import { appkey, restapilink, baseurl, yearnow, baseurlassets, basecolor1, basecolorred } from './Autoload';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
function Logout(props) {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const[nama, setSessionNama] = useState('');
    const [ceksesilogin, setceksesilogin] = useState(0);
    axios.post(restapilink+"api/auth/logout", {
        key:appkey,
        token:token
    });
    localStorage.removeItem('token');
    localStorage.removeItem('tmptoken');
    localStorage.removeItem('kategoricatatankudefault');
    localStorage.removeItem('catatanlist');
    localStorage.removeItem('directlogin');
    document.location.href=baseurl+'login/';
    return(
        <></>
    )
}
export default Logout;