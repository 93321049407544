import React from 'react';

function SubHeader(props) {
    return(
        <div className='subheader'>
            <div className='container'>
                <h1>{props.title}</h1> 
                <p>{props.desc}</p> 
            </div>
        </div>
    )
}

export default SubHeader;