import React from 'react';
import ReactDOM from 'react-dom/client';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import 'bootstrap-datepicker'; 
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.standalone.min.css';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.standalone.min.css';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';

import 'jquery-mask-plugin/dist/jquery.mask.min'; 

import WebRipLabs from './WebRipLabs';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import LoadingRipLabs from './components/LoadingRipLabs';

const root = ReactDOM.createRoot(document.getElementById('webriplabs'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <LoadingRipLabs />
      <WebRipLabs />
    </BrowserRouter>
  // {/* </React.StrictMode> */}
);
reportWebVitals();