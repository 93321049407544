import { appkey, restapilink, baseurl, yearnow, baseurlassets, basecolor1, basecolorred, appsetup } from './Autoload'; 
import axios from 'axios'; 
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Header from './Header';
import Header2 from './Header2';
const nl2br = require('react-nl2br');
const $ = require("jquery"); 
const Swal = require('sweetalert2'); 

function TugasKu(props) {
  const {idtugaskuprojek} = useParams();
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [ceksesilogin2, setceksesilogin2] = useState(0);
  const [PenggunaPopupIntro2, setPenggunaPopupIntro2] = useState('no');
  const [loadpagetugasku, setloadpagetugasku] = useState(0);
  const [idTugasKuEdit, setidTugasKuEdit] = useState('');
  const [keyword, setKeyword] = useState('');
  const [formjudul, setformjudul] = useState('');
  const [formdeskripsi, setformdeskripsi] = useState('');
  const [projektugas, setProjekTugas] = useState(0);
  const [datatugasku, setTugasKu] = useState([]);
  const [jumlahdatatugasku, setJumlahTugasKu] = useState(0);
  const [jumlahalldatatugasku, setJumlahAllTugasKu] = useState(0);
  const [dataprojektugas, setProjekTugasKu] = useState([]);
  const [dataprojektugassharing, setProjekTugasKuSharing] = useState([]);
  const [dataprojektugassharingemail, setProjekTugasKuSharingEmail] = useState([]);
  const [jumlahdatatugaskuterselesaikan, setJumlahTugasKuTerselesaikan] = useState(0);
  const [jumlahalldatatugaskuterselesaikan, setJumlahAllTugasKuTerselesaikan] = useState(0);
  const [datatugaskuterselesaikan, setTugasKuTerselesaikan] = useState([]);
  const [judulProjekTugasAdd, setJudulProjekTugasAdd] = useState('');
  const [limitdatatugas, setlimitdatatugas] = useState(10);
  const [judulProjek, setJudulProjek] = useState('');
  const [projekShareOrNo, setProjekShareOrNo] = useState('');
  const [projekSharing, setProjekSharing] = useState('');
  const [projekSharingFrom, setProjekSharingFrom] = useState('');
  const [projekSharingMe, setProjekSharingMe] = useState('');
  const [projekSharingPermission, setProjekSharingPermission] = useState('');
  const [projekSharingEmail, setProjekSharingEmail] = useState('');
  const [projekSharingEmailJumlah, setProjekSharingEmailJumlah] = useState('');
  const [idtugaskuprojekawal, setidtugaskuprojekawal] = useState('');
  const [ketersediaanProjek, setKetersediaanProjek] = useState('');
  const [formjuduledit, setformjuduledit] = useState('');
  const [formdeskripsiedit, setformdeskripsiedit] = useState('');

  const [dateinsertedtugas, setdateinsertedtugas] = useState('0');
  const [dateupdatedtugas, setdateupdatedtugas] = useState('0');
  const [datecompletedtugas, setdatecompletedtugas] = useState('0');
  const [creatorinsertedtugas, setcreatorinsertedtugas] = useState('');
  const [creatorupdatedtugas, setcreatorupdatedtugas] = useState('');
  const [creatorcompletedtugas, setcreatorcompletedtugas] = useState('');

  const getCekLogin2 = async () => {
    await axios.post(restapilink+"api/auth/ceklogin", {
      key:appkey,
      token:token
    }).then((response) => {
      setceksesilogin2(response.data.ceklogin)
      if(response.data.ceklogin == 0) {}
      else {
        if(response.data.datapengguna.pengguna_popupintro2) {
          setPenggunaPopupIntro2(response.data.datapengguna.pengguna_popupintro2);
        }
      }
    });
  }

  const cekProjekTugasAwal = async() => {
    try {
      let response = await axios.post(restapilink+"api/tugasku/cekprojektugasawal", {
        key:appkey,
        token: token
      });
      setidtugaskuprojekawal(response.data.id);
    }
    catch(e) {}
  }
  const cekKetersediaanProjek = async() => {
    try {
      let response = await axios.post(restapilink+"api/tugasku/cekketersediaanprojek", {
        key:appkey,
        token: token,
        id: idtugaskuprojek
      });
      setKetersediaanProjek(response.data.status);
      setProjekShareOrNo(response.data.shareorno);
      if(response.data.status=="tidakadadata") {}
      else if(response.data.status=="datadihapus") {
        // if(appsetup=="utama") {
        //   navigate('/tugasku/tugas/'+idtugaskuprojekawal+'/');
        // }
        // else {
        //   navigate('/tugas/'+idtugaskuprojekawal+'');
        // }
      }
      else {}
    }
    catch(e) {
      // console.log(e);
    }
  }
  const cekJudulProjek = async() => {
    try {
      let response = await axios.post(restapilink+"api/tugasku/cekjudulprojek", {
        key:appkey,
        token: token,
        id: idtugaskuprojek
      });
      setJudulProjek(response.data.judulprojek);
      setProjekSharing(response.data.data.sharing_email);
      setProjekSharingFrom(response.data.sharing_emailfrom);
      setProjekSharingPermission(response.data.data.sharing_permission);
      setProjekSharingMe(response.data.tugasku_projek_sharingme);
    }
    catch(e) {
      // console.log(e);
    }
  }

  const getTugasKu = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response = await axios.post(restapilink+"api/tugasku/tugasku", {
        key:appkey,
        token: token, 
        id: '',
        tugaskuprojek: idtugaskuprojek,
        limit: limitdatatugas,
        keyword: keyword
      });
      setJumlahTugasKu(response.data.jumlah);
      setJumlahAllTugasKu(response.data.jumlahall);
      setTugasKu(response.data.data);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {
      // console.log(e);
    }
  }

  const getTugasKuTerselesaikan = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response = await axios.post(restapilink+"api/tugasku/tugaskuterselesaikan", {
        key:appkey,
        token: token, 
        id: '',
        idtugaskuprojek: idtugaskuprojek,
      });
      setJumlahTugasKuTerselesaikan(response.data.jumlah);
      setJumlahAllTugasKuTerselesaikan(response.data.jumlahall);
      setTugasKuTerselesaikan(response.data.data);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {
      // console.log(e);
    }
  }
  
  const getProjekTugasKu = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response = await axios.post(restapilink+"api/tugasku/kategoritugasku", {
        key:appkey,
        token: token
      });
      setProjekTugasKu(response.data.data);
      setProjekTugasKuSharingEmail(response.data.sharing_email_list);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {
      // console.log(e);
    }
  }
  const getProjekTugasKuSharing = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response = await axios.post(restapilink+"api/tugasku/kategoritugaskusharing", {
        key:appkey, 
        token: token 
      }); 
      setProjekTugasKuSharing(response.data.data);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {
      // console.log(e);
    }
  }
  const getProjekTugasKuSharingEmail = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response = await axios.post(restapilink+"api/tugasku/kategoritugaskusharingemail", {
        key:appkey, 
        token: token,
        id: idtugaskuprojek
      });
      setProjekSharingEmail(response.data.data);
      setProjekSharingEmailJumlah(response.data.jumlah);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {
      // console.log(e);
    }
  }

  const addTugas = async (e) => {
    e.preventDefault();
    $("form#addTugas #submit").attr('disabled','disabled');
    $(".loading_riplabs").css('display','block');
    try {
      await axios.post(restapilink+"api/tugasku/tambahtugasku", {
        key:appkey,
        token: token, 
        idtugaskuprojek:idtugaskuprojek,
        formjudul:formjudul,
        formdeskripsi:formdeskripsi
      });
      $(".loading_riplabs").css('display','none');
      $("#formjudul").val('')
      $("#formdeskripsi").val('')
      setformjudul('');
      setformdeskripsi('');
      $(".modal .close").click();
      $("form#addTugas #submit").removeAttr('disabled');
      Swal.fire({
        title: 'Berhasil',
        html: 'Tugas baru sudah ditambahkan <i className="fa fa-smile-beam"></i>',
        icon: 'success',
        confirmButtonColor: basecolor1
      });
      setTimeout(function(){
        getTugasKu();
      }, 500);
    }
    catch(e) {
      // console.log(e);
    }
  }

  const btnshowmodalnugas = async (e) => {
    setProjekTugas(idtugaskuprojek);
    $("#formjudul").val('');
    $("#formdeskripsi").val('');
  }

  const changeKategori = async (e) => {
    $(".offcanvas .btn-close").click(); 
    // getTugasKu();
    // getTugasKuTerselesaikan();
    cekJudulProjek();
    getTugasKu();
    getTugasKuTerselesaikan();
    getProjekTugasKu();
    getProjekTugasKuSharing();
    $("html, body").animate({ scrollTop: 0 }, 50);
  }

  const changeIdSelesaikanTugasKu = async (e) => {
    // Swal.fire({
    //   title: 'Konfirmasi',
    //   html: 'Apakah kamu sudah menyelesaikan Tugas ini?',
    //   icon: 'question',
    //   showCancelButton: true,
    //   confirmButtonColor: basecolor1,
    //   cancelButtonColor: basecolorred,
    //   confirmButtonText: 'Ya, Lanjutkan',
    //   cancelButtonText: 'Tidak'
    // }).then((result) => {
    //   if (result.value) {
        $(".loading_riplabs").css('display','block');
        try {
          $(".loading_riplabs").css('display','none');
          axios.post(restapilink+"api/tugasku/selesaikantugasku", {
            key:appkey,
            token: token, 
            id:e
          });
          // Swal.fire({
          //   title: 'Berhasil',
          //   html: 'Tugas sudah diselesaikan <i className="fa fa-smile-beam"></i>',
          //   icon: 'success',
          //   confirmButtonColor: basecolor1
          // });
          setTimeout(function(){
            getTugasKu();
            getTugasKuTerselesaikan();
          }, 500); 
        }
        catch(e) {
          // console.log(e);
        }
    //   }
    // });
  }

  const changeIdKembalikanTugasKu = async (e) => {
    // Swal.fire({
    //   title: 'Konfirmasi',
    //   html: 'Apakah kamu ingin mengembalikan Tugas ini?',
    //   icon: 'question',
    //   showCancelButton: true,
    //   confirmButtonColor: basecolor1,
    //   cancelButtonColor: basecolorred,
    //   confirmButtonText: 'Ya, Lanjutkan',
    //   cancelButtonText: 'Tidak'
    // }).then((result) => {
    //   if (result.value) {
        $(".loading_riplabs").css('display','block');
        try {
          $(".loading_riplabs").css('display','none');
          axios.post(restapilink+"api/tugasku/kembalikantugasku", {
            key:appkey,
            token: token, 
            id:e
          });
          // Swal.fire({
          //   title: 'Berhasil',
          //   html: 'Tugas sudah dikembalikan <i className="fa fa-smile-beam"></i>',
          //   icon: 'success',
          //   confirmButtonColor: basecolor1
          // });
          setTimeout(function(){
            getTugasKu();
            getTugasKuTerselesaikan();
          }, 500); 
        }
        catch(e) {
          // console.log(e);
        }
    //   }
    // });
  }

  const cariTugas = async (e) => {
    e.preventDefault();
    setKeyword(e.target.value); 
  }
 
  const showTugasKuListTerselesaikan = async (e) => { 
    $(".boxtugaskulistterselesaikan").slideToggle();
  }
  
  const tambahProjekTugas = async (e) => {
    e.preventDefault();
    $("form#tambahProjekTugas #submit").attr('disabled','disabled');
    $(".loading_riplabs").css('display','block');
    var judulprojektugas = $("#tambahProjekTugas #judulprojektugas").val();
    await axios.post(restapilink+"api/tugasku/tambahprojektugasku", {
      key:appkey,
      token:token,
      judulprojektugas:judulprojektugas
    })
    .then((response) => {
      $(".loading_riplabs").css('display','none');
      $("form#tambahProjekTugas #submit").removeAttr('disabled');
      if(response.data.status=="error") {
        Swal.fire({
          title: 'Oops...',
          html: response.data.message + ' <i class="fa fa-smile-beam"></i>',
          icon: 'error',
          confirmButtonColor: basecolor1
        });
        return false;
      }
      else {
        $(".judulprojektugas").val('');
        setJudulProjekTugasAdd('');
        setTimeout(function(){
          getProjekTugasKu();
          getProjekTugasKuSharing();
          getTugasKu();
          Swal.fire({
            title: 'Berhasil',
            html: 'Projek Tugas baru sudah ditambahkan <i className="fa fa-smile-beam"></i>',
            icon: 'success',
            confirmButtonColor: basecolor1
          });
        }, 1000);
      }
    });
  }
  const perbaruiProjekTugas = async (e) => {
    var formjudulprojektugasthis = $(".judulprojektugasedit"+e).val();
    if(formjudulprojektugasthis.trim()=="") {
      Swal.fire({
        title: 'Oops...',
        html: 'Jangan kosongkan Judul Projek Tugas ya <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      }); 
      return false;
    }
    else {
      $("#perbaruiprojektugas"+e+" button").attr("disabled","disabled");
      $(".loading_riplabs").css('display','block');
      try {
        let response = await axios.post(restapilink+"api/tugasku/perbaruiprojektugasku", {
          key:appkey,
          token: token,
          id:e,
          judulprojektugas:formjudulprojektugasthis
        });
        if(response.data.status=="error") {
          $("#perbaruiprojektugas"+e+" button").removeAttr('disabled');
          Swal.fire({
            title: 'Oops...',
            html: response.data.message + ' <i class="fa fa-smile-beam"></i>',
            icon: 'error',
            confirmButtonColor: basecolor1
          });
          return false;
        }
        else {
          $("#perbaruiprojektugas"+e+" button").removeAttr('disabled');
          $(".loading_riplabs").css('display','none');
          $(".judulprojektugasedit").val('');
          $(".judulprojektugasedit"+e).attr('placeholder',formjudulprojektugasthis);
          $(".menukategori"+e).html(formjudulprojektugasthis);
          setTimeout(function(){
            cekProjekTugasAwal();
            cekKetersediaanProjek();
            cekJudulProjek();
            getTugasKu();
            getTugasKuTerselesaikan();
            getProjekTugasKu();
            getProjekTugasKuSharing();
            Swal.fire({
              title: 'Berhasil',
              html: 'Projek Tugas sudah diperbarui <i className="fa fa-smile-beam"></i>',
              icon: 'success',
              confirmButtonColor: basecolor1
            });
          }, 500);
        }
      }
      catch(e) {
        // console.log(e);
      }
    }
  }
  const hapusProjekTugas = async (e) => {
    Swal.fire({
      title: 'Konfirmasi',
      html: 'Apakah kamu yakin ingin menghapus Projek Tugas ini?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: basecolor1,
      cancelButtonColor: basecolorred,
      confirmButtonText: 'Ya, Lanjutkan',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.value) {
        $(".loading_riplabs").css('display','block');
        try {
          axios.post(restapilink+"api/tugasku/hapusprojektugasku", {
            key:appkey,
            token: token,
            id:e
          });
          $(".loading_riplabs").css('display','none');
          setTimeout(function(){
            if(e==idtugaskuprojek) {
              if(appsetup=="tugasku") {
                navigate('/tugas/'+idtugaskuprojekawal+'');
                setTimeout(function(){
                  window.location.reload();
                }, 500);
              }
              else {
                navigate('/tugasku/tugas/'+idtugaskuprojekawal+'/');
                setTimeout(function(){
                  window.location.reload();
                }, 500);
              }
            }
            cekProjekTugasAwal();
            cekKetersediaanProjek();
            cekJudulProjek();
            getTugasKu();
            getTugasKuTerselesaikan();
            getProjekTugasKu();
            getProjekTugasKuSharing();
            Swal.fire({
              title: 'Berhasil',
              html: 'Projek Tugas sudah dihapus <i className="fa fa-smile-beam"></i>',
              icon: 'success',
              confirmButtonColor: basecolor1
            });
          }, 500);
        }
        catch(e) {
          // console.log(e);
        }
      }
    });
  }

  const bagikanProjekTugas = async () => {
    var email = $(".formemailadd"+idtugaskuprojek).val();
    var permission = $(".formpermissionadd"+idtugaskuprojek).find('option:selected').attr("value");
    var cek1 = email.indexOf("@");
    var cek2 = email.indexOf(".");
    if(email.trim()=="") {
      Swal.fire({
        title: 'Oops...',
        html: 'Jangan kosongkan Alamat Email ya <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      }); 
      return false;
    }
    else {
      if(cek1 < 0 || cek2 < 0) {
        Swal.fire({
          title: 'Perhatian',
          html: 'Silahkan masukkan format Alamat Email dengan benar <i class="fa fa-smile-beam"></i>',
          icon: 'info',
          confirmButtonColor: basecolor1
        });
        return false;
      }
      else {
        $("#submit").attr('disabled','disabled');
        $(".loading_riplabs").css('display','block');
        try {
          let response = await axios.post(restapilink+"api/tugasku/bagikanprojektugas", {
            key:appkey,
            token: token,
            id:idtugaskuprojek,
            email:email,
            permission:permission
          });
          if(response.data.status=="error") {
            $("#submit").removeAttr('disabled');
            Swal.fire({
              title: 'Oops...',
              html: response.data.message + ' <i class="fa fa-smile-beam"></i>',
              icon: 'error',
              confirmButtonColor: basecolor1
            });
            return false;
          }
          else {
            $("#submit").removeAttr('disabled');
            $(".loading_riplabs").css('display','none');
            $(".formemailadd"+idtugaskuprojek).val('');
            setTimeout(function() {
              getProjekTugasKuSharingEmail();
              Swal.fire({
                title: 'Berhasil',
                html: 'Projek Tugas sudah dibagikan <i className="fa fa-smile-beam"></i>',
                icon: 'success',
                confirmButtonColor: basecolor1
              });
            }, 500);
          }
        }
        catch(e) {
          // console.log(e);
        }
      }
    }
  }

  const perbaruiBagikanProjekTugas = async (e) => {
    var email = $(".formemailedit"+e).val();
    var permission = $(".formpermissionedit"+e).find('option:selected').attr("value");
    var cek1 = email.indexOf("@");
    var cek2 = email.indexOf(".");
    if(email.trim()=="") {
      Swal.fire({
        title: 'Oops...',
        html: 'Jangan kosongkan Alamat Email ya <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      }); 
      return false;
    }
    else {
      if(cek1 < 0 || cek2 < 0) {
        Swal.fire({
          title: 'Perhatian',
          html: 'Silahkan masukkan format Alamat Email dengan benar <i class="fa fa-smile-beam"></i>',
          icon: 'info',
          confirmButtonColor: basecolor1
        });
        return false;
      }
      else {
        $("#perbaruibagikanprojektugas"+e+" button").attr("disabled","disabled");
        $(".loading_riplabs").css('display','block');
        try {
          let response = await axios.post(restapilink+"api/tugasku/perbaruibagikanprojektugas", {
            key:appkey,
            token: token,
            id:idtugaskuprojek,
            idsharing:e,
            email:email,
            permission:permission
          });
          console.log(response.data);
          if(response.data.status=="error") {
            $("#perbaruibagikanprojektugas"+e+" button").removeAttr('disabled');
            Swal.fire({
              title: 'Oops...',
              html: response.data.message + ' <i class="fa fa-smile-beam"></i>',
              icon: 'error',
              confirmButtonColor: basecolor1
            });
            return false;
          }
          else {
            $("#perbaruibagikanprojektugas"+e+" button").removeAttr('disabled');
            $(".loading_riplabs").css('display','none');
            $(".formemailedit"+e).val('');
            setTimeout(function() {
              getProjekTugasKuSharingEmail();
              Swal.fire({
                title: 'Berhasil',
                html: 'Opsi Bagikan Projek Tugas sudah diperbarui <i className="fa fa-smile-beam"></i>',
                icon: 'success',
                confirmButtonColor: basecolor1
              });
            }, 500);
          }
        }
        catch(e) {
          // console.log(e);
        }
      }
    }
  }

  const hapusBagikanProjekTugas = async (e) => {
    Swal.fire({
        title: 'Konfirmasi',
        html: 'Apa kamu yakin ingin menghapus Email Dibagikan Projek Tugas ini? ',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#039ED3',
        cancelButtonColor: '#E63946',
        confirmButtonText: 'Ya, Lanjutkan',
        cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.value) {
        $(".loading_riplabs").css('display','block');
        try {
          let response = axios.post(restapilink+"api/tugasku/hapusbagikanprojektugas", {
            key:appkey,
            token: token,
            id:idtugaskuprojek,
            idsharing:e
          });
          // if(response.data.status=="error") {
          //   Swal.fire({
          //     title: 'Oops...',
          //     html: response.data.message + ' <i class="fa fa-smile-beam"></i>',
          //     icon: 'error',
          //     confirmButtonColor: basecolor1
          //   });
          //   return false;
          // }
          // else {
            $(".loading_riplabs").css('display','none');
            // $(".formemailedit").val('');
            setTimeout(function() {
              getProjekTugasKuSharingEmail();
              Swal.fire({
                title: 'Berhasil',
                html: 'Email Dibagikan Projek Tugas sudah dihapus <i className="fa fa-smile-beam"></i>',
                icon: 'success',
                confirmButtonColor: basecolor1
              });
            }, 500);
          // }
        }
        catch(e) {
          // console.log(e);
        }
      }
    });
  }

  const showboxtambahkategorisimple = async (e) => {
    $(".boxtambahkategorisimple .body").slideToggle();
  }

  const setidTugasKuEdit2 = async (e) => {
    setidTugasKuEdit(e);
    try {
      let response = await axios.post(restapilink+"api/tugasku/tugaskuid", {
        key:appkey,
        token: token,
        id: e
      });
      setformjuduledit(response.data.data.tugasku_judul.replaceAll("\\'","'")); 
      setformdeskripsiedit(response.data.data.tugasku_isi.replaceAll("\\'","'")); 
      $("#formjuduledit").val(response.data.data.tugasku_judul.replaceAll("\\'","'")); 
      $("#formdeskripsiedit").val(response.data.data.tugasku_isi.replaceAll("\\'","'")); 

      if(response.data.date_inserted_tugas.trim()!=="") {
        setdateinsertedtugas(response.data.date_inserted_tugas);
      }
      else { setdateinsertedtugas('0'); }
      if(response.data.date_updated_tugas.trim()!=="") {
        setdateupdatedtugas(response.data.date_updated_tugas);
      }
      else { setdateupdatedtugas('0'); }
      if(response.data.date_completed_tugas.trim()!=="") {
        setdatecompletedtugas(response.data.date_completed_tugas);
      }
      else { setdatecompletedtugas('0'); }

      if(response.data.data.creator_inserted.trim()!=="") {
        setcreatorinsertedtugas(response.data.data.creator_inserted);
      }
      else { setcreatorinsertedtugas(''); }
      if(response.data.data.creator_updated.trim()!=="") {
        setcreatorupdatedtugas(response.data.data.creator_updated);
      }
      else { setcreatorupdatedtugas(''); }
      if(response.data.data.creator_completed.trim()!=="") {
        setcreatorcompletedtugas(response.data.data.creator_completed);
      }
      else { setcreatorcompletedtugas(''); }

    }
    catch(e) {
      // console.log(e);
    }

  }
  const updateTugas = async (e) => {
    e.preventDefault();
    $("form#updateTugas #submit").attr('disabled','disabled');
    $(".loading_riplabs").css('display','block');
    try {
      await axios.post(restapilink+"api/tugasku/perbaruitugasku", {
        key:appkey,
        token: token, 
        id: idTugasKuEdit, 
        formjudul:formjuduledit,
        formdeskripsi:formdeskripsiedit,
        projekshareorno:projekShareOrNo
      });
      $(".loading_riplabs").css('display','none');
      $("#formjuduledit").val('')
      $("#formdeskripsiedit").val('')
      setformjuduledit('');
      setformdeskripsiedit('');
      $(".modal .close").click();
      $("form#updateTugas #submit").removeAttr('disabled');
      Swal.fire({
        title: 'Berhasil',
        html: 'Tugas sudah diperbarui <i className="fa fa-smile-beam"></i>',
        icon: 'success',
        confirmButtonColor: basecolor1
      });
      setTimeout(function(){
        cekProjekTugasAwal();
        cekKetersediaanProjek();
        cekJudulProjek();
        getTugasKu();
        getTugasKuTerselesaikan();
        getProjekTugasKu();
        getProjekTugasKuSharing();
      }, 500); 
    }
    catch(e) {
      // console.log(e);
    }
  }

  const changeIdHapusTugas = async (e) => {
    Swal.fire({
      title: 'Konfirmasi',
      html: 'Apakah kamu ingin menghapus Tugas ini?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: basecolor1,
      cancelButtonColor: basecolorred,
      confirmButtonText: 'Ya, Lanjutkan',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.value) {
        $(".loading_riplabs").css('display','block');
        try { 
          $(".loading_riplabs").css('display','none');
          axios.post(restapilink+"api/tugasku/hapustugasku", {
            key:appkey,
            token: token, 
            id:e,
            projekshareorno:projekShareOrNo
          });
          $(".modal .close").click();
          Swal.fire({
            title: 'Berhasil',
            html: 'Tugas sudah dihapus <i className="fa fa-smile-beam"></i>',
            icon: 'success',
            confirmButtonColor: basecolor1
          });
          setTimeout(function(){
            cekProjekTugasAwal();
            cekKetersediaanProjek();
            cekJudulProjek();
            getTugasKu();
            getTugasKuTerselesaikan();
            getProjekTugasKu();
            getProjekTugasKuSharing();
          }, 500); 
        }
        catch(e) {
          // console.log(e);
        }
      }
    });
  }

  const directlogin = async (e) => {
    if(appsetup=="utama") {
      localStorage.setItem('directlogin',baseurl+'tugasku/');
    }
    else {
      localStorage.setItem('directlogin',baseurl);
    }
    navigate("/login/");
  }

  useEffect(() => {
    // alert(projekShareOrNo)
    // alert(projekSharing)
    // alert(ceksesilogin2)
    getCekLogin2();
    // if(ceksesilogin2 > 0 && PenggunaPopupIntro2 == 'no') {
    //   setTimeout(function() {
    //     $(".showmodalintroutama").click();
    //   }, 2000);
    // }
    if(ceksesilogin2 > 0) {
      cekProjekTugasAwal();
      setTimeout(function(){
        if(!idtugaskuprojek) {
          if(appsetup=="tugasku") {
            navigate('/tugas/'+idtugaskuprojekawal+'');
            setTimeout(function(){
              window.location.reload();
            }, 500);
          }
          else {
            navigate('/tugasku/tugas/'+idtugaskuprojekawal+'/');
            setTimeout(function(){
              window.location.reload();
            }, 500);
          }
        }
      }, 500);
      setTimeout(function(){
        cekKetersediaanProjek();
        cekJudulProjek();
        getTugasKu();
        getTugasKuTerselesaikan();
        getProjekTugasKu();
        getProjekTugasKuSharing();
        getProjekTugasKuSharingEmail();
      }, 500);
    }

    setTimeout(function(){
      setloadpagetugasku(1);
    }, 1000);

  }, [projektugas, keyword, jumlahdatatugasku, jumlahalldatatugasku, limitdatatugas, jumlahdatatugaskuterselesaikan, jumlahalldatatugaskuterselesaikan, idtugaskuprojekawal, ketersediaanProjek, idtugaskuprojek, dateinsertedtugas, dateupdatedtugas, datecompletedtugas, ceksesilogin2, PenggunaPopupIntro2 ]);

  const closemenu = (e) => {
    $(".offcanvas .btn-close").click(); 
  }

  $(".formemailedit").on('mouseover',function(){
    var val = $(this).attr('placeholder');
    $(this).val(val);
  });
  $(".judulprojektugasedit").on('mouseover',function(){
    var val = $(this).attr('placeholder');
    $(this).val(val);
  });

  $(".formemail").on('keyup paste change keydown', function(v) {
    var start = v.target.selectionStart;
    var end = v.target.selectionEnd;
    v.target.value = v.target.value.toLowerCase();
    v.target.setSelectionRange(start, end);
    var valinput = $(this).val();
    var cekvalue1 = valinput.indexOf(" ");
    var valinputnew = valinput.replace(/ /g,"");
    if(cekvalue1>=0) {
      Swal.fire({
        title: 'Perhatian',
        html: 'Diharapkan hanya boleh menggunakan, tanpa spasi huruf, angka dan tanpa simbol yang dilarang oleh sistem <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
      $(this).val(valinputnew);
    }
    if (v.keyCode==192 || v.keyCode==220 || v.keyCode==222 || v.keyCode==186 || v.keyCode==188 || v.keyCode==191 || v.keyCode==189 || v.keyCode==187) {
      Swal.fire({
        title: 'Perhatian',
        html: 'Diharapkan hanya boleh menggunakan huruf, angka, tanpa spasi dan tanpa simbol yang dilarang oleh sistem <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
      $(this).val('');
    }
    var valinput = $(this).val();
    var cekvalue1 = valinput.indexOf("!");var cekvalue3 = valinput.indexOf("#");var cekvalue4 = valinput.indexOf("$");var cekvalue5 = valinput.indexOf("%");var cekvalue6 = valinput.indexOf("^");var cekvalue7 = valinput.indexOf("&");var cekvalue8 = valinput.indexOf("*");var cekvalue10 = valinput.indexOf("'");var cekvalue11 = valinput.indexOf("`");var cekvalue12 = valinput.indexOf("~");var cekvalue17 = valinput.indexOf(",");var cekvalue19 = valinput.indexOf("<");var cekvalue20 = valinput.indexOf(">");var cekvalue21 = valinput.indexOf("/");var cekvalue22 = valinput.indexOf("?");var cekvalue23 = valinput.indexOf("|");var cekvalue24 = valinput.indexOf(";");var cekvalue25 = valinput.indexOf(":");var cekvalue26 = valinput.indexOf("(");var cekvalue27 = valinput.indexOf(")");
    if(cekvalue1>=0 || cekvalue3>=0 || cekvalue4>=0 || cekvalue5>=0 || cekvalue6>=0 || cekvalue7>=0 || cekvalue8>=0 || cekvalue10>=0 || cekvalue11>=0 || cekvalue12>=0 || cekvalue17>=0 || cekvalue19>=0 || cekvalue20>=0 || cekvalue21>=0 || cekvalue22>=0 || cekvalue23>=0 || cekvalue24>=0 || cekvalue25>=0 || cekvalue26>=0 || cekvalue27>=0) {
      Swal.fire({
        title: 'Perhatian',
        html: 'Diharapkan hanya boleh menggunakan huruf, angka, tanpa spasi dan tanpa simbol yang dilarang oleh sistem <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
      $(this).val('');
    }
  });

  $(".formtext").on('keyup paste change keydown', function(v) {
    if (v.keyCode==192 || v.keyCode==220 || v.keyCode==222 || v.keyCode==186 || v.keyCode==188 || v.keyCode==190 || v.keyCode==191 || v.keyCode==187) {
      Swal.fire({
        title: 'Perhatian',
        html: 'Diharapkan hanya boleh menggunakan huruf, angka dan tanpa simbol yang dilarang oleh sistem <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
      $(this).val('');
      setJudulProjekTugasAdd('');
    }
    var valinput = $(this).val();
    var cekvalue1 = valinput.indexOf("!");var cekvalue2 = valinput.indexOf("@");var cekvalue3 = valinput.indexOf("#");var cekvalue4 = valinput.indexOf("$");var cekvalue5 = valinput.indexOf("%");var cekvalue6 = valinput.indexOf("^");var cekvalue7 = valinput.indexOf("&");var cekvalue8 = valinput.indexOf("*");var cekvalue10 = valinput.indexOf("'");var cekvalue11 = valinput.indexOf("`");var cekvalue12 = valinput.indexOf("~");var cekvalue17 = valinput.indexOf(",");var cekvalue18 = valinput.indexOf(".");var cekvalue19 = valinput.indexOf("<");var cekvalue20 = valinput.indexOf(">");var cekvalue21 = valinput.indexOf("/");var cekvalue22 = valinput.indexOf("?");var cekvalue23 = valinput.indexOf("|");var cekvalue24 = valinput.indexOf(";");var cekvalue25 = valinput.indexOf(":");var cekvalue26 = valinput.indexOf("(");var cekvalue27 = valinput.indexOf(")");
    if(cekvalue1>=0 || cekvalue2>=0 || cekvalue3>=0 || cekvalue4>=0 || cekvalue5>=0 || cekvalue6>=0 || cekvalue7>=0 || cekvalue8>=0 || cekvalue10>=0 || cekvalue11>=0 || cekvalue12>=0 || cekvalue17>=0 || cekvalue18>=0 || cekvalue19>=0 || cekvalue20>=0 || cekvalue21>=0 || cekvalue22>=0 || cekvalue23>=0 || cekvalue24>=0 || cekvalue25>=0 || cekvalue26>=0 || cekvalue27>=0) {
      Swal.fire({
        title: 'Perhatian',
        html: 'Diharapkan hanya boleh menggunakan huruf, angka dan tanpa simbol yang dilarang oleh sistem <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
      $(this).val('');
      setJudulProjekTugasAdd('');
    }
  });


  var inputclassbagikanprojek = "formemail formemailadd"+idtugaskuprojek+" form-control";
  var selectclassbagikanprojek = "formpermissionadd"+idtugaskuprojek+" form-select";
  var copylinktugasku = "https://tugasku.riplabs.id/tugas/"+idtugaskuprojek+"/";

  $(".copylinktugasku").click(function(){
    $(".copylinktugasku").attr("class","badge bgcolorgreen font-11 copylinktugasku");
    $(".copylinktugasku").html("<i class='fa fa-link'></i> Copy Link <i class='fa fa-check-circle'></i>");
    setTimeout(function(){
      $(".copylinktugasku").attr("class","badge bgcolor1 font-11 copylinktugasku");
      $(".copylinktugasku").html("<i class='fa fa-link'></i> Copy Link");
    }, 1500);
  })


  var imggiftugasku = baseurlassets+"images/TugasKu.gif";
  var logotugasku = baseurlassets+"images/riplabs/Logo TugasKu.png";


  return (
    <>
      <title>Aplikasi TugasKu | RipLabs Indonesia</title>
      <meta name="title" content="Aplikasi TugasKu | RipLabs Indonesia" />
      <meta itemProp="name" content="Aplikasi TugasKu | RipLabs Indonesia" />
      <meta property="og:title" content="Aplikasi TugasKu | RipLabs Indonesia" />
      <meta name="twitter:title" content="Aplikasi TugasKu | RipLabs Indonesia" />
      {
        loadpagetugasku > 0 && (
          <>
            {
              ceksesilogin2 <= 0 && (
                <>
                  <Header />
                  <div className="bodypage pagehome pagehome2 animated fadeIn">
                    <div className='hero'>
                      <div className='container text-center'>
                        <img src={logotugasku} style={{width:"190px",marginBottom:"20px"}} />
                        <img className='imggif' src={imggiftugasku} />
                        <p><span className='font-semibold' style={{color:"var(--basecolor1)"}}>Tugas</span><span className='font-semibold' style={{color:"var(--basecolor2)"}}>Ku</span> merupakan sebuah Aplikasi Catatan Tugas Online yang sangat ringan ini bisa kamu gunakan secara gratis di RipLabs.</p>
                        <span onClick={directlogin} className='btn_hero_1 cursorpointer'>Coba Sekarang&nbsp;<i className='fa fa-angle-right'></i></span>
                      </div>
                    </div>
                  </div>
                </>
              )
            }
            {
              ceksesilogin2 > 0 && (
                <>
                  <Header2 />
                  <div className='tugaskupage bodypage '>
                    <div className='aplikasiku-wrapper'>
                      <div className='sidebar'>
                        {
                          Array.isArray(dataprojektugas) ?
                          dataprojektugas.map((data, i) => {
                            var classname = "menukategori menukategori" + data.id_tugasku_projek;
                            if(appsetup=="tugasku") {
                              var tonavlink1 = "/tugas/"+data.id_tugasku_projek+"/";
                            }
                            else {
                              var tonavlink1 = "/tugasku/tugas/"+data.id_tugasku_projek+"/";
                            }
                            return (
                              <NavLink key={i} to={tonavlink1} className={classname} onClick={(e) => changeKategori(e.currentTarget.id)} id={data.id_tugasku_projek}>{data.tugasku_projek_judul}</NavLink>
                            )
                          })
                          : ""
                        }


                        {
                          Array.isArray(dataprojektugassharing) ?
                          dataprojektugassharing.map((data, i) => {
                            var classname = "menukategori menukategori" + data.id_tugasku_projek;
                            if(appsetup=="tugasku") {
                              var tonavlink1 = "/tugas/"+data.id_tugasku_projek+"/";
                            }
                            else {
                              var tonavlink1 = "/tugasku/tugas/"+data.id_tugasku_projek+"/";
                            }
                            return (
                              <NavLink key={i} to={tonavlink1} className={classname} onClick={(e) => changeKategori(e.currentTarget.id)} id={data.id_tugasku_projek}><i className='fa fa-share'></i> {data.tugasku_projek_judul}</NavLink>
                            )
                          })
                          : ""
                        }

                        <span className='menukategori' data-bs-toggle="modal" data-bs-target="#popupeditprojektugasku">Edit Projek Tugas</span>
                      </div>
                      <div className='body'>

                        {/* Nyatet */}
                        {/* LiveSearching */}
                        
                        {
                          projekShareOrNo=="yes" && projekSharing!=="" && projekSharingPermission=="full" && (
                            <>
                              <div className='row'>
                                <div className='col-lg-2'>
                                  <button type="button" className="button-blue-1 mb-3 d-block w-100 btnshowmodalnugas" data-bs-toggle="modal" data-bs-target="#nugas" onClick={btnshowmodalnugas}>
                                    <i className='fa fa-plus-square'></i> Nugas
                                  </button>
                                </div>
                                <div className='col-lg-10'>
                                  <input type="search" className="form-control" placeholder='Cari judul atau isi tugas...' onChange={cariTugas} value={keyword} />
                                </div>
                              </div>
                            </>
                          )
                        }
                        
                        {
                          projekShareOrNo=="yes" && projekSharing!=="" && projekSharingPermission!=="full" && (
                            <>
                              <div className='row'>
                                <div className='col-lg-12'>
                                  <input type="search" className="form-control" placeholder='Cari judul atau isi tugas...' onChange={cariTugas} value={keyword} />
                                </div>
                              </div>
                            </>
                          )
                        }

                        {
                          projekShareOrNo!=="yes" && (
                            <>
                              <div className='row'>
                                <div className='col-lg-2'>
                                  <button type="button" className="button-blue-1 mb-3 d-block w-100 btnshowmodalnugas" data-bs-toggle="modal" data-bs-target="#nugas" onClick={btnshowmodalnugas}>
                                    <i className='fa fa-plus-square'></i> Nugas
                                  </button>
                                </div>
                                <div className='col-lg-10'>
                                  <input type="search" className="form-control" placeholder='Cari judul atau isi tugas...' onChange={cariTugas} value={keyword} />
                                </div>
                              </div>
                            </>
                          )
                        }

                        

                        {/* List TugasKu */}

                        {
                          ketersediaanProjek=="tidakadadata" && (
                            <div className='d-block m-auto text-center'>
                              <hr/>
                              Tugas tidak ditemukan
                              <hr/>
                            </div>
                          )
                        }

                        {
                          ketersediaanProjek=="datadihapus" && (
                            <div className='d-block m-auto text-center'>
                              <hr/>
                              Projek Tugas sudah dihapus
                              <hr/>
                            </div>
                          )
                        }

                        {
                          ketersediaanProjek== "ready" && idtugaskuprojek!=="" && idtugaskuprojek > 0 && (
                            <>
                              <hr/>
                              <h5 className='d-flex align-items-center font-medium color2'>{judulProjek}
                              
                              {
                                projekSharingMe=="yes" && (
                                  <>
                                    <>&nbsp;<small style={{cursor:"pointer"}} data-bs-toggle="modal" data-bs-target="#popupbagikanprojektugas" className='badge bgcolor1 font-11'><i className='fa fa-share-alt'></i> Bagikan</small>
                                    </>
                                  </>
                                )
                              }
                              
                              {
                                projekSharingMe=="yes" && (
                                  <>
                                    {
                                      projekSharingEmailJumlah>0 && (
                                        <>&nbsp;<small title={projekSharing} className='badge bgcolor1 font-11'><i className='fa fa-share'></i> Dibagikan</small>
                                        </>
                                      )
                                    }
                                  </>
                                )
                              }
                              
                              {
                                projekShareOrNo=="yes" && projekSharingMe!=="yes" && (
                                  <>
                                    {
                                      projekSharingEmailJumlah>0 && (
                                        <>&nbsp;<small title={projekSharing} className='badge bgcolor1 font-11'><i className='fa fa-share'></i> 
                                        {
                                          projekSharingPermission=="full" && (
                                            <> Full Akses (Sumber: {projekSharingFrom})</>
                                          )
                                        }
                                        {
                                          projekSharingPermission!=="full" && (
                                            <> Hanya Baca (Sumber: {projekSharingFrom}</>
                                          )
                                        }
                                        </small>
                                        </>
                                      )
                                    }
                                  </>
                                )
                              }

                              </h5>
                              <hr/>
                              {
                                jumlahdatatugasku > 0 && (
                                  <>
                                  <div className="tugaskulist row">
                                    {
                                      Array.isArray(datatugasku) ?
                                      datatugasku.map((tugasku, i) => {
                                        var tugaskuisi = tugasku.tugasku_isi.substr(0,150).replaceAll("\\'","'");
                                        var classbtnedittugasku = 'title mb-0 pb-0 btnedittugasku'+tugasku.id_tugasku;
                                        return (
                                          <div key={i} className="col-lg-12 mb-3 content flow listtugasku">
                                            <div style={{margin:"0px",border:"0px",display:"flex",width:"100%"}}>
                                              
                                              {
                                                projekShareOrNo=="yes" && projekSharing!=="" && projekSharingPermission=="full" && (
                                                  <>
                                                    <div style={{margin:"0px",border:"0px",marginRight:"15px",display:"flex",alignItems:"center",justifyContent:"flex-start"}}>
                                                      <a href='javascript:void(0);' className='ceklistugasku' onClick={(e) => changeIdSelesaikanTugasKu(e.currentTarget.id)} id={tugasku.id_tugasku} title="Selesaikan Tugasku"><i className='fa fa-check-square'></i></a>
                                                    </div>
                                                  </>
                                                )
                                              }

                                              {
                                                projekShareOrNo=="no" && (
                                                  <>
                                                    <div style={{margin:"0px",border:"0px",marginRight:"15px",display:"flex",alignItems:"center",justifyContent:"flex-start"}}>
                                                      <a href='javascript:void(0);' className='ceklistugasku' onClick={(e) => changeIdSelesaikanTugasKu(e.currentTarget.id)} id={tugasku.id_tugasku} title="Selesaikan Tugasku"><i className='fa fa-check-square'></i></a>
                                                    </div>
                                                  </>
                                                )
                                              }

                                              <div data-bs-toggle="modal" data-bs-target="#modaledittugasku" onClick={(e) => setidTugasKuEdit2(e.currentTarget.id)} id={tugasku.id_tugasku} style={{margin:"0px",border:"0px",overflow:"hidden",width:"100%"}}>
                                                <h3 style={{overflow: "hidden",textOverflow: "ellipsis",whiteSpace: "nowrap"}} className={classbtnedittugasku}>{tugasku.tugasku_judul.replaceAll("\\'","'")}</h3>
                                                <span className="d-block mt-2 font-15" style={{overflow: "hidden",textOverflow: "ellipsis",whiteSpace: "nowrap"}}>{tugaskuisi}</span>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      })
                                      : ""
                                    }
                                  </div>
                                  </>
                                )
                              }
                              {
                                jumlahdatatugasku <= 0 && jumlahdatatugaskuterselesaikan <= 0 && (
                                  <div className='d-block m-auto text-center'>
                                    Belum ada Tugas
                                    <hr/>
                                  </div>
                                )
                              }
                              {
                                jumlahdatatugasku <= 0 && jumlahdatatugaskuterselesaikan > 0 && (
                                  <div className='d-block m-auto text-center'>
                                    Tidak ada Tugas baru
                                    <hr/>
                                  </div>
                                )
                              }

                              {
                                jumlahdatatugaskuterselesaikan > 0 && (
                                  <>
                                    <center>
                                      <button className='button-blue-1' onClick={showTugasKuListTerselesaikan}>Lihat Tugas Terselesaikan</button>
                                    </center>
                                  </>
                                )
                              }
                              {
                                jumlahdatatugaskuterselesaikan > 0 && (
                                  <>
                                    <br/>
                                    <div className='boxtugaskulistterselesaikan'>
                                      <div className="tugaskulist tugaskulistterselesaikan row">
                                        {
                                          Array.isArray(datatugaskuterselesaikan) ?
                                          datatugaskuterselesaikan.map((tugasku, i) => {
                                            var tugaskuisi = tugasku.tugasku_isi.substr(0,150).replaceAll("\\'","'");
                                            var classbtnedittugasku = 'title mb-0 pb-0 btnedittugasku'+tugasku.id_tugasku;
                                            return (
                                              <div key={i} className="col-lg-12 mb-3 content flow listtugasku">
                                                <div style={{margin:"0px",border:"0px",display:"flex",width:"100%"}}>
                                                  <div style={{margin:"0px",border:"0px",marginRight:"15px",display:"flex",alignItems:"center",justifyContent:"flex-start"}}>
                                                    <a href='javascript:void(0);' className='kembalikantugasku' onClick={(e) => changeIdKembalikanTugasKu(e.currentTarget.id)} id={tugasku.id_tugasku} title="Kembalikan Tugasku"><i className='fa fa-sync'></i></a>
                                                  </div>
                                                  <div data-bs-toggle="modal" data-bs-target="#modaledittugasku" onClick={(e) => setidTugasKuEdit2(e.currentTarget.id)} id={tugasku.id_tugasku} style={{margin:"0px",border:"0px",overflow:"hidden",width:"100%"}}>
                                                    <h3 style={{overflow: "hidden",textOverflow: "ellipsis",whiteSpace: "nowrap"}} className={classbtnedittugasku}>{tugasku.tugasku_judul.replaceAll("\\'","'")}</h3>
                                                    <span className="d-block mt-2 font-14" style={{overflow: "hidden",textOverflow: "ellipsis",whiteSpace: "nowrap"}}>{tugaskuisi}</span>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          })
                                          : ""
                                        }
                                      </div>
                                    </div>
                                  </>
                                )
                              }

                            </>
                          )
                        }

                      </div>
                    </div>

                  </div>

                  <div className="modal fade" id="nugas" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-95">
                      <div className="modal-content">
                        <form method='POST' onSubmit={addTugas} id='addTugas'>
                          <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Nugas Yuk!</h1>
                            <button type="button" className="btn-close close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div className="modal-body">
                            <label className='mb-1' htmlFor="formjudul">Judul Tugas</label>
                            <input required minLength="1" maxLength="255" type="text" className="form-control mb-4" id="formjudul" name="formjudul" placeholder="....." value={formjudul} onChange={(e) => setformjudul(e.target.value)} />
                            <label className='mb-1' htmlFor="formdeskripsi">Deskripsi Tugas</label>
                            <textarea required minLength="1" className="form-control" id="formdeskripsi" name="formdeskripsi" placeholder="....." rows='16' onChange={(e) => setformdeskripsi(e.target.value)} value={formdeskripsi} />
                          </div>
                          <div className="modal-footer">
                            <button type="submit" id='submit' className="btn btn-primary">Simpan</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="modal fade" id="modaledittugasku" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-95">
                      <div className="modal-content">
                        <form method='POST' onSubmit={updateTugas} id='updateTugas'>
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">View/Edit Tugas</h1>
                            <button type="button" className="btn-close close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <label className='mb-1' htmlFor="formjuduledit">Judul Tugas</label>
                          <input required minLength="1" maxLength="255" type="text" className="form-control mb-4" id="formjuduledit" name="formjuduledit" placeholder="....." value={formjuduledit} onChange={(e) => setformjuduledit(e.target.value)} />
                          <label className='mb-1' htmlFor="formdeskripsiedit">Deskripsi Tugas</label>
                          <textarea required minLength="1" className="form-control" id="formdeskripsiedit" name="formdeskripsiedit" placeholder="....." rows='16' onChange={(e) => setformdeskripsiedit(e.target.value)} value={formdeskripsiedit} />

                          <br/>
                          <table className='font-13'>
                          {
                            dateinsertedtugas!=="0" && (
                              <>
                                <tr>
                                  <td>Dibuat</td>
                                  <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                                  <td>
                                    {dateinsertedtugas}
                                    {creatorinsertedtugas!=="" && (<> oleh <span className='font-medium'>{creatorinsertedtugas}</span></>)}
                                  </td>
                                </tr>
                              </>
                            )
                          }
                          {
                            dateupdatedtugas!=="0" && (
                              <>
                                <tr>
                                  <td>Diperbarui</td>
                                  <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                                  <td>
                                    {dateupdatedtugas}
                                    {creatorupdatedtugas!=="" && (<> oleh <span className='font-medium'>{creatorupdatedtugas}</span></>)}
                                  </td>
                                </tr>
                              </>
                            )
                          }
                          {
                            datecompletedtugas!=="0" && (
                              <>
                                <tr>
                                  <td>Diselesaikan</td>
                                  <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                                  <td>
                                    {datecompletedtugas}
                                    {creatorcompletedtugas!=="" && (<> oleh <span className='font-medium'>{creatorcompletedtugas}</span></>)}
                                  </td>
                                </tr>
                                <br/>
                              </>
                            )
                          }
                          </table>
                        </div>
                        <div className="modal-footer">
                          {
                            projekShareOrNo=="yes" && projekSharing!=="" && projekSharingPermission=="full" && (
                              <>
                                <button type="submit" id='submit' className="btn btn-success">Perbarui</button>
                                <button type="button" className="btn btn-danger" onClick={(e) => changeIdHapusTugas(e.currentTarget.id)} id={idTugasKuEdit}>Hapus</button>
                              </>
                            )
                          }

                          {
                            projekShareOrNo!=="yes" && (
                              <>
                                <button type="submit" id='submit' className="btn btn-success">Perbarui</button>
                                <button type="button" className="btn btn-danger" onClick={(e) => changeIdHapusTugas(e.currentTarget.id)} id={idTugasKuEdit}>Hapus</button>
                              </>
                            )
                          }
                        </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="modal fade" id="popupeditprojektugasku" tabindex="-1" aria-labelledby="popupeditprojektugaskuLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-55">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1 className="modal-title fs-5" id="popupeditprojektugaskuLabel"></h1>
                          <button type="button" className="btn-close close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <hr/>
                          <h5 className='font-medium'>Tambah Projek Tugas</h5>
                          <hr/>
                          <form method='POST' onSubmit={tambahProjekTugas} id='tambahProjekTugas'>
                            <div className='row'>
                              <div className='col-lg-10'>
                                <input required minLength="1" maxLength="255" type="text" id='judulprojektugas' className="formtext mb-3 form-control judulprojektugas" name='judulprojektugas' placeholder='.....' />
                              </div>
                              <div className='col-lg-2'>
                                <button type='submit' id='submit' className='d-block w-100 btn btn-primary'>Tambah</button>
                              </div>
                            </div>
                          </form>
                          <hr/>
                          <h5 className='font-medium'>Edit/Hapus Projek Tugas</h5>
                          <hr/>
                          <div className='row'>
                            {
                              Array.isArray(dataprojektugas) ?
                              dataprojektugas.map((data, i) => { 
                                // var pecahtugasku_projek_sharing = data.sharing_email.split(";");
                                var inew = i+1;
                                var inputclass = "formtext judulprojektugasedit judulprojektugasedit"+data.id_tugasku_projek+" form-control";
                                var idnameperbaruiprojektugas = "perbaruiprojektugas"+data.id_tugasku_projek;
                                return (
                                  <>
                                    {
                                      data.tugasku_projek_judul == "Tugas" && (
                                        <>
                                          <div key={i} className='col-lg-12 mb-4'>
                                            <input disabled type="text" id='judulkategori' className="form-control" value={data.tugasku_projek_judul} />
                                          </div>
                                        </>
                                      )
                                    }
                                    {
                                      data.tugasku_projek_judul !== "Tugas" && (
                                        <>
                                          <div key={i} className='col-lg-8 mb-2'>
                                            <input minLength="1" maxLength="255" type="text" id='judulkategori' className={inputclass} placeholder={data.tugasku_projek_judul} />
                                          </div>
                                          <div className='col-lg-2 col-6 mb-4' id={idnameperbaruiprojektugas}>
                                            <button type='button' className='d-block w-100 btn btn-success' onClick={(e) => perbaruiProjekTugas(e.currentTarget.id)} id={data.id_tugasku_projek}>Perbarui</button>
                                          </div>
                                          <div className='col-lg-2 col-6 mb-4'>
                                            <button type='button' className='d-block w-100 btn btn-danger' onClick={(e) => hapusProjekTugas(e.currentTarget.id)} id={data.id_tugasku_projek}>Hapus</button>
                                          </div>
                                        </>
                                      )
                                    }
                                  </>
                                )
                              })
                              : ""
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="offcanvas offcanvas-end" tabindex="-1" id="box-kategori-apps" aria-labelledby="box-kategori-appsLabel">
                    <div className="offcanvas-header">
                      <h5 className="offcanvas-title" id="offcanvasExampleLabel">Kategori</h5>
                      <button type="button" className="btn-close close-menu-on-mobile" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                      {
                        Array.isArray(dataprojektugas) ?
                        dataprojektugas.map((data, i) => {
                          var classname = "nav-link menukategori menukategori" + data.id_tugasku_projek;
                          if(appsetup=="tugasku") {
                            var tonavlink1 = "/tugas/"+data.id_tugasku_projek+"/";
                          }
                          else {
                            var tonavlink1 = "/tugasku/tugas/"+data.id_tugasku_projek+"/";
                          }
                          return (
                            <NavLink key={i} to={tonavlink1} className={classname} onClick={(e) => changeKategori(e.currentTarget.id)} id={data.id_tugasku_projek}>{data.tugasku_projek_judul}</NavLink>
                          )
                        })
                        : ""
                      }
                      <span className='nav-link menukategori' data-bs-toggle="modal" data-bs-target="#popupeditprojektugasku" onClick={closemenu}>Edit Projek Tugas</span>
                    </div>
                  </div>

                  <div className="modal fade" id="popupbagikanprojektugas" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-55">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button type="button" className="btn-close close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <hr/>
                          <h5 className='font-medium'>Bagikan Projek Tugas {judulProjek} <span className='badge bgcolor1 font-11 copylinktugasku' style={{cursor:"pointer"}} onClick={() => {navigator.clipboard.writeText(copylinktugasku)}}><i className='fa fa-link'></i> Copy Link</span></h5>
                          <hr/>
                          <div className='row' id='bagikanProjekTugas'>
                            <div className='col-lg-6 mb-2 col-6'>
                              <input placeholder='Masukkan Email' className={inputclassbagikanprojek} />
                            </div>
                            <div className='col-lg-4 col-6'>
                              <select className={selectclassbagikanprojek}>
                                <option value="full">Akses Penuh</option>
                                <option value="read">Hanya Baca</option>
                              </select>
                            </div>
                            <div className='col-lg-2 col-12'>
                              <button type='button' className='d-block w-100 btn btn-primary' id='submit' onClick={bagikanProjekTugas}>Tambah</button>
                            </div>
                          </div> 
                          {
                            projekSharingEmailJumlah > 0 && (
                              <>  
                                <hr/>
                                <h5 className='font-medium'>Email Dibagikan</h5>
                                <hr/>
                                <div className='row'>
                                {
                                  <>
                                    {
                                      Array.isArray(projekSharingEmail) ?
                                      projekSharingEmail.map((data, i) => {
                                        var inputclassbagikanprojek2 = "formemail formemailedit formemailedit"+data.id_sharing+" form-control";
                                        var selectclassbagikanprojek2 = "formpermissionedit"+data.id_sharing+" form-select";
                                        var idnameperbaruibagikanprojektugas = "perbaruibagikanprojektugas"+data.id_sharing;
                                        return (
                                          <>
                                            <div key={i} className='col-lg-4 mb-2 col-6'>
                                              <input className={inputclassbagikanprojek2} placeholder={data.sharing_email} />
                                            </div>
                                            <div className='col-lg-4 col-6'>
                                              <select className={selectclassbagikanprojek2}>
                                                {
                                                  data.sharing_permission=="full" && (
                                                    <>
                                                      <option value="full">Akses Penuh</option>
                                                      <option value="read">Hanya Baca</option>
                                                    </>
                                                  )
                                                }
                                                {
                                                  data.sharing_permission!=="full" && (
                                                    <>
                                                      <option value="read">Hanya Baca</option>
                                                      <option value="full">Akses Penuh</option>
                                                    </>
                                                  )
                                                }
                                              </select>
                                            </div>
                                            <div className='col-lg-2 col-6 mb-2' id={idnameperbaruibagikanprojektugas}>
                                              <button type='button' className='d-block w-100 btn btn-success' onClick={(e) => perbaruiBagikanProjekTugas(e.currentTarget.id)} id={data.id_sharing}>Perbarui</button>
                                            </div>
                                            <div className='col-lg-2 col-6 mb-4'>
                                              <button type='button' className='d-block w-100 btn btn-danger' onClick={(e) => hapusBagikanProjekTugas(e.currentTarget.id)} id={data.id_sharing}>Hapus</button>
                                            </div>
                                          </>
                                        )
                                      })
                                      : ""
                                    }
                                  </>
                                }
                                </div> 
                              </>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
              </>
              )
            }
          </>
        )
      }
    </>
  )
}

export default TugasKu;