import React from 'react';

function LoadingRipLabs(props) {
    return(
        <div>
          <div className='loading_riplabs'></div>
        </div>
    )
}

export default LoadingRipLabs;