import { statusweb, appkey, restapilink, baseurl, yearnow, baseurlassets, basecolor1, basecolorred, appsetup } from './Autoload'; 
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import SubHeader from './SubHeader';
import Header from './Header';
import Footer from './Footer';
import "../../node_modules/datatables.net-dt/js/dataTables.dataTables";
import "../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css";
const $ = require("jquery");   
const Swal = require('sweetalert2');

function rupiah(angka) {  
  var bilangan = angka;         
  var	number_string = bilangan.toString(),  
      sisa 	= number_string.length % 3,
      rupiah 	= number_string.substr(0, sisa),
      ribuan 	= number_string.substr(sisa).match(/\d{3}/g);
  if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
  } 
  return rupiah;
}
function ucwords (str) {
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
      return $1.toUpperCase();
  });
} 
function strtolower (str) {
  return (str+'').toLowerCase();
} 
function ucfirst (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}


function ShareFiles(props) {
  const token = localStorage.getItem('token'); 
  const tmptoken = localStorage.getItem('tmptoken'); 
  const navigate = useNavigate();
  const [ceksesilogin2, setceksesilogin2] = useState(0);
  const [PenggunaPopupIntro4, setPenggunaPopupIntro4] = useState('no');
  const[sharefileskapasitas, setsharefileskapasitas] = useState('0');
  const[sharefileskapasitas2awal, setsharefileskapasitas2awal] = useState('0b');
  const[datafilesaya, setdatafilesaya] = useState([]);
  const[jumlahdatafilesaya, setjumlahdatafilesaya] = useState(0);
  const cekKapasitasTerpakai = async () => {
    await axios.post(restapilink+"api/sharefiles/cekfilesaya", {
      key:appkey,
      token:token,
      tmptoken:tmptoken,
      limit:''
    }).then((response) => {
      // console.log(response);
      if(response.data.kapasitasterpakai) {
        setsharefileskapasitas(response.data.kapasitasterpakai);
      }
      if(response.data.kapasitasterpakai2) {
        setsharefileskapasitas2awal(response.data.kapasitasterpakai2);
      }
      if(response.data.jumlah>0) {
        setdatafilesaya(response.data.data);
      }
      if(response.data.jumlah) {
        setjumlahdatafilesaya(response.data.jumlah);
      }
    });
  }

  const[nama, setSessionNama] = useState('');
  const getCekLogin2 = async () => {
    await axios.post(restapilink+"api/auth/ceklogin", {
      key:appkey,
      token:token
    }).then((response) => {
      if(response.data.ceklogin == 0) {
        if(!tmptoken) {
          localStorage.setItem('tmptoken',response.data.tmptoken);
        }
      }
      setceksesilogin2(response.data.ceklogin)
      setSessionNama(response.data.nama);
      if(response.data.ceklogin == 0) {}
      else {
        if(response.data.datapengguna.pengguna_popupintro2) {
          setPenggunaPopupIntro4(response.data.datapengguna.pengguna_popupintro4);
        }
      }
    });
  }

  const unggahfile = async (e) => {
    $("#file").click();
  }

  const [selectedFile, setSelectedFile] = useState(null);
  const inputunggahfile = async (e) => {
    setSelectedFile(e.target.files[0]);
    $(".unggahfile").css('display','none');
    $(".boxunggahfile").css('display','flex');
  }

  const uploadFileHandler = async(e) => {
    e.preventDefault();
    const formData = new FormData(); 
    formData.append("key", appkey); 
    formData.append("token", token); 
    formData.append("tmptoken", tmptoken);
    formData.append("file", selectedFile);
    $("form#formunggahfile #submit").attr('disabled','disabled');
    try {
      $(".loading_riplabs").css('display','block');
      const response = await axios({
        method: "post",
        url: restapilink+"uploadsharefiles.php",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      // console.log(response);
      if(response.data.status=="error") {
        $(".loading_riplabs").css('display','none');
        $("form#formunggahfile #submit").removeAttr('disabled');
        Swal.fire({
          title: 'Oops...',
          html: response.data.message + ' <i class="fa fa-smile-beam"></i>',
          icon: 'error',
          confirmButtonColor: basecolor1
        });
        return false;
      }
      else {
        $(".loading_riplabs").css('display','none');
        $("form#formunggahfile #file").val('');
        setSelectedFile(null);
        $("form#formunggahfile #submit").removeAttr('disabled');
        // if(jumlahdatafilesaya<=1) {
        //   Swal.fire({
        //     title: 'Berhasil',
        //     html: 'File sudah diunggah <i className="fa fa-smile-beam"></i>',
        //     icon: 'success',
        //     confirmButtonColor: basecolor1,
        //     allowOutsideClick: false, 
        //     allowEscapeKey: false
        //   }).then((result) => {
        //     if (result.value) {
        //       window.location.reload();
        //     }
        //   });
        // }
        // else {
          Swal.fire({
            title: 'Berhasil',
            html: 'File sudah diunggah <i className="fa fa-smile-beam"></i>',
            icon: 'success',
            confirmButtonColor: basecolor1
          });
        // }
        $(".unggahfile").css('display','block');
        $(".boxunggahfile").css('display','none');
        cekKapasitasTerpakai();
      }
    } catch(error) {
      // console.log(error)
    }
  }


  const hapusFile = async (e) => {
    Swal.fire({
      title: 'Konfirmasi',
      html: 'Apakah kamu yakin ingin menghapus File ini?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: basecolor1,
      cancelButtonColor: basecolorred,
      confirmButtonText: 'Ya, Lanjutkan',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.value) {
        $(".loading_riplabs").css('display','block');
        try {
          $(".loading_riplabs").css('display','none');
          axios.post(restapilink+"api/sharefiles/hapusfile", {
            key:appkey,
            token: token, 
            tmptoken:tmptoken,
            id:e
          });
          // console.log(response.data);
          // if(response.data.status=="error") {
          //   $(".loading_riplabs").css('display','none');
          //   Swal.fire({
          //     title: 'Oops...',
          //     html: response.data.message + ' <i class="fa fa-smile-beam"></i>',
          //     icon: 'error',
          //     confirmButtonColor: basecolor1
          //   });
          //   return false;
          // }
          // else {
            Swal.fire({
              title: 'Berhasil',
              html: 'File sudah dihapus <i className="fa fa-smile-beam"></i>',
              icon: 'success',
              confirmButtonColor: basecolor1
            });
            setTimeout(function() {
              cekKapasitasTerpakai(); 
            }, 500); 
          // }
        }
        catch(e) {
          // console.log(e);
        }
      }
    });
  }

  useEffect(() => {
    getCekLogin2();
    // if(ceksesilogin2 > 0 && PenggunaPopupIntro4 == 'no') {
    //   setTimeout(function() {
    //     $(".showmodalintroutama").click();
    //   }, 2000);
    // }
    cekKapasitasTerpakai();
    // alert(token)
    // alert(tmptoken)
  }, [ ceksesilogin2, jumlahdatafilesaya, PenggunaPopupIntro4 ]); 
  
  setTimeout(function(){
    $('#tabledata').DataTable({
      aLengthMenu:[[10,25,50,100,250,500,1000,-1],[10,25,50,100,250,500,1000,'All']]
    });
  }, 2500); 

  $(".copylinkfile").click(function(){
    var id = $(this).attr('id');
    $(".copylinkfile"+id).attr("class","btn btn-success btn-sm m-1 copylinkfile"+id);
    $(".copylinkfile"+id).html("<i class='fa fa-check-circle'></i>");
    setTimeout(function(){
      $(".copylinkfile"+id).attr("class","btn btn-success btn-sm m-1 copylinkfile"+id);
      $(".copylinkfile"+id).html("<i class='fa fa-copy'></i>");
    }, 1500);
  })

  

  var pecahsharefileskapasitas = sharefileskapasitas.split(".");
  var sharefileskapasitas2 = pecahsharefileskapasitas[0];
  var sharefileskapasitas3 = pecahsharefileskapasitas[0]+"%";

  return(
    <>
      <title>Aplikasi ShareFiles | RipLabs Indonesia</title>
      <meta name="title" content="Aplikasi ShareFiles | RipLabs Indonesia" />
      <meta itemProp="name" content="Aplikasi ShareFiles | RipLabs Indonesia" />
      <meta property="og:title" content="Aplikasi ShareFiles | RipLabs Indonesia" />
      <meta name="twitter:title" content="Aplikasi ShareFiles | RipLabs Indonesia" />
      <Header/>
      <div className="bodypage pagehome pagesharefiles animated fadeIn">
        <div className='hero'>
          <div className='container'>
            <h1 className='text-center d-block'>Penyimpanan dan berbagi dokumen dengan mudah</h1>
            <br/>
            <center>
              <button className='btn btn-primary unggahfile' onClick={unggahfile}><i className='fa fa-upload'></i> UPLOAD FILE</button>
            </center>
            <form method='POST' id='formunggahfile' onSubmit={uploadFileHandler}>
            <div className='boxunggahfile' style={{display:"none"}}>
                <input required accept='.jpeg,.jpg,.png,.pdf,.docx,.zip,.rar,.mp3,.m4a,.mp4,.3gp,.mpeg,.mov,.gif,.doc,.xls,.xlsx' type='file' className='form-control' onChange={inputunggahfile} id='file' />
                &nbsp;&nbsp;&nbsp;
                <button className='btn btn-primary' id='submit' type='submit'>Upload</button>
            </div>
            </form>
          </div>
        </div>
        <section className='riplabsapps2'>
          <div className='container'>
            <h3 className='riplabs-h3'>Daftar File Saya</h3>
            {/* <p style={{color:"var(--basecolor6)"}} className="mt-3">Berikut daftar File yang masih aktif dan sudah tidak aktif/terhapus.</p> */}
            <hr/>
            <div className="progress" role="progressbar" aria-label="Default striped example" aria-valuenow="10" aria-valuemin="0" aria-valuemax={sharefileskapasitas2}>
              <div className="progress-bar progress-bar-striped" style={{width:sharefileskapasitas3}}></div>
            </div>
            <span className='color2 mt-2 d-block font-15'>Terpakai {sharefileskapasitas2awal} dari 100MB.</span>
            <hr/>
            <div className="table-responsive">
            <table id="tabledata" className="table table-bordered" style={{paddingTop:"16px"}}>
              <thead>
                <tr className='bgcolor1 text-white'>
                  <th style={{textAlign:"center",width:"1%"}}>#</th>
                  <th style={{width:"50%"}}>File</th>
                  <th style={{textAlign:"center",width:"10%"}}>Kapasitas</th>
                  <th style={{textAlign:"center",width:"15%"}}>Batas<span style={{color:"var(--basecolor1)"}}>_</span>Aktif</th>
                  <th style={{textAlign:"center",width:"5%"}}>Status</th>
                  <th style={{textAlign:"center",width:"15%"}}>#</th>
                </tr>
              </thead>
              <tbody>
              {
                jumlahdatafilesaya > 0 && (
                  <>
                    {
                      datafilesaya.map((data, i) => {
                        var no = i+1;
                        if(data.sharefiles_kapasitas>0 && data.sharefiles_kapasitas<100000) {
                          var sharefiles_kapasitas = (data.sharefiles_kapasitas / 100000).toFixed(2) + "KB";
                        }
                        else if(data.sharefiles_kapasitas>100000 && data.sharefiles_kapasitas<10000000) {
                          var sharefiles_kapasitas = (data.sharefiles_kapasitas / 10000000).toFixed(2) + "MB";
                        }
                        if(statusweb=="development") {
                          var baseurlsharefiles = baseurl;
                        }
                        else {
                          var baseurlsharefiles = "https://sharefiles.riplabs.id/";
                        }
                        var linktofile = baseurlsharefiles+"file/"+data.id_sharefiles+"/";
                        if(data.STATUS=="1") {
                          var sharefilesstatus = "Aktif"
                          var classsharefilesstatus = "badge bg-success"
                        }
                        else {
                          var sharefilesstatus = "NonAktif"
                          var classsharefilesstatus = "badge bg-danger"
                        }
                        var copylinkfile = baseurlsharefiles+"file/"+data.id_sharefiles+"/";
                        var classnamebtncopylinkfile = 'btn btn-sm btn-success m-1 copylinkfile copylinkfile'+data.id_sharefiles;
                        return (
                          <tr key={i}>
                            <td className='text-center'>{no}</td>
                            <td><a href={linktofile} className='judullink textdecorationnone' target='_blank'>{data.sharefiles_judul}</a></td>
                            <td className='text-center'>{sharefiles_kapasitas}</td>
                            <td className='text-center'>{data.sharefiles_batas}</td>
                            <td className='text-center'>
                              <span class={classsharefilesstatus}>{sharefilesstatus}</span>
                            </td>
                            <td className='text-center'>
                            {
                              data.STATUS > 0 && (
                                <>
                                  <a href={linktofile} target='_blank' className='btn btn-sm btn-primary m-1'><i className='fa fa-paper-plane'></i></a>
                                  <button id={data.id_sharefiles} className={classnamebtncopylinkfile} type='button' onClick={() => {navigator.clipboard.writeText(copylinkfile)}}><i className='fa fa-copy'></i></button>
                                  <button className='btn btn-sm btn-danger m-1' type='button' onClick={(e) => hapusFile(e.currentTarget.id)} id={data.id_sharefiles}><i className='fa fa-trash-alt'></i></button>
                                </>
                              )
                            }
                            </td>
                            {/* <td> */}
                              {/* <a className='linkebookdownlod' target="_blank" href={baseurlebooks+ebook.ebook_filename}>{ucfirst(ebook.ebook_name)}</a> */}
                            {/* </td> */}
                            {/* <td style={{textAlign:"center",width:"1%"}}> */}
                              {/* <a href={baseurl+'ebook/'+strtolower(ebook.ebook_category)+'/'}><small className='badge bgcolor3'>{ucwords(ebook.ebook_category)}</small></a> */}
                            {/* </td> */}
                          </tr>
                        )
                      }) 
                    }
                  </>
                )
              }
              </tbody>
            </table>
          </div>
          </div>
        </section>
      </div>
      <br/>
      <br/>
      <Footer/>
    </>
  )
}

export default ShareFiles;