import * as autoload from './Autoload'; 
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
import SubHeader from './SubHeader';
import '../styles/WebRipLabs.css'; 
// import MenuNavbarDesktop from './MenuNavbarDesktop';
const $ = require("jquery");   

function Header2(props) {
  // $("html, body").animate({ scrollTop: 0 }, 50);
  const navigate = useNavigate();
  // $("html, body").animate({ scrollTop: 0 }, 50);
  // if(window.location.origin=="http://arifsiddikm.com") {
  //   window.location.href="https://arifsiddikm.com/";
  // }

  const token = localStorage.getItem('token');
  // const [nama, setSessionNama] = useState('');
  const [ceksesilogin, setceksesilogin] = useState(0);
  const [idtugaskuprojekawal, setidtugaskuprojekawal] = useState('');

  // if(!token) {
    // navigate('/login'); 
    // document.location.href=baseurl+'login/'; 
    // return false;
  // }
  
  // const[user, setUser] = useState({});
  const fetchData = async () => {
    await axios.post(autoload.restapilink+"api/auth/ceklogin", {
      key:autoload.appkey,
      token:token
    }).then((response) => {
      setceksesilogin(response.data.ceklogin)
      if(response.data.ceklogin == 0) {
        localStorage.removeItem('token');
        localStorage.removeItem('kategoricatatankudefault');
        localStorage.removeItem('catatanlist');
      }
    });
  }

  const logoutHandler = (e) => {
    localStorage.removeItem('token'); 
    localStorage.removeItem('kategoricatatankudefault');
    localStorage.removeItem('catatanlist');
    navigate('/login'); 
  }

  const cekProjekTugasAwal = async() => {
    try {
      let response = await axios.post(autoload.restapilink+"api/tugasku/cekprojektugasawal", {
        key:autoload.appkey,
        token: token
      });
      setidtugaskuprojekawal(response.data.id);
    }
    catch(e) {}
  }

  useEffect(() => {
    fetchData();
    cekProjekTugasAwal();
  }, [ ceksesilogin ]);

  $("#box-menu-apps .nav-link").click(function(){
    $(".close-menu-on-mobile").click(); 
  })

  // setTimeout(function(){
  //   document.location.href=autoload.baseurl+'dashboard/';
  // }, 1800000);


  const hidemenuappsHandler = (e) => {
    $(".aplikasiku-wrapper .sidebar").css('width','0%');
    $(".aplikasiku-wrapper .sidebar").css('left','-100%');
    $(".aplikasiku-wrapper .body").css('width','100%');
    $(".aplikasiku-wrapper .sidebar a").css('display','none');
    $(".aplikasiku-wrapper .sidebar span").css('display','none');
    $(".showhidemenuapps1").css('display','none');
    $(".showhidemenuapps2").css('display','inline-flex');
  }
  const showmenuappsHandler = (e) => {
    $(".aplikasiku-wrapper .sidebar").css('width','18%');
    $(".aplikasiku-wrapper .body").css('width','82%');
    $(".aplikasiku-wrapper .sidebar a").css('display','block');
    $(".aplikasiku-wrapper .sidebar span").css('display','block');
    $(".showhidemenuapps1").css('display','inline-flex');
    $(".showhidemenuapps2").css('display','none');
  }

  const closemenu = (e) => {
    $(".offcanvas .btn-close").click(); 
  }

  if(autoload.appsetup=="catatanku") {
    var linktopagecatatanku = "/catatan/all/";
    var linktopagetugasku = "/tugasku/tugas/"+idtugaskuprojekawal+"/";
    var linktopagedompetku = "/dompetku/";
    var linktopagesharefiles = "/sharefiles/";
  }
  else if(autoload.appsetup=="tugasku") {
    var linktopagecatatanku = "/catatanku/catatan/all/";
    var linktopagetugasku = "/tugas/"+idtugaskuprojekawal+"/";
    var linktopagedompetku = "/dompetku/";
    var linktopagesharefiles = "/sharefiles/";
  }
  else if(autoload.appsetup=="dompetku") {
    var linktopagecatatanku = "/catatanku/catatan/all/";
    var linktopagetugasku = "/tugasku/tugas/"+idtugaskuprojekawal+"/";
    var linktopagedompetku = "/";
    var linktopagesharefiles = "/sharefiles/";
  }
  else if(autoload.appsetup=="sharefiles") {
    var linktopagecatatanku = "/catatanku/catatan/all/";
    var linktopagetugasku = "/tugasku/tugas/"+idtugaskuprojekawal+"/";
    var linktopagedompetku = "/dompetku/";
    var linktopagesharefiles = "/";
  }
  else {
    var linktopagecatatanku = "/catatanku/catatan/all/";
    var linktopagetugasku = "/tugasku/tugas/"+idtugaskuprojekawal+"/";
    var linktopagedompetku = "/dompetku/";
    var linktopagesharefiles = "/sharefiles/";
  }

  return(
    <>

      <div className='navbar-desktop header2'>
        <div className='container'>
          <div className='left'>
            <a href='javascript:void(0);' onClick={hidemenuappsHandler} className='showhidemenuapps showhidemenuapps1' statussidebar='show'><i className='fa fa-bars'></i></a>
            <a href='javascript:void(0);' onClick={showmenuappsHandler} className='showhidemenuapps showhidemenuapps2' style={{display:"none"}} statussidebar='show'><i className='fa fa-bars'></i></a>
            {/* <NavLink to="/"><img src='https://assetsriplabsid.arifsiddikm.com/images/riplabs/Logo%20RipLabs.png' className='logo' /></NavLink> */}
          </div>
          <div className='right'>
            <a className="nav-link" data-bs-toggle="offcanvas" href="#box-menu-apps" role="button" aria-controls="box-menu-apps">Aplikasi</a>
            {
              token && (
                <>
                  <NavLink to="/dashboard/" href="javascript:void(0);" className="nav-link p-0 m-0"><i className='fa fa-user-circle fa-3x'></i></NavLink>
                </>
              )
            }
          </div>
        </div>
      </div>

      <div className="navbar-mobile">
        <div className='container'>
          {
            ceksesilogin > 0 && (
              <NavLink to="/dashboard/" className="todashboardpage">
                <div className='image color2'>
                  <img />
                  <i className='fa fa-user-circle color2 fa-2x'></i>
                </div>
              </NavLink>
            )
          }
          <div>
          <div className='linkmenu linkmenukategoriapps' data-bs-toggle="offcanvas" href="#box-menu-apps" role="button" aria-controls="box-menu-apps">APLIKASI</div>
          &nbsp;&nbsp;&nbsp;
          <div className='linkmenu linkmenukategoriapps' data-bs-toggle="offcanvas" href="#box-kategori-apps" role="button" aria-controls="box-kategori-apps">KATEGORI</div>
          </div>
        </div>
      </div>


      <div className="offcanvas offcanvas-end" tabindex="-1" id="box-menu-apps" aria-labelledby="box-menu-appsLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">Aplikasi</h5>
          <button type="button" className="btn-close close-menu-on-mobile" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <NavLink to={linktopagecatatanku} className='nav-link'>CatatanKu</NavLink>
          <NavLink to={linktopagetugasku} className='nav-link'>TugasKu</NavLink>
          <NavLink to={linktopagedompetku} className='nav-link'>DompetKu</NavLink>
          <NavLink to={linktopagesharefiles} className='nav-link'>ShareFiles</NavLink>
        </div>
      </div>
    </>
    )
}
export default Header2;