import { appkey, restapilink, baseurl, yearnow, baseurlassets, basecolor1, basecolorred } from './Autoload'; 
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import SubHeader from './SubHeader';
import ReactPaginate from 'react-paginate';
const $ = require("jquery");   
const Swal = require('sweetalert2');

function rupiah(angka) {  
  var bilangan = angka;         
  var	number_string = bilangan.toString(),  
      sisa 	= number_string.length % 3,
      rupiah 	= number_string.substr(0, sisa),
      ribuan 	= number_string.substr(sisa).match(/\d{3}/g);
  if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
  } 
  return rupiah;
}
function ucwords (str) {
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
      return $1.toUpperCase();
  });
} 
function strtolower (str) {
  return (str+'').toLowerCase();
} 
function ucfirst (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function Jasa(props) {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  // const [ceksesilogin, setceksesilogin] = useState(0);
  const [listjasariplabs, setlistjasariplabs] = useState([]);
  const [jumlahjasariplabs, setjumlahjasariplabs] = useState(0);
  const [kategorijasariplabs, setkategorijasariplabs] = useState([]);
  const [jumlahkategorijasariplabs, setjumlahkategorijasariplabs] = useState(0);
  const [judulPencarian, setJudulPencarian] = useState('');

  const [keyword, setkeyword] = useState('');
  const [kategori, setkategori] = useState('All');
  // getListJasaRipLabs
  const getListJasaRipLabs = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response2 = await axios.post(restapilink+"api/data/listjasariplabs", {
        key:appkey,
        keyword:keyword,
        kategori:kategori
      });
      setlistjasariplabs(response2.data.data);
      setjumlahjasariplabs(response2.data.jumlah);
      setJudulPencarian(response2.data.judul);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {console.log(e.message);}
  }
  const getKategoriJasaRipLabs = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response2 = await axios.post(restapilink+"api/data/kategorijasariplabs", {
        key:appkey
      });
      setkategorijasariplabs(response2.data.data);
      setjumlahkategorijasariplabs(response2.data.jumlah);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {console.log(e.message);}
  }
  const searchHandler = async (e) => {
    setkeyword(e);
  }
  const kategoriHandler = async (e) => {
    setkategori(e);
  }
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 9;
  const handlePageClick = (e) => {
    const newOffset = (e.selected * itemsPerPage) % listjasariplabs.length;
    setItemOffset(newOffset);
  }

  useEffect(() => {
    getListJasaRipLabs();
    getKategoriJasaRipLabs();

    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(listjasariplabs.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(listjasariplabs.length / itemsPerPage));

    var kategori2 = kategori.toLowerCase().replaceAll(" ","");
    $(".listkategorimenu").removeClass(" active ");
    $(".listkategorimenu"+kategori2).addClass(" active ");

  }, [ jumlahjasariplabs, itemOffset, itemsPerPage, keyword, kategori ]); 

  return(
    <>
      <title>Jasa RipLabs | RipLabs Indonesia</title>
      <meta name="title" content="Jasa RipLabs | RipLabs Indonesia" />
      <meta itemProp="name" content="Jasa RipLabs | RipLabs Indonesia" />
      <meta property="og:title" content="Jasa RipLabs | RipLabs Indonesia" />
      <meta name="twitter:title" content="Jasa RipLabs | RipLabs Indonesia" />
      <div className='bodypage pagejasa animated fadeIn'>
        <SubHeader title="Jasa" desc="Beberapa Jasa RipLabs yang bisa kamu order." />
        <section className='riplabsapps riplabsservices' style={{marginBottom:"60px"}}>
          <div className='container'>
            {/* Search Data */}
            <input type="search" className='boxdompetku1 mb-0' placeholder='Cari judul atau kategori jasa...' onChange={(e) => searchHandler(e.target.value)} />
            <br/>
            <div className='listkategoridata'>
            <span className="listkategorimenu listkategorimenuall" onClick={(e) => kategoriHandler(e.currentTarget.id)} id="All">All</span>
            {
              Array.isArray(kategorijasariplabs) ?
                kategorijasariplabs.map((data, i) => {
                  var classnamelist = "listkategorimenu listkategorimenu"+data.jasa_kategori.toLowerCase().replaceAll(" ","");
                  return (
                    <span key={i} className={classnamelist} id={data.jasa_kategori} onClick={(e) => kategoriHandler(e.currentTarget.id)}>{data.jasa_kategori}</span>
                  )
                })
              : ""
            }
            </div>
            <hr/>
            <h4 className='font-semibold'>{judulPencarian}</h4>
            {
              jumlahjasariplabs > 0 && (
                <>
                  <div className="row justify-content-center rowcard1 rowcardnoborder card-groupp">
                    {
                      Array.isArray(currentItems) ?
                      currentItems.map((data, i) => {
                        let listjasariplabsimg = data.jasa_thumbnail ? baseurlassets+'images/jasa/thumbnail/' + data.jasa_thumbnail : '';
                        return (
                          <div key={i} className="card col-lg-4 p-3 mb-3">
                            <div className='image'>
                              <img src={listjasariplabsimg} className="card-img-top" alt={listjasariplabsimg} />
                            </div>
                            <div className="card-body">
                              <h5 className="card-title mb-1">{data.jasa_judul}</h5>
                              <div className='color1 font-medium mb-3'>{data.jasa_kategori} | {data.jasa_digunakan}x Digunakan</div>
                              <p className="card-text">{data.jasa_deskripsi}</p>
                            </div>
                            <div className="card-footer">
                              <h5 className='estimasiharga d-block mt-3'>{data.jasa_harga}</h5>
                              <a target="_blank" href={data.jasa_cta_link} className="font-medium d-block mt-3 textdecorationnone color1">{data.jasa_cta_teks}</a>
                            </div>
                          </div>
                        )
                      })
                      : ""
                    }
                  </div>
                  <br/>
                  <br/>
                  <center>
                    <ReactPaginate 
                      breakLabel="..."
                      nextLabel="Next"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={2}
                      marginPagesDisplayed={1}
                      pageCount={pageCount}
                      previousLabel="Previous"
                      renderOnZeroPageCount={null}
                      containerClassName="pagination m-auto d-inline-flex"
                      pageClassName="page-item"
                      breakClassName='page-item'
                      breakLinkClassName='page-link'
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      activeClassName="active"
                      activeLinkClassName="active"
                    />
                  </center>
                </>
              )
            }

            </div>
        </section>
      </div>
    </>
  )
}

export default Jasa;  