import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { appsetup, restapilink, baseurl, yearnow, baseurlassets, basecolor1 } from '../components/Autoload';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Home from '../components/Home';
import Login from '../components/Login';
import Register from '../components/Register';
import Dashboard from '../components/Dashboard';
import Account from '../components/Account';
import CatatanKu from '../components/CatatanKu';
import TugasKu from '../components/TugasKu';
import DompetKu from '../components/DompetKu';
import HeaderDompetKu from '../components/HeaderDompetKu';
import ShareFiles from '../components/ShareFiles';
import Aplikasi from '../components/Aplikasi';
import Produk from '../components/Produk';
import Jasa from '../components/Jasa';
import Portofolio from '../components/Portofolio';
import Artikel from '../components/Artikel';
import ArtikelDetail from '../components/ArtikelDetail';
import FreeChat from '../components/FreeChat';
import NotFound from '../components/NotFound';
import Header2 from '../components/Header2';
import KebijakanPrivasi from '../components/KebijakanPrivasi';
import SyaratKetentuan from '../components/SyaratKetentuan';
import SendToken from '../components/SendToken';
import Logout from '../components/Logout';
import ShareFilesDownload from '../components/ShareFilesDownload';

function Router(props) {
return(
<Routes>
    {
        appsetup == "utama" && (
            <>
            <Route path='/' element={<><Header/><Home/><Footer/></>} />
            {/* CatatanKu */}
            <Route path='/catatan' element={<><CatatanKu/></>}/>
            <Route path='/catatan/:idcatatankukategori' element={<><CatatanKu/></>}/>
            <Route path='/catatanku' element={<><CatatanKu/></>}/>
            <Route path='/catatanku/catatan' element={<><CatatanKu/></>}/>
            <Route path='/catatanku/catatan/:idcatatankukategori' element={<><CatatanKu/></>}/>
            {/* TugasKu */}
            <Route path='/tugas' element={<><TugasKu/></>}/>
            <Route path='/tugas/:idtugaskuprojek' element={<><TugasKu/></>}/>
            <Route path='/tugasku' element={<><TugasKu/></>}/>
            <Route path='/tugasku/tugas' element={<><TugasKu/></>}/>
            <Route path='/tugasku/tugas/:idtugaskuprojek' element={<><TugasKu/></>}/>
            {/* DompetKu */}
            <Route path='/dompetku' element={<><DompetKu/></>} />
            <Route path='/dompetku/:otherpage' element={<><DompetKu/></>} />
            {/* ShareFiles */}
            <Route path='/sharefiles' element={<><ShareFiles/></>} />
            <Route path='/sharefiles/file/:idsharefiles' element={<><ShareFilesDownload/></>} />
            <Route path='/file/:idsharefiles' element={<><ShareFilesDownload/></>} />
            </>
        )
    }
    {
        appsetup == "catatanku" && (
            <>
            {/* CatatanKu */}
            <Route path='/' element={<><CatatanKu/></>} />
            <Route path='/catatan' element={<><CatatanKu/></>}/>
            <Route path='/catatan/:idcatatankukategori' element={<><CatatanKu/></>}/>
            <Route path='/catatanku' element={<><CatatanKu/></>}/>
            <Route path='/catatanku/catatan' element={<><CatatanKu/></>}/>
            <Route path='/catatanku/catatan/:idcatatankukategori' element={<><CatatanKu/></>}/>
            {/* TugasKu */}
            <Route path='/tugas' element={<><TugasKu/></>}/>
            <Route path='/tugas/:idtugaskuprojek' element={<><TugasKu/></>}/>
            <Route path='/tugasku' element={<><TugasKu/></>}/>
            <Route path='/tugasku/tugas' element={<><TugasKu/></>}/>
            <Route path='/tugasku/tugas/:idtugaskuprojek' element={<><TugasKu/></>}/>
            {/* DompetKu */}
            <Route path='/dompetku' element={<><DompetKu/></>} />
            <Route path='/dompetku/:otherpage' element={<><DompetKu/></>} />
            {/* ShareFiles */}
            <Route path='/sharefiles' element={<><ShareFiles/></>} />
            <Route path='/sharefiles/file/:idsharefiles' element={<><ShareFilesDownload/></>} />
            <Route path='/file/:idsharefiles' element={<><ShareFilesDownload/></>} />
            </>
            
        )
    }
    {
        appsetup == "tugasku" && (
            <>
            {/* TugasKu */}
            <Route path='/' element={<><TugasKu/></>} />
            <Route path='/tugas' element={<><TugasKu/></>}/>
            <Route path='/tugas/:idtugaskuprojek' element={<><TugasKu/></>}/>
            <Route path='/tugasku' element={<><TugasKu/></>}/>
            <Route path='/tugasku/tugas' element={<><TugasKu/></>}/>
            <Route path='/tugasku/tugas/:idtugaskuprojek' element={<><TugasKu/></>}/>
            {/* CatatanKu */}
            <Route path='/catatan' element={<><CatatanKu/></>}/>
            <Route path='/catatan/:idcatatankukategori' element={<><CatatanKu/></>}/>
            <Route path='/catatanku' element={<><CatatanKu/></>}/>
            <Route path='/catatanku/catatan' element={<><CatatanKu/></>}/>
            <Route path='/catatanku/catatan/:idcatatankukategori' element={<><CatatanKu/></>}/>
            {/* DompetKu */}
            <Route path='/dompetku' element={<><DompetKu/></>} />
            <Route path='/dompetku/:otherpage' element={<><DompetKu/></>} />
            {/* ShareFiles */}
            <Route path='/sharefiles' element={<><ShareFiles/></>} />
            <Route path='/sharefiles/file/:idsharefiles' element={<><ShareFilesDownload/></>} />
            <Route path='/file/:idsharefiles' element={<><ShareFilesDownload/></>} />
            </>
        )
    }
    {
        appsetup == "dompetku" && (
            <>
            {/* DompetKu */}
            <Route path='/' element={<><DompetKu/></>} />
            <Route path='/:otherpage' element={<><DompetKu/></>} />
            <Route path='/dompetku/:otherpage' element={<><DompetKu/></>} />
            {/* CatatanKu */}
            <Route path='/catatan' element={<><CatatanKu/></>}/>
            <Route path='/catatan/:idcatatankukategori' element={<><CatatanKu/></>}/>
            <Route path='/catatanku' element={<><CatatanKu/></>}/>
            <Route path='/catatanku/catatan' element={<><CatatanKu/></>}/>
            <Route path='/catatanku/catatan/:idcatatankukategori' element={<><CatatanKu/></>}/>
            {/* TugasKu */}
            <Route path='/tugas' element={<><TugasKu/></>}/>
            <Route path='/tugas/:idtugaskuprojek' element={<><TugasKu/></>}/>
            <Route path='/tugasku' element={<><TugasKu/></>}/>
            <Route path='/tugasku/tugas' element={<><TugasKu/></>}/>
            <Route path='/tugasku/tugas/:idtugaskuprojek' element={<><TugasKu/></>}/>
            {/* ShareFiles */}
            <Route path='/sharefiles' element={<><ShareFiles/></>} />
            <Route path='/sharefiles/file/:idsharefiles' element={<><ShareFilesDownload/></>} />
            <Route path='/file/:idsharefiles' element={<><ShareFilesDownload/></>} />
            </>
        )
    }
    {
        appsetup == "sharefiles" && (
            <>
            {/* ShareFiles */}
            <Route path='/' element={<><ShareFiles/></>} />
            <Route path='/file/:idsharefiles' element={<><ShareFilesDownload/></>} />
            <Route path='/sharefiles' element={<><ShareFiles/></>} />
            <Route path='/sharefiles/file/:idsharefiles' element={<><ShareFilesDownload/></>} />
            {/* CatatanKu */}
            <Route path='/catatan' element={<><CatatanKu/></>}/>
            <Route path='/catatan/:idcatatankukategori' element={<><CatatanKu/></>}/>
            <Route path='/catatanku' element={<><CatatanKu/></>}/>
            <Route path='/catatanku/catatan' element={<><CatatanKu/></>}/>
            <Route path='/catatanku/catatan/:idcatatankukategori' element={<><CatatanKu/></>}/>
            {/* TugasKu */}
            <Route path='/tugas' element={<><TugasKu/></>}/>
            <Route path='/tugas/:idtugaskuprojek' element={<><TugasKu/></>}/>
            <Route path='/tugasku' element={<><TugasKu/></>}/>
            <Route path='/tugasku/tugas' element={<><TugasKu/></>}/>
            <Route path='/tugasku/tugas/:idtugaskuprojek' element={<><TugasKu/></>}/>
            {/* DompetKu */}
            <Route path='/dompetku' element={<><DompetKu/></>} />
            <Route path='/dompetku/:otherpage' element={<><DompetKu/></>} />
            </>
        )
    }

    <Route path='/login' element={<><Login/></>}/>
    <Route path='/masuk' element={<><Login/></>}/>
    <Route path='/register' element={<><Register/></>}/>
    <Route path='/daftar' element={<><Register/></>}/>
    <Route path='/dashboard' element={<><Header/><Dashboard/><Footer/></>}/>
    <Route path='/account' element={<><Header/><Account/><Footer/></>}/>
    
    {/* <Route path='/sharefiles' element={<><Header2/><ShareFiles/></>}/> */}

    <Route path='/aplikasi' element={<><Header/><Aplikasi/><Footer/></>}/>
    <Route path='/applications' element={<><Header/><Aplikasi/><Footer/></>}/>
    <Route path='/apps' element={<><Header/><Aplikasi/><Footer/></>}/>
    <Route path='/app' element={<><Header/><Aplikasi/><Footer/></>}/>
    <Route path='/jasa' element={<><Header/><Jasa/><Footer/></>}/>
    <Route path='/services' element={<><Header/><Jasa/><Footer/></>}/>
    <Route path='/service' element={<><Header/><Jasa/><Footer/></>}/>
    <Route path='/produk' element={<><Header/><Produk/><Footer/></>}/>
    <Route path='/products' element={<><Header/><Produk/><Footer/></>}/>
    <Route path='/product' element={<><Header/><Produk/><Footer/></>}/>
    <Route path='/portofolio' element={<><Header/><Portofolio/><Footer/></>}/>
    <Route path='/portfolio' element={<><Header/><Portofolio/><Footer/></>}/>
    <Route path='/artikel' element={<><Header/><Artikel/><Footer/></>}/>
    <Route path='/article' element={<><Header/><Artikel/><Footer/></>}/>
    <Route path='/artikel/:uri' element={<><Header/><ArtikelDetail/><Footer/></>}/>
    <Route path='/article/:uri' element={<><Header/><ArtikelDetail/><Footer/></>}/>

    <Route path='/sendtoken/:token/from/:from' element={<><SendToken/></>}/>

    <Route path='/kebijakan_privasi' element={<><Header/><KebijakanPrivasi/><Footer/></>}/>
    <Route path='/privacy_policy' element={<><Header/><KebijakanPrivasi/><Footer/></>}/>
    <Route path='/syarat_ketentuan' element={<><Header/><SyaratKetentuan/><Footer/></>}/>
    <Route path='/terms_conditions' element={<><Header/><SyaratKetentuan/><Footer/></>}/>
    <Route path='/logout' element={<><Logout/></>}/>
    <Route path='*' element={<NotFound/>} />
    
    {/* <Route path='/chat' element={<><FreeChat/></>}/> */}

</Routes>
)
}
export default Router;