import { appkey, appsetup, restapilink, baseurl, yearnow, baseurlassets, basecolor1, basecolorred } from './Autoload'; 
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import SubHeader from './SubHeader';
import ReactPaginate from 'react-paginate';
const $ = require("jquery");   
const Swal = require('sweetalert2');
const nl2br = require('react-nl2br');

function ucwords (str) {
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
      return $1.toUpperCase();
  });
} 
function strtolower (str) {
  return (str+'').toLowerCase();
} 
function ucfirst (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function ArtikelDetail(props) {
  const {uri} = useParams();
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  const [listartikelriplabs, setlistartikelriplabs] = useState([]);
  const [jumlahartikelriplabs, setjumlahartikelriplabs] = useState(0);
  const [loaddataartikeldetail, setloaddataartikeldetail] = useState(1);

  const [artikel_judul, setartikel_judul] = useState('');
  const [artikel_uri, setartikel_uri] = useState('');
  const [artikel_thumbnail, setartikel_thumbnail] = useState('');
  const [artikel_tanggal, setartikel_tanggal] = useState('');
  const [artikel_kategori, setartikel_kategori] = useState('');
  const [artikel_isi, setartikel_isi] = useState('');

  // getListArtikelRipLabs
  const getListArtikelRipLabs = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response3 = await axios.post(restapilink+"api/data/listartikelriplabs", {
        key:appkey,
        keyword:'',
        kategori:'',
        uri:'',
        uri2:uri
      }); 
      setlistartikelriplabs(response3.data.data);
      setjumlahartikelriplabs(response3.data.jumlah);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {console.log(e.message);}
  }
  const getArtikelDetailRipLabs = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response3 = await axios.post(restapilink+"api/data/listartikelriplabs", {
        key:appkey,
        keyword:'',
        kategori:'',
        uri:uri,
        uri2:''
      });
      setloaddataartikeldetail(response3.data.jumlah);
      setartikel_judul(response3.data.data.artikel_judul);
      setartikel_uri(response3.data.data.artikel_uri);
      setartikel_thumbnail(response3.data.data.artikel_thumbnail);
      setartikel_tanggal(response3.data.data.artikel_tanggal);
      setartikel_kategori(response3.data.data.artikel_kategori);
      setartikel_isi(response3.data.data.artikel_isi);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {console.log(e.message);}
  }
  
  useEffect(() => {
    getListArtikelRipLabs();
    getArtikelDetailRipLabs();
  }, [ jumlahartikelriplabs, uri, loaddataartikeldetail ]);

  if(loaddataartikeldetail > 0 && artikel_judul!=="") {
    var webtitle1 = artikel_judul + " | RipLabs Indonesia";
  }
  else {
    var webtitle1 = "Artikel | RipLabs Indonesia";
  }
  var linkartikel_thumbnail = baseurlassets+'images/artikel/thumbnail/' + artikel_thumbnail;

  return(
    <>
    <title>{webtitle1}</title>
    <meta name="title" content={webtitle1} />
    <meta itemProp="name" content={webtitle1} />
    <meta property="og:title" content={webtitle1} />
    <meta name="twitter:title" content={webtitle1} />
    <div className='bodypage pageartikeldetail animated fadeIn'>
      <br/>
      <br/>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-lg-8 mb-5'>
            {
              loaddataartikeldetail > 0 && (
                <>
                  {
                    artikel_judul!=="" && (
                      <>
                        <h1 className='artikel_judul mb-3'>{artikel_judul}</h1>
                        <div className='color2'>
                          {artikel_tanggal} | {artikel_kategori}
                        </div>
                        <br/>
                        <img src={linkartikel_thumbnail} className="artikel_thumbnail borderradius12" /> 
                        <br/>
                        <br/>
                        {nl2br(artikel_isi)}
                        <br/>
                        <br/>
                        <NavLink to="/artikel/" className="btn btn-primary"><i className='fa fa-angle-left'></i> Kembali</NavLink>
                      </>
                    )
                  }
                </>
              )
            }
            
            {
              loaddataartikeldetail <= 0 && (
                <>
                  <div className='alert alert-primary text-center d-block w-100'>Artikel tidak ditemukan, silahkan kunjungi Artikel lainnya</div>
                </>
              )
            }
          </div>
          <div className='col-lg-3 listartikelterbaru'>
            <h4 className='font-semibold mb-4'>Artikel Terbaru</h4>
            {
              jumlahartikelriplabs > 0 && (
                <>
                  {
                    Array.isArray(listartikelriplabs) ?
                    listartikelriplabs.map((data, i) => {
                      let listartikelriplabsimg = data.artikel_thumbnail ? baseurlassets+'images/artikel/thumbnail/' + data.artikel_thumbnail : '';
                      let artikelpreview = "/artikel/"+data.artikel_uri+"/";
                      return (
                        <div>
                          <NavLink to={artikelpreview}>{data.artikel_judul}</NavLink>
                          <p className='mb-0'>{data.artikel_judul.substr(0,150)}...</p>
                        </div>
                      )
                    })
                  : ""
                  }
                </>
              )
            }
            {
              jumlahartikelriplabs <= 0 && (
                <center>Belum ada Artikel</center>
              )
            }
          </div>
        </div>
      </div>
      <br/>
      <br/>
    </div>
    </>
  )
}

export default ArtikelDetail;