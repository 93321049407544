import { baseurl } from './Autoload'; 
import React from 'react';
import '../styles/NotFound.css'
import { NavLink } from 'react-router-dom';
function NotFound(props) {
    return(
      <div id="notfound">
      <meta name="title" content="404 Not Found | RipLabs Indonesia" />
      <title>404 Not Found | RipLabs Indonesia</title>
        <div className="notfound">
          <div className="notfound-404">
            <h1>4<span></span>4</h1>
          </div>
          <h2>Oops! Halaman tidak ditemukan ^_^</h2>
          <p>Maaf, sepertinya kamu salah masuk ke sebuah halaman website tertentu, ayo saya antar ke halaman awal Web RipLabs, klik tombol kembali di bawah ini ya ^_^</p>
          <NavLink to="/">Kembali</NavLink>
        </div>
      </div>
    )
}
export default NotFound;