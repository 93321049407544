import { appkey, appsetup, restapilink, baseurl, yearnow, baseurlassets, basecolor1, basecolorred } from './Autoload'; 
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import SubHeader from './SubHeader';
import ReactPaginate from 'react-paginate';
const $ = require("jquery");   
const Swal = require('sweetalert2');

function rupiah(angka) {  
  var bilangan = angka;         
  var	number_string = bilangan.toString(),  
      sisa 	= number_string.length % 3,
      rupiah 	= number_string.substr(0, sisa),
      ribuan 	= number_string.substr(sisa).match(/\d{3}/g);
  if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
  } 
  return rupiah;
}
function ucwords (str) {
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
      return $1.toUpperCase();
  });
} 
function strtolower (str) {
  return (str+'').toLowerCase();
} 
function ucfirst (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function Artikel(props) {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  // const [ceksesilogin, setceksesilogin] = useState(0);
  const [listartikelriplabs, setlistartikelriplabs] = useState([]);
  const [jumlahartikelriplabs, setjumlahartikelriplabs] = useState(0);
  const [kategoriartikelriplabs, setkategoriartikelriplabs] = useState([]);
  const [jumlahkategoriartikelriplabs, setjumlahkategoriartikelriplabs] = useState(0);
  const [judulPencarian, setJudulPencarian] = useState('');

  const [keyword, setkeyword] = useState('');
  const [kategori, setkategori] = useState('All');
  const [uri, seturi] = useState('');
  // getListArtikelRipLabs
  const getListArtikelRipLabs = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response3 = await axios.post(restapilink+"api/data/listartikelriplabs", {
        key:appkey,
        keyword:keyword,
        kategori:kategori,
        uri:uri
      });
      setlistartikelriplabs(response3.data.data);
      setjumlahartikelriplabs(response3.data.jumlah);
      setJudulPencarian(response3.data.judul);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {console.log(e.message);}
  }
  const getKategoriArtikelRipLabs = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response3 = await axios.post(restapilink+"api/data/kategoriartikelriplabs", {
        key:appkey
      });
      setkategoriartikelriplabs(response3.data.data);
      setjumlahkategoriartikelriplabs(response3.data.jumlah);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {console.log(e.message);}
  }
  const searchHandler = async (e) => {
    setkeyword(e);
  }
  const kategoriHandler = async (e) => {
    setkategori(e);
  }
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 9;
  const handlePageClick = (e) => {
    const newOffset = (e.selected * itemsPerPage) % listartikelriplabs.length;
    setItemOffset(newOffset);
  }

  useEffect(() => {
    getListArtikelRipLabs();
    getKategoriArtikelRipLabs();

    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(listartikelriplabs.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(listartikelriplabs.length / itemsPerPage));

    var kategori2 = kategori.toLowerCase().replaceAll(" ","");
    $(".listkategorimenu").removeClass(" active ");
    $(".listkategorimenu"+kategori2).addClass(" active ");

  }, [ jumlahartikelriplabs, itemOffset, itemsPerPage, keyword, kategori ]);

    return(
      <>
      <title>Artikel RipLabs | RipLabs Indonesia</title>
      <meta name="title" content="Artikel RipLabs | RipLabs Indonesia" />
      <meta itemProp="name" content="Artikel RipLabs | RipLabs Indonesia" />
      <meta property="og:title" content="Artikel RipLabs | RipLabs Indonesia" />
      <meta name="twitter:title" content="Artikel RipLabs | RipLabs Indonesia" />
      <div className='bodypage pageartikel animated fadeIn'>
        <SubHeader title="Artikel" desc="Beberapa Artikel dari RipLabs yang bisa kamu baca." /> 
        <section className='riplabsapps riplabsartikel' style={{marginBottom:"60px"}}>
          <div className='container'>
            {/* Search Data */}
            <input type="search" className='boxdompetku1 mb-0' placeholder='Cari judul atau kategori artikel...' onChange={(e) => searchHandler(e.target.value)} />
            <br/>
            <div className='listkategoridata'>
            <span className="listkategorimenu listkategorimenuall" onClick={(e) => kategoriHandler(e.currentTarget.id)} id="All">All</span>
            {
              Array.isArray(kategoriartikelriplabs) ?
                kategoriartikelriplabs.map((data, i) => {
                  var classnamelist = "listkategorimenu listkategorimenu"+data.artikel_kategori.toLowerCase().replaceAll(" ","");
                  return (
                    <span key={i} className={classnamelist} id={data.artikel_kategori} onClick={(e) => kategoriHandler(e.currentTarget.id)}>{data.artikel_kategori}</span>
                  )
                })
              : ""
            }
            </div>
            <hr/>
            <h4 className='font-semibold'>{judulPencarian}</h4>
            {
              jumlahartikelriplabs > 0 && (
                <>
                  <div className="row justify-content-center rowcard1 rowcardnoborder card-groupp">
                    {
                      Array.isArray(currentItems) ?
                      currentItems.map((data, i) => {
                        let listartikelriplabsimg = data.artikel_thumbnail ? baseurlassets+'images/artikel/thumbnail/' + data.artikel_thumbnail : '';
                        let artikelpreview = "/artikel/"+data.artikel_uri+"/";
                        return (
                          <div key={i} className="card col-lg-4 p-3 mb-3">
                            <div className='image'>
                              <img src={listartikelriplabsimg} className="card-img-top" alt={listartikelriplabsimg} />
                            </div>
                            <div className="card-body"> 
                              <NavLink to={artikelpreview} className="textdecorationnone card-title mb-1">{data.artikel_judul}</NavLink>
                              <div className='color1 font-medium mb-3'>{data.artikel_kategori}</div>
                              <p className="card-text">{data.artikel_isi.substr(0,150)}...</p>
                            </div>
                            <div className="card-footer">
                              <NavLink to={artikelpreview} className="textdecorationnone color1 font-medium d-block mt-3">Baca Artikel</NavLink>
                            </div>
                          </div>
                        )
                      })
                      : ""
                    }
                  </div>
                  <br/>
                  <br/>
                  <center>
                    <ReactPaginate 
                      breakLabel="..."
                      nextLabel="Next"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={2}
                      marginPagesDisplayed={1}
                      pageCount={pageCount}
                      previousLabel="Previous"
                      renderOnZeroPageCount={null}
                      containerClassName="pagination m-auto d-inline-flex"
                      pageClassName="page-item"
                      breakClassName='page-item'
                      breakLinkClassName='page-link'
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      activeClassName="active"
                      activeLinkClassName="active"
                    />
                  </center>
                </>
              )
            }
            {
              jumlahartikelriplabs <= 0 && (
                <div className='alert alert-danger d-block text-center'>Data tidak ditemukan</div>
              )
            }
          </div>
        </section>
      </div>
      </>
    )
}

export default Artikel;