import { appkey, restapilink, baseurl, yearnow, baseurlassets, basecolor1, basecolorred, appsetup } from './Autoload'; 
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Header2 from './Header2';
import Header from './Header';
const $ = require("jquery");
const Swal = require('sweetalert2');

function CatatanKu(props) {
  const {idcatatankukategori} = useParams();
  const token = localStorage.getItem('token');
  const catatanlist = localStorage.getItem('catatanlist');
  const navigate = useNavigate();
  const [ceksesilogin2, setceksesilogin2] = useState(0);
  const [loadpagecatatanku, setloadpagecatatanku] = useState(0);
  const [idCatatanKuEdit, setidCatatanKuEdit] = useState('');
  const [idcatatankukategoriawal, setidcatatankukategoriawal] = useState('');
  const [idcatatankukategoriselected, setidcatatankukategoriselected] = useState('');
  const [judulcatatankukategoriselected, setjudulcatatankukategoriselected] = useState('');
  const [idcatatankukategoriedit, setidcatatankukategoriedit] = useState('');
  const [keyword, setKeyword] = useState('');
  const [formjudultanggal, setformjudultanggal] = useState('');
  const [formjudulkalimat, setformjudulkalimat] = useState('');
  const [formcatatan, setformcatatan] = useState('');
  const [kategoricatatan, setKategoriCatatan] = useState('all');
  const [kategoricatatanedit, setKategoriCatatanedit] = useState('1');
  const [kategoricatatannamaedit, setKategoriCatatanNamaedit] = useState('Catatan');
  const [formjudulkalimateditawal, setformjudulkalimateditawal] = useState('');
  const [formjudulkalimatedit, setformjudulkalimatedit] = useState('');
  const [formjudultanggaledit, setformjudultanggaledit] = useState('');
  const [formcatatanedit, setformcatatanedit] = useState('');
  const [judulKategori, setJudulKategori] = useState('');
  const [sortingData, setSortingData] = useState('DESC');
  const [datacatatan, setCatatanKu] = useState([]);
  const [jumlahdatacatatan, setJumlahCatatanKu] = useState(0);
  const [jumlahalldatacatatan, setJumlahAllCatatanKu] = useState(0);
  const [datakategoricatatan, setKategoriCatatanKu] = useState([]);
  const [judulKategoriAdd, setJudulKategoriAdd] = useState([]);
  const [limitdatacatatan, setlimitdatacatatan] = useState(20);
  const [ketersediaanKategori, setKetersediaanKategori] = useState('');

  const getCekLogin2 = async () => {
    await axios.post(restapilink+"api/auth/ceklogin", {
      key:appkey,
      token:token
    }).then((response) => {
      setceksesilogin2(response.data.ceklogin)
      // if(response.data.ceklogin == 0) {}
      // else {
      //   if(response.data.datapengguna.pengguna_popupintro1) {
      //     setPenggunaPopupIntro1(response.data.datapengguna.pengguna_popupintro1);
      //   }
      // }
    });
  }

  const cekKategoriCatatanAwal = async() => {
    try {
      let response = await axios.post(restapilink+"api/catatanku/cekkategoricatatanawal", {
        key:appkey,
        token: token
      });
      setidcatatankukategoriawal(response.data.id);
    } 
    catch(e) {
      // console.log(e);
    }
  }
  const cekKategoriCatatanSelected = async() => {
    try {
      let response = await axios.post(restapilink+"api/catatanku/cekkategoricatatanselected", {
        key:appkey,
        token: token,
        id: idcatatankukategori
      });
      setidcatatankukategoriselected(response.data.id);
      setjudulcatatankukategoriselected(response.data.title);
    } 
    catch(e) {
      // console.log(e);
    }
  }
  const cekKetersediaanKategori = async() => {
    try {
      let response = await axios.post(restapilink+"api/catatanku/cekketersediaankategori", {
        key:appkey,
        token: token,
        id: idcatatankukategori
      });
      setKetersediaanKategori(response.data.status);
      if(response.data.status=="tidakadadata") {}
      else if(response.data.status=="datadihapus") {
        // if(appsetup=="utama") {
        //   navigate('/catatanku/catatan/'+idcatatankukategoriawal+'/');
        // }
        // else {
        //   navigate('/catatan/'+idcatatankukategoriawal+'');
        // }
      }
      else {}
    }
    catch(e) {
      // console.log(e);
    }
  }
  const cekJudulKategori = async() => {
    try {
      let response = await axios.post(restapilink+"api/catatanku/cekjudulkategori", {
        key:appkey,
        token: token,
        id: idcatatankukategori
      });
      setJudulKategori(response.data.judulkategori);
    }
    catch(e) {
      // console.log(e);
    }
  }

  const getCatatanKu = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response = await axios.post(restapilink+"api/catatanku/catatanku", {
        key:appkey,
        token: token,
        id: '',
        kategori: idcatatankukategori,
        limit: limitdatacatatan,
        keyword: keyword,
        sorting: sortingData
      });
      setJumlahCatatanKu(response.data.jumlah);
      setJumlahAllCatatanKu(response.data.jumlahall);
      setCatatanKu(response.data.data);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {
      // console.log(e);
    }
  }
  
  const btnSortingData = async(e) => {
    if(sortingData=="DESC") {
      setSortingData('ASC');
      $(".btnSortingData .fa").attr('class','fa fa-sort-amount-down');
    }
    else {
      setSortingData('DESC');
      $(".btnSortingData .fa").attr('class','fa fa-sort-amount-up');
    }
    getCatatanKu();
  }

  const getKategoriCatatanKu = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response = await axios.post(restapilink+"api/catatanku/kategoricatatanku", {
        key:appkey,
        token: token
      });
      setKategoriCatatanKu(response.data.data);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {
      // console.log(e);
    }
  }

  const addCatatan = async (e) => {
    e.preventDefault(); 
    $("form#addCatatan #submit").attr('disabled','disabled'); 
    $(".loading_riplabs").css('display','block');
    var formkategori = $("form#addCatatan #formkategori").val();
    var formcatatan = $("form#addCatatan #formcatatan").val();
    var formjudulkalimat = $("form#addCatatan #formjudulkalimat").val();
    var formjudultanggal = $("form#addCatatan #formjudultanggal").val();
    if(formjudulkalimat!=="") {
      var formjuduladd = formjudulkalimat;
    }
    else {
      var formjuduladd = formjudultanggal;
    }
    try {
      await axios.post(restapilink+"api/catatanku/tambahcatatanku", {
        key:appkey,
        token: token,
        formkategori:formkategori,
        formjudul:formjuduladd,
        formcatatan:formcatatan
      });
      $(".loading_riplabs").css('display','none');
      $("form#addCatatan #formjudul").val('')
      $("form#addCatatan #formcatatan").val('')
      $(".modal .close").click();
      $("form#addCatatan .changeformjudultipetotanggal").css('display','inline-block');
      $("form#addCatatan .changeformjudultipetokalimat").css('display','none');
      $("form#addCatatan .formjudultanggal").val('');
      $("form#addCatatan .formjudultanggal").removeAttr('required');
      $("form#addCatatan .formjudulkalimat").val('');
      $("form#addCatatan .formjudulkalimat").attr('required','required');
      $("form#addCatatan .formjudultanggal").css('display','none');
      $("form#addCatatan .formjudulkalimat").css('display','block');
      $("form#addCatatan #submit").removeAttr('disabled');
      Swal.fire({
        title: 'Berhasil',
        html: 'Catatan baru sudah ditambahkan <i className="fa fa-smile-beam"></i>',
        icon: 'success',
        confirmButtonColor: basecolor1
      });
      if(idcatatankukategori=="sampah") {
        $(".menukategoriall").click();
        if(appsetup=="catatanku") {
          navigate('/catatan/all');
        }
        else {
          navigate('/catatanku/catatan/all/');
        }
      }
      if(formkategori!==idcatatankukategori) {
        navigate('/catatanku/catatan/'+formkategori+'/');
      }
      else {
        setTimeout(function() {
          getCatatanKu();
        }, 500);
      }
    }
    catch(e) {
      // console.log(e);
    }
  }

  const btnshowmodalnyatet = async (e) => {
    setKategoriCatatan(idcatatankukategoriawal)
    setidcatatankukategoriawal(idcatatankukategoriawal)
    $("#formjudul").val('');
    $("#formcatatan").val('');
  }

  const setidCatatanKuEdit2 = async (e) => {
    setidCatatanKuEdit(e);  
    $(".changeformjuduledittipetokalimat").click();  
    try {
      let response = await axios.post(restapilink+"api/catatanku/catatankuid", {
        key:appkey,
        token: token,
        id: e
      });
      var catatanku_judul = response.data.data.catatanku_judul.replaceAll("\\'","'");
      $("#formjudulkalimatedit").val(response.data.data.catatanku_judul.replaceAll("\\'","'"));
      $("#formcatatanedit").val(response.data.data.catatanku_isi.replaceAll("\\'","'"));
    }
    catch(e) {
      // console.log(e);
    }   
  }
    
  const updateCatatan = async (e) => {
    e.preventDefault();
    $(".loading_riplabs").css('display','block');
    $("form#updateCatatan #submit").attr('disabled','disabled'); 
    var formkategoriedit = $("form#updateCatatan #formkategoriedit").val();
    var formcatatanedit = $("form#updateCatatan #formcatatanedit").val();
    var formjudulkalimatedit = $("form#updateCatatan #formjudulkalimatedit").val();
    var formjudultanggaledit = $("form#updateCatatan #formjudultanggaledit").val();
    if(formjudulkalimatedit!=="") {
      var formjudulupdate = formjudulkalimatedit;
    }
    else {
      var formjudulupdate = formjudultanggaledit;
    }
    try {
      await axios.post(restapilink+"api/catatanku/perbaruicatatanku", {
        key:appkey,
        token: token, 
        id: idCatatanKuEdit,
        formkategori:formkategoriedit,
        formjudul:formjudulupdate,
        formcatatan:formcatatanedit
      });
      $(".loading_riplabs").css('display','none');
      $("#formkategoriedit").val('');
      $("#formjudulkalimatedit").val('');
      $("#formjudultanggaledit").val('');
      $("#formcatatanedit").val('');
      $(".modal .close").click();
      $("form#updateCatatan #submit").removeAttr('disabled');
      Swal.fire({
        title: 'Berhasil',
        html: 'Catatan sudah diperbarui <i className="fa fa-smile-beam"></i>',
        icon: 'success',
        confirmButtonColor: basecolor1
      });
      if(formkategoriedit!==idcatatankukategori) {
        navigate('/catatanku/catatan/'+formkategoriedit+'/');
      }
      else {
        setTimeout(function(){
          cekKetersediaanKategori();
          cekJudulKategori();
          getKategoriCatatanKu();
          getCatatanKu();
        }, 500); 
      }
    }
    catch(e) {}
  }

  const changeKategori = async (e) => {
    $(".offcanvas .btn-close").click(); 
    cekKetersediaanKategori();
    cekJudulKategori();
    getKategoriCatatanKu();
    getCatatanKu();
    if(e=="sampah") {
      $(".kosongkansampahcatatan").css('display','block');
    }
    else {
      $(".kosongkansampahcatatan").css('display','none');
    }
    $("html, body").animate({ scrollTop: 0 }, 50);
  }

  const changeIdHapusCatatanKu = async (e) => {
    Swal.fire({
      title: 'Konfirmasi',
      html: 'Apakah kamu yakin ingin menghapus Catatan ini?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: basecolor1,
      cancelButtonColor: basecolorred,
      confirmButtonText: 'Ya, Lanjutkan',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.value) {
        $(".loading_riplabs").css('display','block');
        try {
          $(".loading_riplabs").css('display','none');
          axios.post(restapilink+"api/catatanku/hapuscatatanku", {
            key:appkey,
            token: token, 
            id:e
          });
          $(".modal .close").click();
          Swal.fire({
            title: 'Berhasil',
            html: 'Catatan sudah dihapus <i className="fa fa-smile-beam"></i>',
            icon: 'success',
            confirmButtonColor: basecolor1
          });
          setTimeout(function() {
            getCatatanKu(); 
          }, 500); 
        }
        catch(e) {
          // console.log(e);
        }
      }
    });
  }

  const cariCatatan = async (e) => {
    e.preventDefault();
    setKeyword(e.target.value);  
  }

  const KosongkanSampah = async (e) => {
    Swal.fire({
      title: 'Konfirmasi',
      html: 'Apakah kamu yakin ingin mengosongkan Tong Sampah Catatan?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: basecolor1,
      cancelButtonColor: basecolorred,
      confirmButtonText: 'Ya, Lanjutkan',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.value) {
        $(".loading_riplabs").css('display','block');
        try {
          axios.post(restapilink+"api/catatanku/hapusallcatatanku", {
            key:appkey,
            token: token
          });
          $(".loading_riplabs").css('display','none');
          Swal.fire({
            title: 'Berhasil',
            html: 'Tong Sampah sudah dikosongkan <i className="fa fa-smile-beam"></i>',
            icon: 'success',
            confirmButtonColor: basecolor1
          });
          setTimeout(function(){
            getCatatanKu();
          }, 500);
        }
        catch(e) {
          // console.log(e);
        }
      }
    });
  }

  const loadMoreData = async (e) => { 
    var newlimitdatacatatan = limitdatacatatan + 20; 
    setlimitdatacatatan(newlimitdatacatatan);
    getCatatanKu();
  }

  const tambahKategoriHandler = async (e) => {
    e.preventDefault();
    $("form#tambahKategoriHandler #submit").attr('disabled','disabled'); 
    $(".loading_riplabs").css('display','block');
    const formData = new FormData();
    formData.append('key',appkey);
    formData.append('token',token);
    formData.append('judulkategori',judulKategoriAdd);
    await axios.post(restapilink+"api/catatanku/tambahkategoricatatanku", formData)
    .then((response) => {
      $(".loading_riplabs").css('display','none');
      $("form#tambahKategoriHandler #submit").removeAttr('disabled');
      if(response.data.status=="error") {
        Swal.fire({
          title: 'Oops...',
          html: response.data.message + ' <i class="fa fa-smile-beam"></i>',
          icon: 'error',
          confirmButtonColor: basecolor1
        });
        return false;
      }
      else {
        $(".judulkategori").val('');
        setJudulKategoriAdd('');
        setTimeout(function(){
          setKategoriCatatan(response.data.id_catatanku_kategori)
        }, 500);
        setTimeout(function(){
          getKategoriCatatanKu();
          getCatatanKu();
          Swal.fire({
            title: 'Berhasil',
            html: 'Kategori Catatan baru sudah ditambahkan <i className="fa fa-smile-beam"></i>',
            icon: 'success',
            confirmButtonColor: basecolor1
          });
        }, 1000);
      }
    });
  }

  const tambahKategoriHandler2 = async (e) => {
    if(judulKategoriAdd=="") {
      Swal.fire({
        title: 'Oops...',
        html: 'Jangan kosongkan Judul Kategorinya ya <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      }); 
      return false;
    }
    else {
      $(".loading_riplabs").css('display','block');
      try {
        let response = await axios.post(restapilink+"api/catatanku/tambahkategoricatatanku", {
          key:appkey,
          token: token, 
          judulkategori:judulKategoriAdd
        });
        $(".loading_riplabs").css('display','none');
        if(response.data.status=="error") {
          Swal.fire({
            title: 'Oops...',
            html: response.data.message + ' <i class="fa fa-smile-beam"></i>',
            icon: 'error',
            confirmButtonColor: basecolor1
          });
          return false;
        }
        else {
          $(".judulkategori").val('');
          setJudulKategoriAdd('');
          $(".boxtambahkategorisimple .body").slideUp();
          setTimeout(function() {
            setKategoriCatatan(response.data.id_catatanku_kategori)
          }, 500);
          setTimeout(function(){
            getKategoriCatatanKu();
            getCatatanKu();
            Swal.fire({
              title: 'Berhasil',
              html: 'Kategori Catatan baru sudah ditambahkan <i className="fa fa-smile-beam"></i>',
              icon: 'success',
              confirmButtonColor: basecolor1
            });
          }, 1000);
        }
      }
      catch(e) {
        // console.log(e);
      }
    }
  }

  const perbaruiKategori = async (e) => {
    var formjudulkategorithis = $(".judulkategoriedit"+e).val();
    if(formjudulkategorithis.trim()=="") {
      Swal.fire({
        title: 'Oops...',
        html: 'Jangan kosongkan Judul Kategorinya ya <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      }); 
      return false;
    }
    else {
      $(".loading_riplabs").css('display','block');
      $("#perbaruikategori"+e+" button").attr('disabled','disabled'); 
      try {
        let response = await axios.post(restapilink+"api/catatanku/perbaruikategoricatatanku", {
          key:appkey,
          token: token,
          id:e,
          judulkategori:formjudulkategorithis
        });
        if(response.data.status=="error") {
          $("#perbaruikategori"+e+" button").removeAttr('disabled');
          $(".loading_riplabs").css('display','none');
          Swal.fire({
            title: 'Oops...',
            html: response.data.message + ' <i class="fa fa-smile-beam"></i>',
            icon: 'error',
            confirmButtonColor: basecolor1
          });
          return false;
        }
        else {
          $("#perbaruikategori"+e+" button").removeAttr('disabled');
          $(".loading_riplabs").css('display','none');
          $(".judulkategoriedit").val('');
          $(".judulkategoriedit"+e).attr('placeholder',formjudulkategorithis);
          $(".menukategori"+e).html(formjudulkategorithis);
          Swal.fire({
            title: 'Berhasil',
            html: 'Kategori Catatan sudah diperbarui <i className="fa fa-smile-beam"></i>',
            icon: 'success',
            confirmButtonColor: basecolor1
          });
          setTimeout(function(){
            cekKetersediaanKategori();
            cekJudulKategori();
            getKategoriCatatanKu();
            getCatatanKu();
          }, 500);
        }
      }
      catch(e) {
        // console.log(e);
      }
    }
  }

  const hapusKategori = async (e) => {
    Swal.fire({
      title: 'Konfirmasi',
      html: 'Apakah kamu yakin ingin menghapus Kategori Catatan ini?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: basecolor1,
      cancelButtonColor: basecolorred,
      confirmButtonText: 'Ya, Lanjutkan',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.value) {
        $(".loading_riplabs").css('display','block');
        try {
          axios.post(restapilink+"api/catatanku/hapuskategoricatatanku", {
            key:appkey,
            token: token,
            id:e
          });
          $(".loading_riplabs").css('display','none');
          Swal.fire({
            title: 'Berhasil',
            html: 'Kategori Catatan sudah dihapus <i className="fa fa-smile-beam"></i>',
            icon: 'success',
            confirmButtonColor: basecolor1
          });
          setTimeout(function(){
            if(e==idcatatankukategori) {
              if(appsetup=="catatanku") {
                navigate('/catatan/all');
              }
              else {
                navigate('/catatanku/catatan/all/');
              }
            }
            cekKetersediaanKategori();
            cekJudulKategori();
            getKategoriCatatanKu();
            getCatatanKu();
          }, 500);
        }
        catch(e) {
          // console.log(e);
        }
      }
    });
  }

  const showboxtambahkategorisimple = async (e) => {
    $(".boxtambahkategorisimple .body").slideToggle();
  }
  
  const directlogin = async (e) => {
    // localStorage.setItem('directlogin','/catatanku/catatan/all/');
    if(appsetup=="utama") {
      localStorage.setItem('directlogin',baseurl+'catatanku/catatan/all/');
    }
    else {
      localStorage.setItem('directlogin',baseurl+'catatan/all');
    }
    navigate("/login/");
  }

  useEffect(() => {
    getCekLogin2();
    if(ceksesilogin2 > 0) {
      cekKategoriCatatanAwal();
      cekKategoriCatatanSelected();
      setTimeout(function(){
        if(!idcatatankukategori) {
          if(appsetup=="catatanku") {
            // navigate('/catatan/'+idcatatankukategoriawal+'');
            document.location.href='/catatan/all/';
            // setTimeout(function(){
            //   window.location.reload();
            // }, 1000);
          }
          else {
            // navigate('/catatanku/catatan/'+idcatatankukategoriawal+'/');
            document.location.href='/catatanku/catatan/all/';
            // setTimeout(function(){
            //   window.location.reload();
            // }, 1000);
          }
        }
      }, 500);
      // getcatatankukategoriawal();
      setTimeout(function(){
        cekKetersediaanKategori();
        cekJudulKategori();
        getKategoriCatatanKu();
        getCatatanKu();
        // setidcatatankukategoriawal(idcatatankukategori);
      }, 500);
      // if(kategoricatatan=="sampah") {
      if(idcatatankukategori=="sampah") {
        $(".kosongkansampahcatatan").css('display','block');
      }
      else {
        $(".kosongkansampahcatatan").css('display','none');
      }
    }

    setTimeout(function(){
      setloadpagecatatanku(1);
    }, 1000);

   
  }, [kategoricatatan, keyword, jumlahdatacatatan, jumlahalldatacatatan, limitdatacatatan, idcatatankukategori, idCatatanKuEdit, idcatatankukategoriawal, kategoricatatanedit, kategoricatatannamaedit, loadpagecatatanku, sortingData ]); 
  
  const closemenu = (e) => {
    $(".offcanvas .btn-close").click(); 
  }

  $(".changelayoutlistcatatangrid").click(function(){
    $(".changelayoutlistcatatangrid").css('display','none');
    $(".changelayoutlistcatatanlist").css('display','flex');
    $(".catatan-list").addClass(" catatan-list-grid "); 
    $(".catatan-list").removeClass(" catatan-list-list "); 
    localStorage.setItem('catatanlist','catatan-list-grid');
  })
  $(".changelayoutlistcatatanlist").click(function(){
    $(".changelayoutlistcatatangrid").css('display','flex');
    $(".changelayoutlistcatatanlist").css('display','none');
    $(".catatan-list").removeClass(" catatan-list-grid "); 
    $(".catatan-list").addClass(" catatan-list-list "); 
    localStorage.setItem('catatanlist','catatan-list-list');
  })


  $(".changeformjudultipetokalimat").click(function(){
    $(".changeformjudultipetotanggal").css('display','inline-block');
    $(".changeformjudultipetokalimat").css('display','none');
    $(".formjudulkalimat").val('');
    $(".formjudultanggal").val('');
    $(".formjudulkalimat").attr('required','required');
    $(".formjudultanggal").removeAttr('required');
    $(".formjudulkalimat").css('display','block');
    $(".formjudultanggal").css('display','none');
    $(".formjudulkalimat").focus();
    // setformjudulkalimat();
    // setformjudultanggal('');
  });
  $(".changeformjudultipetotanggal").click(function(){
    $(".changeformjudultipetotanggal").css('display','none');
    $(".changeformjudultipetokalimat").css('display','inline-block');
    $(".formjudulkalimat").val('');
    $(".formjudultanggal").val('');
    $(".formjudulkalimat").removeAttr('required');
    $(".formjudultanggal").attr('required','required');
    $(".formjudulkalimat").css('display','none');
    $(".formjudultanggal").css('display','block');
    // $(".formjudultanggal").focus();
    // setformjudulkalimat('');
    // setformjudultanggal('');
  });

  
  $(".changeformjuduledittipetotanggal").click(function(){
    $(".changeformjuduledittipetokalimat").css('display','inline-block');
    $(".changeformjuduledittipetotanggal").css('display','none');
    $(".formjudulkalimatedit").val('');
    $(".formjudultanggaledit").val('');
    $(".formjudulkalimatedit").removeAttr('required');
    $(".formjudultanggaledit").attr('required','required');
    $(".formjudulkalimatedit").css('display','none');
    $(".formjudultanggaledit").css('display','block');
    $(".formjudultanggaledit").focus();
    // setformjudulkalimatedit('');
    // setformjudultanggaledit('');
  });
  $(".changeformjuduledittipetokalimat").click(function(){
    $(".changeformjuduledittipetokalimat").css('display','none');
    $(".changeformjuduledittipetotanggal").css('display','inline-block');
    $(".formjudulkalimatedit").val(formjudulkalimateditawal);
    $(".formjudultanggaledit").val('');
    $(".formjudulkalimatedit").attr('required','required');
    $(".formjudultanggaledit").removeAttr('required');
    $(".formjudulkalimatedit").css('display','block');
    $(".formjudultanggaledit").css('display','none');
    // $(".formjudulkalimatedit").focus();
    // setformjudulkalimatedit(formjudulkalimateditawal);
    // setformjudultanggaledit('');
  });

  $('.formjudultanggal').datepicker({
    'format': 'DD, dd MM yyyy',
    'autoclose': true
  });
  $('.formjudultanggaledit').datepicker({
    'format': 'DD, dd MM yyyy',
    'autoclose': true
  });

  
  $("#nyatet .datepicker-days, #nyatet .formjudultanggal, #nyatet").click(function(){
    setTimeout(function() {
      var e = $(".formjudultanggal").val();
      if(e.trim()!=="") {
        let pecahdate = e.split(" ");
        if(pecahdate[0]=="" || pecahdate[1]=="" || pecahdate[2]=="" || pecahdate[3]=="") {
          // setformjudultanggal('');setformjudulkalimat('');
        }
        else {
          var dayname0 = pecahdate[0];
          var dayname = "";
          var monthname = "";
          var monthname0 = pecahdate[2];
          // konversi hari
          switch (dayname0) {
            case "Sunday,": dayname = "Minggu,"; break;
            case "Minggu,": dayname = "Minggu,"; break;
            case "Monday,": dayname = "Senin,"; break;
            case "Senin,": dayname = "Senin,"; break;
            case "Tuesday,": dayname = "Selasa,"; break;
            case "Selasa,": dayname = "Selasa,"; break;
            case "Wednesday,": dayname = "Rabu,"; break;
            case "Rabu,": dayname = "Rabu,"; break;
            case "Thursday,": dayname = "Kamis,"; break;
            case "Kamis,": dayname = "Kamis,"; break;
            case "Friday,": dayname = "Jum'at,"; break;
            case "Jum'at,": dayname = "Jum'at,"; break;
            case "Saturday,": dayname = "Sabtu,"; break;
            case "Sabtu,": dayname = "Sabtu,"; break;
            default: dayname = "";
            // setformjudultanggal('');setformjudulkalimat(''); 
            break;
          }
          // konversi bulan
          switch (monthname0) {
            case "January": monthname = "Januari"; break;
            case "Januari": monthname = "Januari"; break;
            case "February": monthname = "Februari"; break;
            case "Februari": monthname = "Februari"; break;
            case "March": monthname = "Maret"; break;
            case "Maret": monthname = "Maret"; break;
            case "April": monthname = "April"; break;
            case "April": monthname = "April"; break;
            case "May": monthname = "Mei"; break;
            case "Mei": monthname = "Mei"; break;
            case "June": monthname = "Juni"; break;
            case "Juni": monthname = "Juni"; break;
            case "July": monthname = "Juli"; break;
            case "Juli": monthname = "Juli"; break;
            case "August": monthname = "Agustus"; break;
            case "Agustus": monthname = "Agustus"; break;
            case "September": monthname = "September"; break;
            case "September": monthname = "September"; break;
            case "October": monthname = "Oktober"; break;
            case "Oktober": monthname = "Oktober"; break;
            case "November": monthname = "November"; break;
            case "November": monthname = "November"; break;
            case "December": monthname = "Desember"; break;
            case "Desember": monthname = "Desember"; break;
            default: monthname = "";
            // setformjudultanggal('');setformjudulkalimat(''); 
            break;
          }
          var newdate = dayname + ' ' + pecahdate[1] + ' ' + monthname + ' ' + pecahdate[3];
          $(".formjudultanggal").val(newdate);
          // setformjudultanggal(newdate);
        }
      }
    }, 500);
  })

  $("#modaleditcatatanku .datepicker-days, #modaleditcatatanku .formjudultanggaledit, #modaleditcatatanku").click(function(){
    setTimeout(function() {
      var e = $(".formjudultanggaledit").val();
      if(e.trim()!=="") {
        let pecahdate = e.split(" ");
        if(pecahdate[0]=="" || pecahdate[1]=="" || pecahdate[2]=="" || pecahdate[3]=="") {
          // setformjudultanggaledit('');setformjudulkalimatedit('');
        }
        else {
          var dayname0 = pecahdate[0];
          var dayname = "";
          var monthname = "";
          var monthname0 = pecahdate[2];
          // konversi hari
          switch (dayname0) {
            case "Sunday,": dayname = "Minggu,"; break;
            case "Minggu,": dayname = "Minggu,"; break;
            case "Monday,": dayname = "Senin,"; break;
            case "Senin,": dayname = "Senin,"; break;
            case "Tuesday,": dayname = "Selasa,"; break;
            case "Selasa,": dayname = "Selasa,"; break;
            case "Wednesday,": dayname = "Rabu,"; break;
            case "Rabu,": dayname = "Rabu,"; break;
            case "Thursday,": dayname = "Kamis,"; break;
            case "Kamis,": dayname = "Kamis,"; break;
            case "Friday,": dayname = "Jum'at,"; break;
            case "Jum'at,": dayname = "Jum'at,"; break;
            case "Saturday,": dayname = "Sabtu,"; break;
            case "Sabtu,": dayname = "Sabtu,"; break;
            default: dayname = "";
            // setformjudultanggaledit('');setformjudulkalimatedit(''); 
            break;
          }
          // konversi bulan
          switch (monthname0) {
            case "January": monthname = "Januari"; break;
            case "Januari": monthname = "Januari"; break;
            case "February": monthname = "Februari"; break;
            case "Februari": monthname = "Februari"; break;
            case "March": monthname = "Maret"; break;
            case "Maret": monthname = "Maret"; break;
            case "April": monthname = "April"; break;
            case "April": monthname = "April"; break;
            case "May": monthname = "Mei"; break;
            case "Mei": monthname = "Mei"; break;
            case "June": monthname = "Juni"; break;
            case "Juni": monthname = "Juni"; break;
            case "July": monthname = "Juli"; break;
            case "Juli": monthname = "Juli"; break;
            case "August": monthname = "Agustus"; break;
            case "Agustus": monthname = "Agustus"; break;
            case "September": monthname = "September"; break;
            case "September": monthname = "September"; break;
            case "October": monthname = "Oktober"; break;
            case "Oktober": monthname = "Oktober"; break;
            case "November": monthname = "November"; break;
            case "November": monthname = "November"; break;
            case "December": monthname = "Desember"; break;
            case "Desember": monthname = "Desember"; break;
            default: monthname = "";
            // setformjudultanggaledit('');setformjudulkalimatedit(''); 
            break;
          }
          var newdate = dayname + ' ' + pecahdate[1] + ' ' + monthname + ' ' + pecahdate[3];
          $(".formjudultanggaledit").val(newdate);
          // setformjudultanggaledit(newdate);
        }
      }
    }, 500);
  })

  $(".formtext").on('keyup paste change keydown', function(v) {
    if (v.keyCode==192 || v.keyCode==220 || v.keyCode==222 || v.keyCode==186 || v.keyCode==188 || v.keyCode==190 || v.keyCode==191 || v.keyCode==187) {
      Swal.fire({
        title: 'Perhatian',
        html: 'Diharapkan hanya boleh menggunakan huruf, angka dan tanpa simbol yang dilarang oleh sistem <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
      $(this).val('');
      // setJudulKategoriAdd('');
    }
    var valinput = $(this).val();
    var cekvalue1 = valinput.indexOf("!");var cekvalue2 = valinput.indexOf("@");var cekvalue3 = valinput.indexOf("#");var cekvalue4 = valinput.indexOf("$");var cekvalue5 = valinput.indexOf("%");var cekvalue6 = valinput.indexOf("^");var cekvalue7 = valinput.indexOf("&");var cekvalue8 = valinput.indexOf("*");var cekvalue10 = valinput.indexOf("'");var cekvalue11 = valinput.indexOf("`");var cekvalue12 = valinput.indexOf("~");var cekvalue17 = valinput.indexOf(",");var cekvalue18 = valinput.indexOf(".");var cekvalue19 = valinput.indexOf("<");var cekvalue20 = valinput.indexOf(">");var cekvalue21 = valinput.indexOf("/");var cekvalue22 = valinput.indexOf("?");var cekvalue23 = valinput.indexOf("|");var cekvalue24 = valinput.indexOf(";");var cekvalue25 = valinput.indexOf(":");var cekvalue26 = valinput.indexOf("(");var cekvalue27 = valinput.indexOf(")");
    if(cekvalue1>=0 || cekvalue2>=0 || cekvalue3>=0 || cekvalue4>=0 || cekvalue5>=0 || cekvalue6>=0 || cekvalue7>=0 || cekvalue8>=0 || cekvalue10>=0 || cekvalue11>=0 || cekvalue12>=0 || cekvalue17>=0 || cekvalue18>=0 || cekvalue19>=0 || cekvalue20>=0 || cekvalue21>=0 || cekvalue22>=0 || cekvalue23>=0 || cekvalue24>=0 || cekvalue25>=0 || cekvalue26>=0 || cekvalue27>=0) {
      Swal.fire({
        title: 'Perhatian',
        html: 'Diharapkan hanya boleh menggunakan huruf, angka dan tanpa simbol yang dilarang oleh sistem <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
      $(this).val('');
      // setJudulKategoriAdd('');
    }
  });

  $(".judulkategoriedit").on('mouseover',function(){
    var val = $(this).attr('placeholder');
    $(this).val(val);
  });

  if(!catatanlist) {
    var classcatatanlist = "catatan-list-grid catatan-list";
  }
  else {
    var classcatatanlist = catatanlist+ " catatan-list";
  }

  var imggifcatatanku = baseurlassets+"images/CatatanKu.gif";
  var logocatatanku = baseurlassets+"images/riplabs/Logo CatatanKu.png";

  return (
    <>
      <title>Aplikasi CatatanKu | RipLabs Indonesia</title>
      <meta name="title" content="Aplikasi CatatanKu | RipLabs Indonesia" />
      <meta itemProp="name" content="Aplikasi CatatanKu | RipLabs Indonesia" />
      <meta property="og:title" content="Aplikasi CatatanKu | RipLabs Indonesia" />
      <meta name="twitter:title" content="Aplikasi CatatanKu | RipLabs Indonesia" />
      {
        loadpagecatatanku > 0 && (
          <>
            {
              ceksesilogin2 <= 0 && (
                <>
                  <Header />
                  <div className="bodypage pagehome pagehome2 animated fadeIn">
                    <div className='hero'>
                      <div className='container text-center'>
                        <img src={logocatatanku} style={{width:"225px",marginBottom:"20px"}} />
                        <img className='imggif' src={imggifcatatanku} />
                        <p><span className='font-semibold' style={{color:"var(--basecolor1)"}}>Catatan</span><span className='font-semibold' style={{color:"var(--basecolor2)"}}>Ku</span> merupakan sebuah Aplikasi Catatan Online yang sangat ringan ini bisa kamu gunakan secara gratis di RipLabs.</p>
                        <span onClick={directlogin} className='btn_hero_1 cursorpointer'>Coba Sekarang&nbsp;<i className='fa fa-angle-right'></i></span>
                      </div>
                    </div>
                  </div>
                </>
              )
            }
            {
              ceksesilogin2 > 0 && (
                <>
                  <Header2 />
                  <div className='catatankupage bodypage '>
                    <div className='aplikasiku-wrapper'>
                      <div className='sidebar'>
                        <NavLink to="/catatanku/catatan/all/" onClick={(e) => changeKategori(e.currentTarget.id)} id='all' className='menukategori menukategoriall'>Semua Catatan</NavLink>
                        {
                          Array.isArray(datakategoricatatan) ?
                          datakategoricatatan.map((data, i) => {
                            var classname = "menukategori menukategori" + data.id_catatanku_kategori;
                            if(appsetup=="catatanku") {
                              var tonavlink1 = "/catatan/"+data.id_catatanku_kategori+"/";
                            }
                            else {
                              var tonavlink1 = "/catatanku/catatan/"+data.id_catatanku_kategori+"/";
                            }
                            return (
                              <NavLink key={i} to={tonavlink1} className={classname} onClick={(e) => changeKategori(e.currentTarget.id)} id={data.id_catatanku_kategori}>{data.catatanku_kategori_nama}</NavLink>
                            )
                          })
                          : ""
                        }
                        <NavLink to="/catatanku/catatan/sampah/"  className='menukategori menukategorisampah' onClick={(e) => changeKategori(e.currentTarget.id)} id='sampah'>Sampah</NavLink>
                        
                        <span className='menukategori' data-bs-toggle="modal" data-bs-target="#popupeditkategori">Edit Kategori</span>
                      </div>
                      <div className='body'>

                        {/* Nyatet */}
                        {/* LiveSearching */}
                        <div className='row'>
                          <div className='col-lg-2'>
                            <button type="button" className="button-blue-1 mb-3 d-block w-100 btnshowmodalnyatet" data-bs-toggle="modal" data-bs-target="#nyatet" onClick={btnshowmodalnyatet}>
                              <i className='fa fa-plus-square'></i> Nyatet
                            </button>
                          </div>
                          <div className='col-lg-10'>
                            <input type="search" className="form-control" placeholder='Cari judul atau isi catatan...' onChange={cariCatatan} value={keyword} />
                          </div>
                        </div>


                        {/* List Catatan */}


                        {
                          ketersediaanKategori=="tidakadadata" && idcatatankukategori!=="all" && idcatatankukategori!=="sampah" && (
                            <div className='d-block m-auto text-center'>
                              <hr/>
                              Kategori Catatan tidak ditemukan
                              <hr/>
                            </div>
                          )
                        }
                        {
                          ketersediaanKategori=="datadihapus" && idcatatankukategori!=="all" && idcatatankukategori!=="sampah" && (
                            <div className='d-block m-auto text-center'>
                              <hr/>
                              Kategori Catatan sudah dihapus
                              <hr/>
                            </div>
                          )
                        }
                        
                        {
                          ketersediaanKategori=="ready" && idcatatankukategori!=="" && judulKategori!=="" && (
                            <>
                              <hr/>
                              <div className='d-flex align-items-center justify-content-between'>
                                <h5 className='font-medium color2 m-0'>{judulKategori}  ({jumlahalldatacatatan})</h5>
                                <div className='d-flex align-items-center justify-content-between'>
                                <a href='javascript:void(0);' style={{color:"#222"}} className='btnSortingData' onClick={btnSortingData}>
                                  <i className="fa fa-sort-amount-up"></i>
                                </a>
                                &nbsp;&nbsp;&nbsp;
                                {
                                  !catatanlist && (
                                    <>
                                      <span className='changelayoutlistcatatan changelayoutlistcatatangrid' style={{display:"none"}}>
                                        <i className="fa fa-th"></i>
                                      </span>
                                      <span className='changelayoutlistcatatan changelayoutlistcatatanlist'>
                                        <i className="fa fa-th-list"></i>
                                      </span>
                                    </>
                                  )
                                }
                                {
                                  catatanlist && (
                                    <>
                                    {
                                      catatanlist=="catatan-list-list" && (
                                        <>
                                          <span className='changelayoutlistcatatan changelayoutlistcatatangrid'>
                                            <i className="fa fa-th"></i>
                                          </span>
                                          <span className='changelayoutlistcatatan changelayoutlistcatatanlist' style={{display:"none"}}>
                                            <i className="fa fa-th-list"></i>
                                          </span>
                                        </>
                                      )
                                    }
                                    {
                                      catatanlist !== "catatan-list-list" && (
                                        <>
                                          <span className='changelayoutlistcatatan changelayoutlistcatatangrid' style={{display:"none"}}>
                                            <i className="fa fa-th"></i>
                                          </span>
                                          <span className='changelayoutlistcatatan changelayoutlistcatatanlist'>
                                            <i className="fa fa-th-list"></i>
                                          </span>
                                        </>
                                      )
                                    }
                                    </>
                                  )
                                }
                                </div>
                                
                              </div>
                              <hr/>

                              {
                                idcatatankukategori=="sampah" && (
                                  <>
                                    <div className='d-block mb-3 text-center m-auto w-100'>
                                      Catatan akan hilang otomatis setelah 7 hari dihapus
                                    </div>
                                    <a href='javascript:void(0);' className='text-center mb-4 text-danger font-medium textdecorationnone kosongkansampahcatatan' onClick={KosongkanSampah}>Kosongkan Tong Sampah Catatan</a>

                                  </>
                                )
                              }
                              
                              {
                                jumlahdatacatatan > 0 && (
                                  <>
                                  <div className={classcatatanlist}>
                                    {
                                      Array.isArray(datacatatan) ?
                                      datacatatan.map((catatan, i) => {
                                        var catatanku_judul = catatan.catatanku_judul.replaceAll("\\'","'");
                                        var catatankuisi = catatan.catatanku_isi.substr(0,200).replaceAll("\\'","'");
                                        var classbtneditcatatan = 'title mb-0 pb-0 btneditcatatanku'+catatan.id_catatanku;
                                        return (
                                          <div key={i} className="content flow listcatatanku">
                                            <h3 className={classbtneditcatatan} data-bs-toggle="modal" data-bs-target="#modaleditcatatanku" onClick={(e) => setidCatatanKuEdit2(e.currentTarget.id)} id={catatan.id_catatanku}>{catatanku_judul}</h3>
                                            <span className='font-12 p-1 mt-2 badge bgcolor1' data-bs-toggle="modal" data-bs-target="#modaleditcatatanku" onClick={(e) => setidCatatanKuEdit2(e.currentTarget.id)} id={catatan.id_catatanku}>{catatan.catatanku_kategori_nama}</span>
                                            <span className="d-block mt-2 font-15" data-bs-toggle="modal" data-bs-target="#modaleditcatatanku" onClick={(e) => setidCatatanKuEdit2(e.currentTarget.id)} id={catatan.id_catatanku}>{catatankuisi}</span>
                                            {/* <div className='content-footer'> */}
                                              {/* <a href='javascript:void(0);' className='hapuscatatan' onClick={(e) => changeIdHapusCatatanKu(e.currentTarget.id)} id={catatan.id_catatanku} title="Hapus Catatan"><i className='fa fa-trash-alt'></i></a> */}
                                              {/* &nbsp;&nbsp;&nbsp; */}
                                              {/* <a href='javascript:void(0);' className='arsipkancatatan' title='Arsipkan Catatan'><i className='fa fa-archive'></i></a> */}
                                            {/* </div> */}
                                          </div>
                                        )
                                      })
                                      : ""
                                    }
                                  </div>
                                  {
                                    jumlahalldatacatatan > limitdatacatatan && (
                                      <>
                                        <br/>
                                        <br/>
                                        <center>
                                          <button className='button-blue-1 loadMoreData' onClick={loadMoreData}>Lihat Catatan Lainnya</button>
                                        </center>
                                      </>
                                    )
                                  }
                                  </>
                                )
                              }
                              {
                                jumlahdatacatatan <= 0 && (
                                  <div className='d-block m-auto text-center'>
                                    Belum ada Catatan 
                                  </div>
                                )
                              }

                            </>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  
                  <div className="modal fade" id="nyatet" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-95">
                      <div className="modal-content">
                        <form method='POST' onSubmit={addCatatan} id='addCatatan'>
                          <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Nyatet Yuk!</h1>
                            <button type="button" className="btn-close close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div className="modal-body">
                            <label className='mb-1' htmlFor="formkategori">Kategori</label>
                            <select required className='form-select mb-4' id='formkategori'>
                              {/* <option value={idcatatankukategoriselected}>{judulcatatankukategoriselected}</option> */}
                              {
                                Array.isArray(datakategoricatatan) ?
                                datakategoricatatan.map((data, i) => {
                                  return (
                                    <>
                                    {
                                      idcatatankukategoriselected==data.id_catatanku_kategori && (
                                        <option key={i} selected="selected" value={data.id_catatanku_kategori}>{data.catatanku_kategori_nama}</option>
                                      )
                                    }
                                    {
                                      idcatatankukategoriselected!==data.id_catatanku_kategori && (
                                        <option key={i} value={data.id_catatanku_kategori}>{data.catatanku_kategori_nama}</option>
                                      )
                                    }
                                    </>
                                  )
                                })
                                : ""
                              }
                            </select>
                            {/* </select> */}
                            {/* <label className='mb-1' htmlFor="formkategori">Kategori</label>
                            <select required className='form-select mb-1' id='formkategori' onChange={(e) => setKategoriCatatan(e.target.value)}>
                            {
                              Array.isArray(datakategoricatatan) ?
                              datakategoricatatan.map((data, i) => {
                                return (
                                  <option key={i} value={data.id_catatanku_kategori}>{data.catatanku_kategori_nama}</option>
                                )
                              })
                              : ""
                            }
                            </select>
                            <span style={{cursor:"pointer"}} className='color1 font-medium font-14' onClick={showboxtambahkategorisimple}><small>Tambah Kategori</small></span> */}
                            {/* <div className='boxtambahkategorisimple mb-3'>
                              <div className='body'>
                                <hr/>
                                <h5 className='font-medium mb-3'>Tambah Kategori</h5>
                                <div className='row'>
                                  <div className='col-lg-10'>
                                    <input minLength="1" maxLength="255" type="text" id='judulkategori' className="formtext form-control judulkategori" placeholder='.....' value={judulKategoriAdd} onChange={(e) => setJudulKategoriAdd(e.target.value)} />
                                  </div>
                                  <div className='col-lg-2'>
                                    <button type='button' className='d-block w-100 btn btn-primary' onClick={tambahKategoriHandler2}>Tambah</button>
                                  </div>
                                </div>
                                <hr/>
                              </div>
                            </div> */}
                            <label className='mb-1'>Judul</label>
                            <input minLength="1" maxLength="255" type="text" className="formjudulkalimat form-control mb-1" autoComplete="off" id="formjudulkalimat" placeholder="....." />
                            <input minLength="1" maxLength="255" type="text" className="formjudultanggal form-control mb-1" autoComplete="off" id="formjudultanggal" placeholder="....." style={{display:"none"}} />
                            <div className='d-block mt-1'></div>
                            <span style={{display:"inline-block",cursor:"pointer"}} className='changeformjudultipetotanggal color1 font-medium font-14'><small>Pilih Tanggal</small></span>
                            <span style={{display:"none",cursor:"pointer"}} className='changeformjudultipetokalimat color1 font-medium font-14'><small>Ketik Kalimat</small></span>
                            <div className='d-block mb-4'></div>
                            <label className='mb-1' htmlFor="formcatatan">Catatan</label>
                            <textarea required minLength="1" className="form-control" id="formcatatan" name="formcatatan" placeholder="....." rows='14' />
                          </div>
                          <div className="modal-footer">
                            <button type="submit" id='submit' className="btn btn-primary">Simpan</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="modal fade" id="modaleditcatatanku" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-95">
                      <div className="modal-content">
                        <form method='POST' onSubmit={updateCatatan} id='updateCatatan'>
                          <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">View/Edit Catatan</h1>
                            <button type="button" className="btn-close close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div className="modal-body">
                            <label className='mb-1' htmlFor="formkategoriedit">Kategori</label>
                            <select required className='form-select mb-4' id='formkategoriedit' name='formkategoriedit'>
                              {
                                Array.isArray(datakategoricatatan) ?
                                datakategoricatatan.map((data, i) => {
                                  return (
                                    <>
                                    {
                                      idcatatankukategoriselected==data.id_catatanku_kategori && (
                                        <option key={i} selected="selected" value={data.id_catatanku_kategori}>{data.catatanku_kategori_nama}</option>
                                      )
                                    }
                                    {
                                      idcatatankukategoriselected!==data.id_catatanku_kategori && (
                                        <option key={i} value={data.id_catatanku_kategori}>{data.catatanku_kategori_nama}</option>
                                      )
                                    }
                                    </>
                                  )
                                })
                                : ""
                              }
                            </select>
                            <label className='mb-1'>Judul</label>
                            {/* <input required minLength="1" maxLength="255" type="text" className="form-control mb-4" autoComplete="off" id="formjuduledit" name="formjuduledit" placeholder="....." value={formjuduledit} onChange={(e) => setformjuduledit(e.target.value)} /> */}

                            <input minLength="1" maxLength="255" type="text" className="formjudulkalimatedit form-control mb-1" autoComplete="off" id="formjudulkalimatedit" placeholder="....." />
                            <input minLength="1" maxLength="255" type="text" className="formjudultanggaledit form-control mb-1" autoComplete="off" id="formjudultanggaledit" placeholder="....." style={{display:"none"}} />
                            <div className='d-block mt-1'></div>
                            <span style={{display:"inline-block",cursor:"pointer"}} className='changeformjuduledittipetotanggal color1 font-medium font-14'><small>Pilih Tanggal</small></span>
                            <span style={{display:"none",cursor:"pointer"}} className='changeformjuduledittipetokalimat color1 font-medium font-14'><small>Ketik Kalimat</small></span>
                            <div className='d-block mb-4'></div>

                            <label className='mb-1' htmlFor="formcatatanedit">Catatan</label>
                            <textarea required minLength="1" className="form-control" id="formcatatanedit" name="formcatatanedit" placeholder="....." rows='14' />
                          </div>
                          <div className="modal-footer">
                            <button type="submit" id='submit' className="btn btn-success">Perbarui</button>
                            <button type="button" className="btn btn-danger" onClick={(e) => changeIdHapusCatatanKu(e.currentTarget.id)} id={idCatatanKuEdit}>Hapus</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="modal fade" id="popupeditkategori" tabindex="-1" aria-labelledby="popupeditkategoriLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-55">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1 className="modal-title fs-5" id="popupeditkategoriLabel"></h1>
                          <button type="button" className="btn-close close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          
                          <hr/>
                          <h5 className='font-medium'>Tambah Kategori</h5>
                          <hr/>
                          <form method='POST' onSubmit={tambahKategoriHandler} id='tambahKategoriHandler'>
                            {/* <label htmlFor='judulkategori' className='d-block mb-1'>Judul Kategori</label> */}
                            <div className='row'>
                              <div className='col-lg-10'>
                                <input required minLength="1" maxLength="255" type="text" id='judulkategori' className="formtext form-control judulkategori" placeholder='.....' value={judulKategoriAdd} onChange={(e) => setJudulKategoriAdd(e.target.value)} />
                              </div>
                              <div className='col-lg-2'>
                                <button type='submit' id='submit' className='btntambahkategori d-block w-100 btn btn-primary'>Tambah</button>
                              </div>
                            </div>
                          </form>

                          <hr/>

                          <h5 className='font-medium'>Edit/Hapus Kategori</h5>
                          <hr/>
                          <div className='row'>

                            {
                              Array.isArray(datakategoricatatan) ?
                              datakategoricatatan.map((data, i) => {
                                var inputclass = "formtext judulkategoriedit judulkategoriedit"+data.id_catatanku_kategori+" form-control";
                                var idnameperbaruikategori = "perbaruikategori"+data.id_catatanku_kategori;
                                return (
                                  <>
                                    {
                                      data.catatanku_kategori_nama == "Catatan" && (
                                        <>
                                          <div key={i} className='col-lg-12 mb-4'>
                                            <input disabled type="text" id='judulkategori' className="form-control " value={data.catatanku_kategori_nama} />
                                          </div>
                                        </>
                                      )
                                    }
                                    {
                                      data.catatanku_kategori_nama !== "Catatan" && (
                                        <>
                                          <div key={i} className='col-lg-8 mb-2'>
                                            <input type="text" id='judulkategori' minLength="1" maxLength="255" className={inputclass} placeholder={data.catatanku_kategori_nama} />
                                          </div>
                                          <div className='col-lg-2 col-6 mb-4' id={idnameperbaruikategori}>
                                            <button type='button' className='d-block w-100 btn btn-success' onClick={(e) => perbaruiKategori(e.currentTarget.id)} id={data.id_catatanku_kategori}>Perbarui</button>
                                          </div>
                                          <div className='col-lg-2 col-6 mb-4'>
                                            <button type='button' className='d-block w-100 btn btn-danger' onClick={(e) => hapusKategori(e.currentTarget.id)} id={data.id_catatanku_kategori}>Hapus</button>
                                          </div>
                                        </>
                                      )
                                    }
                                  </>
                                )
                              })
                              : ""
                            }
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="offcanvas offcanvas-end" tabindex="-1" id="box-kategori-apps" aria-labelledby="box-kategori-appsLabel">
                    <div className="offcanvas-header">
                      <h5 className="offcanvas-title" id="offcanvasExampleLabel">Kategori</h5>
                      <button type="button" className="btn-close close-menu-on-mobile" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                      <NavLink to="/catatanku/catatan/all/" onClick={(e) => changeKategori(e.currentTarget.id)} id='all' className='nav-link menukategori menukategoriall'>Semua Catatan</NavLink>
                      { 
                        Array.isArray(datakategoricatatan) ?
                        datakategoricatatan.map((data, i) => {
                          var classname = "nav-link menukategori menukategori" + data.id_catatanku_kategori;
                          if(appsetup=="catatanku") {
                            var tonavlink1 = "/catatan/"+data.id_catatanku_kategori+"/";
                          }
                          else {
                            var tonavlink1 = "/catatanku/catatan/"+data.id_catatanku_kategori+"/";
                          }
                          return (
                            <NavLink key={i} to={tonavlink1} className={classname} onClick={(e) => changeKategori(e.currentTarget.id)} id={data.id_catatanku_kategori}>{data.catatanku_kategori_nama}</NavLink>
                          )
                        })
                        : ""
                      }
                      <NavLink to="/catatanku/catatan/sampah/" className='nav-link menukategori menukategorisampah' onClick={(e) => changeKategori(e.currentTarget.id)} id='sampah'>Sampah</NavLink>
                      <span className='nav-link menukategori' data-bs-toggle="modal" data-bs-target="#popupeditkategori" onClick={closemenu}>Edit Kategori</span>
                    </div>
                  </div>

                </>
              )
            }
          </>
        )
      }
    </>
  )
}

export default CatatanKu;