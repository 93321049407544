import { appkey, restapilink, baseurl, yearnow, baseurlassets, basecolor1, basecolorred } from './Autoload';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
import SubHeader from './SubHeader';
import '../styles/WebRipLabs.css'; 
// import MenuNavbarDesktop from './MenuNavbarDesktop';
const $ = require("jquery");
const Swal = require('sweetalert2');


function Header(props) {
  $("html, body").animate({ scrollTop: 0 }, 50);
  // if(window.location.origin=="http://arifsiddikm.com") {
  //   window.location.href="https://arifsiddikm.com/";
  // }

  const token = localStorage.getItem('token');
  // const page = window.location.href; 
  const navigate = useNavigate();
  const[nama, setSessionNama] = useState('');
  const [ceksesilogin, setceksesilogin] = useState(0);

  const fetchData = async () => {
    await axios.post(restapilink+"api/auth/ceklogin", {
      key:appkey,
      token:token
    }).then((response) => {
      setceksesilogin(response.data.ceklogin)
      if(response.data.ceklogin == 0) {
        localStorage.removeItem('token');
        localStorage.removeItem('tmptoken');
        localStorage.removeItem('kategoricatatankudefault');
        localStorage.removeItem('catatanlist');
      }
      else {
        setSessionNama(response.data.namapanjangku);
      }
    });
  }

  

  const logoutHandler = (e) => {
    $(".offcanvas .btn-close").click(); 
    Swal.fire({
      title: 'Konfirmasi',
      html: 'Apakah kamu ingin keluar dari Aplikasi RipLabs?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: basecolor1,
      cancelButtonColor: basecolorred,
      confirmButtonText: 'Ya, Keluar',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.value) {
        $(".loading_riplabs").css('display','block');
        axios.post(restapilink+"api/auth/logout", {
          key:appkey,
          token:token
        });
        setTimeout(function(){
          localStorage.removeItem('token');
          localStorage.removeItem('kategoricatatankudefault');
          localStorage.removeItem('catatanlist');
          localStorage.removeItem('directlogin');
          $(".loading_riplabs").css('display','none');
          Swal.fire({
            title: 'Logout Berhasil',
            html: 'Sampai jumpa kembali '+nama+' <i className="fa fa-smile-beam"></i>',
            icon: 'success',
            confirmButtonColor: basecolor1,
            confirmButtonText: 'Oke, Lanjutkan',
            allowOutsideClick: false, 
            allowEscapeKey: false
          }).then((result) => {
            if (result.value) {
              navigate('/login'); 
            }
          });
        }, 1000);
        setTimeout(function() {
          $(".swal2-confirm").click();
          navigate('/login'); 
        }, 4000);
      }
    });
  }

  const closemenu = (e) => {
    $(".offcanvas .btn-close").click(); 
  }

  useEffect(() => {
    // getGeolocation();
    fetchData();

    // if(localStorage.getItem('token')) {
    //   navigate('/home');
    // }

    // setTimeout(function(){
    //   $(".riplabspartners").css('display','block');
    //   $(".footer").css('display','block');
    //   $(".footer-copyright").css('display','block');
    // }, 3000); 

  }, []);

  var logoriplabs = baseurlassets+"images/riplabs/Logo RipLabs.png";

 

  return(
    <>

      <div className="navbar-desktop">
        <div className='container'>
          <div className='left'>
            <NavLink to="/"><img src={logoriplabs} className='logo' /></NavLink>
          </div>
          <div className='right'>
            <NavLink className="nav-link" to="/">Beranda</NavLink>
            <NavLink className="nav-link" to="/aplikasi/">Aplikasi</NavLink>
            <NavLink className="nav-link" to="/jasa/">Jasa</NavLink>
            <NavLink className="nav-link" to="/produk/">Produk</NavLink>
            <NavLink className="nav-link" to="/portofolio/">Portofolio</NavLink>
            <NavLink className="nav-link" to="/artikel/">Artikel</NavLink>
            {
              token && (
                <>
                  <a href="javascript:void(0);" className="nav-link nav-logout" onClick={logoutHandler}>Logout</a>
                  <NavLink to="/dashboard/" href="javascript:void(0);" className="nav-link p-0 m-0"><i className='fa fa-user-circle fa-3x'></i></NavLink>
                </>
              )
            }
            {
              !token && (
                <>
                  <NavLink className="nav-link nav-masuk" to="/masuk/">Masuk</NavLink>
                  <NavLink className="nav-link nav-daftar" to="/daftar/">Daftar</NavLink>
                </>
              )
            }
          </div>
        </div>
      </div>

      <div className="navbar-mobile">
        <div className='container'>
          {
            ceksesilogin > 0 && (
              <NavLink to="/dashboard/" className="tomypage">
                <div className='image color2'>
                  <img />
                  <i className='fa fa-user-circle color2 fa-2x'></i>
                </div>
              </NavLink>
            )
          }
          {
            ceksesilogin <= 0 && (
              <>&nbsp;</>
            )
          }
          <div className='linkmenu' data-bs-toggle="offcanvas" href="#menu-on-mobile" role="button" aria-controls="menu-on-mobile">MENU</div>
        </div>
      </div>
      
      <div className="offcanvas offcanvas-end" tabindex="-1" id="menu-on-mobile" aria-labelledby="menu-on-mobileLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">Menu</h5>
          <button type="button" className="btn-close close-menu-on-mobile" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <NavLink className="nav-link" onClick={closemenu} to="/">Beranda</NavLink>
          <NavLink className="nav-link" onClick={closemenu} to="/aplikasi/">Aplikasi</NavLink>
          <NavLink className="nav-link" onClick={closemenu} to="/jasa/">Jasa</NavLink>
          <NavLink className="nav-link" onClick={closemenu} to="/produk/">Produk</NavLink>
          <NavLink className="nav-link" onClick={closemenu} to="/portofolio/">Portofolio</NavLink>
          <NavLink className="nav-link" onClick={closemenu} to="/artikel/">Artikel</NavLink>
          {
            ceksesilogin > 0 && (
              <>
                <NavLink className="nav-link" onClick={closemenu} to="/dashboard/">Dashboard</NavLink>
                <a href="javascript:void(0);" className='nav-link nav-logout' onClick={logoutHandler}>Logout</a>
              </>
            )
          }
          {
            ceksesilogin <= 0 && (
              <>
                <NavLink className="nav-link" onClick={closemenu} to="/masuk/">Masuk</NavLink>
                <NavLink className="nav-link" onClick={closemenu} to="/daftar/">Daftar</NavLink>
              </>
            )
          }
        </div>
      </div>


    </>
    )
}
export default Header;