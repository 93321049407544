import { appkey, restapilink, baseurl, yearnow, baseurlassets, basecolor1 } from './Autoload';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
// import SubHeader from './SubHeader';
// import MenuNavbarDesktop from './MenuNavbarDesktop';
const $ = require("jquery");
const Swal = require('sweetalert2');

function Account(props) {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [ceksesilogin, setceksesilogin] = useState(0);

  const [loadidsection, setloadidsection] = useState(0);
  const [namalengkapdefault, setNamaLengkapDefault] = useState('');
  const [namalengkap, setNamaLengkap] = useState('');
  const [alamatemail, setAlamatEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [katasandi, setKataSandi] = useState('');
  // getDataPengguna 
  const getDataPengguna = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response = await axios.post(restapilink+"api/auth/cekdatapengguna", {
        key:appkey,
        token: token
      });
      $(".loading_riplabs").css('display','none');
      if(response.data.ceklogin == 0) {
        localStorage.removeItem('token');
        navigate("/login/"); 
      }
      else {
        setNamaLengkapDefault(response.data.data.pengguna_nama);
        setNamaLengkap(response.data.data.pengguna_nama);
        setAlamatEmail(response.data.data.pengguna_email);
        setPhone(response.data.data.pengguna_phone);
        setloadidsection(1)
      }
    }
    catch(e) {console.log(e);setloadidsection(0)}
  }

  const editAkunHandler = async (e) => {
    e.preventDefault();
    $(".loading_riplabs").css('display','block');
    const formData = new FormData();
    formData.append('key',appkey);
    formData.append('token',token);
    formData.append('pengguna_nama',namalengkap);
    formData.append('pengguna_password',katasandi);
    formData.append('pengguna_phone',phone);
    await axios.post(restapilink+"api/auth/perbaruidataprofil", formData).then((response) => {
      $(".loading_riplabs").css('display','none');
      Swal.fire({
        title: 'Berhasil',
        html: 'Data Profil sudah diperbarui <i class="fa fa-smile-beam"></i>',
        icon: 'success',
        confirmButtonColor: basecolor1
      });
      setKataSandi(''); 
      $("#password").val('');
    });
  }

  useEffect(() => {
    getDataPengguna();
  }, [ ceksesilogin, loadidsection ]);

  $(".formnama").on('keyup paste change keydown', function(v) {
    if (v.keyCode==192 || v.keyCode==220 || v.keyCode==222 || v.keyCode==186 || v.keyCode==188 || v.keyCode==190 || v.keyCode==191 || v.keyCode==189 || v.keyCode==187) {
      Swal.fire({
        title: 'Perhatian',
        html: 'Diharapkan hanya boleh menggunakan huruf, angka dan tanpa simbol yang dilarang oleh sistem <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
      $(this).val(namalengkapdefault);
      setNamaLengkap(namalengkapdefault);
    }
    var valinput = $(this).val();
    var cekvalue1 = valinput.indexOf("!");var cekvalue2 = valinput.indexOf("@");var cekvalue3 = valinput.indexOf("#");var cekvalue4 = valinput.indexOf("$");var cekvalue5 = valinput.indexOf("%");var cekvalue6 = valinput.indexOf("^");var cekvalue7 = valinput.indexOf("&");var cekvalue8 = valinput.indexOf("*");var cekvalue10 = valinput.indexOf("'");var cekvalue11 = valinput.indexOf("`");var cekvalue12 = valinput.indexOf("~");var cekvalue17 = valinput.indexOf(",");var cekvalue18 = valinput.indexOf(".");var cekvalue19 = valinput.indexOf("<");var cekvalue20 = valinput.indexOf(">");var cekvalue21 = valinput.indexOf("/");var cekvalue22 = valinput.indexOf("?");var cekvalue23 = valinput.indexOf("|");var cekvalue24 = valinput.indexOf(";");var cekvalue25 = valinput.indexOf(":");var cekvalue26 = valinput.indexOf("(");var cekvalue27 = valinput.indexOf(")");
    if(cekvalue1>=0 || cekvalue2>=0 || cekvalue3>=0 || cekvalue4>=0 || cekvalue5>=0 || cekvalue6>=0 || cekvalue7>=0 || cekvalue8>=0 || cekvalue10>=0 || cekvalue11>=0 || cekvalue12>=0 || cekvalue17>=0 || cekvalue18>=0 || cekvalue19>=0 || cekvalue20>=0 || cekvalue21>=0 || cekvalue22>=0 || cekvalue23>=0 || cekvalue24>=0 || cekvalue25>=0 || cekvalue26>=0 || cekvalue27>=0) {
      Swal.fire({
        title: 'Perhatian',
        html: 'Diharapkan hanya boleh menggunakan huruf, angka dan tanpa simbol yang dilarang oleh sistem <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
      $(this).val(namalengkapdefault);
      setNamaLengkap(namalengkapdefault);
    }
  });

  return(
    <>
      <title>Akun Saya | RipLabs Indonesia</title>
      <div className='bodypage pagedashboard pageaccount'>
        <br/>
        <br/>
        <div className='container'>
          {
            loadidsection > 0 && (
              <>
                <h2>Akun Saya</h2> 
                <div className='boxpageaccount'>
                  <form method='POST' encType='multipart/form-data'>
                  <center>
                    <h4 className='font-semibold color2'>Foto Profil</h4>
                    <div className='image color2 my-3'>
                      <img />
                      <i className='fa fa-user-circle color2 fa-7x'></i>
                    </div>
                  </center>
                  </form>
                  <br/>
                  <form method='POST' onSubmit={editAkunHandler}>
                  <div className='row'>
                    <div className='col-lg-2 d-flex align-items-center'>
                      <label htmlFor='nama'>Nama Lengkap</label>
                    </div>
                    <div className='col-lg-10 pb-3'>
                      <input required minLength="1" maxLength="255" id='nama' type="text" className='form-control formnama' value={namalengkap} onChange={(e) => setNamaLengkap(e.target.value)} />
                    </div>
                    <div className='col-lg-2 d-flex align-items-center'>
                      <label htmlFor='email'>Alamat Email</label>
                    </div>
                    <div className='col-lg-10 pb-3'>
                      <input disabled id='email' type="text" className='form-control' value={alamatemail} />
                    </div>
                    <div className='col-lg-2 d-flex align-items-center'>
                      <label htmlFor='nohp'>No. Handphone</label>
                    </div>
                    <div className='col-lg-10 pb-3'>
                      <input id='nohp' type="number" className='form-control' value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                    <div className='col-lg-2 d-flex align-items-center'>
                      <label htmlFor='password'>Kata Sandi</label>
                    </div>
                    <div className='col-lg-10 pb-3'>
                      <input minLength="8" maxLength="255" id='password' type="text" className='form-control' onChange={(e) => setKataSandi(e.target.value)} autoComplete="off" />
                      <small className='d-block mt-1 colorred'>Isikan formulir ini jika ingin memperbarui Kata Sandi.</small>
                    </div>
                    <div className='col-lg-2 d-flex align-items-center'>
                      &nbsp;
                    </div>
                    <div className='col-lg-10'>
                      <button type='submit' className='button-blue-1'>Simpan</button>
                    </div>
                  </div>
                  </form>
                </div>
              </>
            )
          }
        </div>
      </div>
    </>
  )

}

export default Account;