import { appkey, appsetup, statusweb, restapilink, baseurl, yearnow, baseurlassets, basecolor1 } from './Autoload'; 
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
const $ = require("jquery");
function SendToken(props) {
    const { token, from } = useParams();
    const navigate = useNavigate();
    const directlogin = localStorage.getItem('directlogin');
    localStorage.setItem('token', token);
    // Cek Pengguna Token 
    const cekSendToken = async () => {
        $(".loading_riplabs").css('display','block');
        try {
          let response = await axios.post(restapilink+"api/auth/ceksendtoken", {
            key:appkey,
            token: token,
            from: from
          });
          var from20 = response.data.selanjutnya.replace(".","");
          if(from20=="") {var from2 = "utama";} 
          else {var from2 = from20;}
          if(response.data.statustoken=="lanjut") {
            document.location.href="https://"+response.data.selanjutnya+"riplabs.id/sendtoken/"+token+"/from/"+from2;
          }
          else {
            if(directlogin) {
              if(directlogin!=="") {
                // document.location.href= baseurl+directlogin;
                // navigate(directlogin); 
                document.location.href=directlogin;
                setTimeout(function(){
                  $(".menukategoriall").click();
                  localStorage.removeItem('directlogin');
                }, 1500);
              }
            }
            else {
              if(from=="utama") {
                document.location.href="https://riplabs.id/dashboard/";
              }
              else if(from=="catatanku") {
                document.location.href="https://catatanku.riplabs.id/";
              }
              else if(from=="tugasku") {
                document.location.href="https://tugasku.riplabs.id/";
              }
              else if(from=="dompetku") {
                document.location.href="https://dompetku.riplabs.id/";
              }
              else if(from=="sharefiles") {
                document.location.href="https://sharefiles.riplabs.id/";
              }
            }
          }
        }
        catch(e) {console.log(e.message);}
    }
    useEffect(() => {
      cekSendToken();
    }, []);
    return(
        <></>
    )
}
export default SendToken;