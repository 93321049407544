import React from 'react';

function Test(props) {
    console.log('render test');
    return(
        <div>
          Test1
          Test
        </div>
    )
}

export default Test;