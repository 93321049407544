import { restapilink, baseurl, yearnow, baseurlassets, basecolor1, basecolorred, appsetup } from './Autoload'; 
import axios from 'axios'; 
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
const nl2br = require('react-nl2br');
const $ = require("jquery"); 
const Swal = require('sweetalert2'); 

function rupiah(angka) {  
    var bilangan = angka;         
    var	number_string = bilangan.toString(),  
        sisa 	= number_string.length % 3,
        rupiah 	= number_string.substr(0, sisa),
        ribuan 	= number_string.substr(sisa).match(/\d{3}/g);
    if (ribuan) {
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
    } 
    return rupiah;
}
function rupiah2(angka) {  
    var bilangan = angka;         
    var	number_string = bilangan.toString(),  
        sisa 	= number_string.length % 3,
        rupiah 	= number_string.substr(0, sisa),
        ribuan 	= number_string.substr(sisa).match(/\d{3}/g);
    if (ribuan) {
        var separator = sisa ? ',' : '';
        rupiah += separator + ribuan.join(',');
    } 
    return rupiah;
}

function HeaderDompetKu(props) {

    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    const [saldoSaya, setSaldoSaya] = useState('');
    const [jumlahDompetSaya, setJumlahDompetSaya] = useState('');
    const [jumlah, setjumlah] = useState('');
    const [kategori, setkategori] = useState('1');
    const [tanggal, settanggal] = useState('');
    const [dompet, setdompet] = useState('3');
    const [catatan, setcatatan] = useState('');
    
    
    const cekDompetSaya = async() => {
        try {
        let response = await axios.post(restapilink+"api/dompetku/cekdompetsaya", {
            key:'d928195e34f940cc6aa1fec18805a13c2',
            token: token
        });
        setJumlahDompetSaya(response.data.jumlahall);
        setSaldoSaya(rupiah2(response.data.data.dompetku_saldo));
        }
        catch(e) {console.log(e);} 
    }
    


    const tambahTransaksi = async (e) => {
        e.preventDefault();
        $(".loading_riplabs").css('display','block');
        try {
          await axios.post(restapilink+"api/dompetku/tambahtransaksi", {
            key:'d928195e34f940cc6aa1fec18805a13c2',
            token: token,
            jumlah:jumlah,
            kategori:kategori,
            tanggal:tanggal,
            dompet:dompet,
            catatan:catatan
          });
          $(".loading_riplabs").css('display','none');
          $(".modal .close").click();
          $("#jumlah").val('');
          $("#kategori").val('');
          $("#tanggal").val('');
          $("#dompet").val('');
          $("#catatan").val('');
          setjumlah('');
          setkategori('');
          settanggal('');
          setdompet('');
          setcatatan('');
          Swal.fire({
            title: 'Berhasil',
            html: 'Transaksi baru sudah ditambahkan <i className="fa fa-smile-beam"></i>',
            icon: 'success',
            confirmButtonColor: basecolor1
          });

          setTimeout(function(){
            cekDompetSaya();
          }, 500);

        }
        catch(e) {
          console.log(e);
        }
    }


    const tambahkategoritransaksi = () => {
        // $(".tambahkategoritransaksi").attr("class","textdecorationnone font-14 color1 d-block mb-4 tambahkategoritransaksi");
        $(".boxtambahkategoritransaksi").slideToggle();
    }

    useEffect(() => {
        
        cekDompetSaya();

    }, [saldoSaya, jumlahDompetSaya ]);


    



    return(
        <>
            

        </>
    )
}

export default HeaderDompetKu;