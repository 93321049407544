import { appkey, appsetup, statusweb, restapilink, baseurl, yearnow, baseurlassets, basecolor1 } from './Autoload'; 
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
const $ = require("jquery");
const Swal = require('sweetalert2');

function Register(props) {
  $("html, body").animate({ scrollTop: 0 }, 50);
  const[nama, setNama] = useState('');
  const[email, setEmail] = useState('');
  const[password, setPassword] = useState('');
  const[validation, setValidation] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const directlogin = localStorage.getItem('directlogin');

  const daftarHandler = async (e) => {
    e.preventDefault(); 
    $("form#daftarHandler #submit").attr('disabled','disabled'); 
    var cek1 = email.indexOf("@");
    var cek2 = email.indexOf(".");
    if(cek1 < 0 || cek2 < 0) {
      Swal.fire({
        title: 'Perhatian',
        html: 'Silahkan masukkan format Alamat Email dengan benar <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
    }
    else {
      $(".loading_riplabs").css('display','block');
      const formData = new FormData();
      formData.append('key',appkey);
      formData.append('pengguna_nama',nama);
      formData.append('pengguna_email',email);
      formData.append('pengguna_password',password);
      await axios.post(restapilink+"api/auth/register", formData)
      .then((response) => {
        $(".loading_riplabs").css('display','none');
        if(response.data.status == "error") {
          Swal.fire({
            title: 'Oops...',
            html: response.data.message + ' <i class="fa fa-smile-beam"></i>',
            icon: 'error',
            confirmButtonColor: basecolor1
          });
          return false;
        }
        else {
          $("form#daftarHandler #submit").removeAttr('disabled');
          localStorage.setItem('token',response.data.token);
          Swal.fire({
            title: 'Pendaftaran Berhasil',
            html: '<span>'+response.data.message+' <i class="fa fa-smile-beam"></i></span>',
            icon: 'success',
            confirmButtonColor: basecolor1,
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: 'Oke, Lanjutkan'
          }).then((result) => {
            if (result.value) {
              if(statusweb=="production") {
                // Kirim token ke all riplabs apps
                document.location.href="https://riplabs.id/sendtoken/"+response.data.token+"/from/"+appsetup;
              }
              else {
                if(directlogin) {
                  if(directlogin!=="") {
                    // document.location.href= baseurl+directlogin;
                    // navigate(directlogin);
                    document.location.href=directlogin;
                    setTimeout(function() {
                      $(".menukategoriall").click();
                      localStorage.removeItem('directlogin');
                    }, 1500);
                  }
                }
                else {
                  if(appsetup=="utama") {
                    navigate('/dashboard/');
                  }
                  else {
                    navigate('/');
                  }
                }
              }
            }
          });
          setTimeout(function() {
            $(".swal2-confirm").click();
          }, 4000);
        }
      });
    }
  }

  useEffect(() => {
    if(localStorage.getItem('token')) {
      navigate('/');
    }
  }, []);


  $(".formnama").on('keyup paste change keydown', function(v) {
    if (v.keyCode==192 || v.keyCode==220 || v.keyCode==222 || v.keyCode==186 || v.keyCode==188 || v.keyCode==190 || v.keyCode==191 || v.keyCode==189 || v.keyCode==187) {
      Swal.fire({
        title: 'Perhatian',
        html: 'Diharapkan hanya boleh menggunakan huruf, angka dan tanpa simbol yang dilarang oleh sistem <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
      $(this).val('');
      setNama('');
    }
    var valinput = $(this).val();
    var cekvalue1 = valinput.indexOf("!");var cekvalue2 = valinput.indexOf("@");var cekvalue3 = valinput.indexOf("#");var cekvalue4 = valinput.indexOf("$");var cekvalue5 = valinput.indexOf("%");var cekvalue6 = valinput.indexOf("^");var cekvalue7 = valinput.indexOf("&");var cekvalue8 = valinput.indexOf("*");var cekvalue10 = valinput.indexOf("'");var cekvalue11 = valinput.indexOf("`");var cekvalue12 = valinput.indexOf("~");var cekvalue17 = valinput.indexOf(",");var cekvalue18 = valinput.indexOf(".");var cekvalue19 = valinput.indexOf("<");var cekvalue20 = valinput.indexOf(">");var cekvalue21 = valinput.indexOf("/");var cekvalue22 = valinput.indexOf("?");var cekvalue23 = valinput.indexOf("|");var cekvalue24 = valinput.indexOf(";");var cekvalue25 = valinput.indexOf(":");var cekvalue26 = valinput.indexOf("(");var cekvalue27 = valinput.indexOf(")");
    if(cekvalue1>=0 || cekvalue2>=0 || cekvalue3>=0 || cekvalue4>=0 || cekvalue5>=0 || cekvalue6>=0 || cekvalue7>=0 || cekvalue8>=0 || cekvalue10>=0 || cekvalue11>=0 || cekvalue12>=0 || cekvalue17>=0 || cekvalue18>=0 || cekvalue19>=0 || cekvalue20>=0 || cekvalue21>=0 || cekvalue22>=0 || cekvalue23>=0 || cekvalue24>=0 || cekvalue25>=0 || cekvalue26>=0 || cekvalue27>=0) {
      Swal.fire({
        title: 'Perhatian',
        html: 'Diharapkan hanya boleh menggunakan huruf, angka dan tanpa simbol yang dilarang oleh sistem <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
      $(this).val('');
      setNama('');
    }
  });
  $(".formemail").on('keyup paste change keydown', function(v) {
    var start = v.target.selectionStart;
    var end = v.target.selectionEnd;
    v.target.value = v.target.value.toLowerCase();
    v.target.setSelectionRange(start, end);
    var valinput = $(this).val();
    var cekvalue1 = valinput.indexOf(" ");
    var valinputnew = valinput.replace(/ /g,"");
    if(cekvalue1>=0) {
      Swal.fire({
        title: 'Perhatian',
        html: 'Diharapkan hanya boleh menggunakan, tanpa spasi huruf, angka dan tanpa simbol yang dilarang oleh sistem <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
      $(this).val(valinputnew);
      setEmail(valinputnew);
    }
    if (v.keyCode==192 || v.keyCode==220 || v.keyCode==222 || v.keyCode==186 || v.keyCode==188 || v.keyCode==191 || v.keyCode==189 || v.keyCode==187) {
      Swal.fire({
        title: 'Perhatian',
        html: 'Diharapkan hanya boleh menggunakan huruf, angka, tanpa spasi dan tanpa simbol yang dilarang oleh sistem <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
      $(this).val('');
      setEmail('');
    }
    var valinput = $(this).val();
    var cekvalue1 = valinput.indexOf("!");var cekvalue3 = valinput.indexOf("#");var cekvalue4 = valinput.indexOf("$");var cekvalue5 = valinput.indexOf("%");var cekvalue6 = valinput.indexOf("^");var cekvalue7 = valinput.indexOf("&");var cekvalue8 = valinput.indexOf("*");var cekvalue10 = valinput.indexOf("'");var cekvalue11 = valinput.indexOf("`");var cekvalue12 = valinput.indexOf("~");var cekvalue17 = valinput.indexOf(",");var cekvalue19 = valinput.indexOf("<");var cekvalue20 = valinput.indexOf(">");var cekvalue21 = valinput.indexOf("/");var cekvalue22 = valinput.indexOf("?");var cekvalue23 = valinput.indexOf("|");var cekvalue24 = valinput.indexOf(";");var cekvalue25 = valinput.indexOf(":");var cekvalue26 = valinput.indexOf("(");var cekvalue27 = valinput.indexOf(")");
    if(cekvalue1>=0 || cekvalue3>=0 || cekvalue4>=0 || cekvalue5>=0 || cekvalue6>=0 || cekvalue7>=0 || cekvalue8>=0 || cekvalue10>=0 || cekvalue11>=0 || cekvalue12>=0 || cekvalue17>=0 || cekvalue19>=0 || cekvalue20>=0 || cekvalue21>=0 || cekvalue22>=0 || cekvalue23>=0 || cekvalue24>=0 || cekvalue25>=0 || cekvalue26>=0 || cekvalue27>=0) {
      Swal.fire({
        title: 'Perhatian',
        html: 'Diharapkan hanya boleh menggunakan huruf, angka, tanpa spasi dan tanpa simbol yang dilarang oleh sistem <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
      $(this).val('');
      setEmail('');
    }
  });

  var logoriplabs = baseurlassets+"images/riplabs/Logo%20RipLabs.png";

  return(
    <>
    <title>Daftar Akun | RipLabs Indonesia</title>
    <meta name="title" content="Daftar Akun | RipLabs Indonesia" />
    <meta itemProp="name" content="Daftar Akun | RipLabs Indonesia" />
    <meta property="og:title" content="Daftar Akun | RipLabs Indonesia" />
    <meta name="twitter:title" content="Daftar Akun | RipLabs Indonesia" />
    <div className='bodypage pagelogin animated fadeIn'>
      <center>
        <NavLink to="/"><img src={logoriplabs} style={{width:"150px",paddingTop:"40px"}} /></NavLink>
      </center>
      <div className='box'>
        <h5 className='riplabs-h5 text-center'>Daftar Akun</h5>
        <br/>
        <br/>
        <form method='post' onSubmit={daftarHandler} id='daftarHandler'>
          <label className='form-label mb-1' for="pengguna_nama">Nama Lengkap</label>
          <input required minLength="1" maxLength="255" type="nama" className="formnama form-control mb-4" id="pengguna_nama" value={nama} onChange={(e) => setNama(e.target.value)} />
          <label className='form-label mb-1' for="pengguna_email">Alamat Email</label>
          <input required minLength="4" maxLength="255" type="text" className="formemail form-control mb-4" id="pengguna_email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <label className='form-label mb-1' for="pengguna_password">Kata Sandi</label>
          <input required minLength="8" maxLength="255" type="password" className="form-control mb-4" id="pengguna_password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <button type='submit' id='submit' className='button-blue-1 d-block w-100'>Daftar</button>
        </form>
        <br/>
        <span style={{display:"block",textAlign:"center",fontSize:"16px"}}>Sudah memiliki akun? <NavLink to="/masuk/" className="riplabs-link">Masuk</NavLink></span>
        <hr/>
        <span style={{display:"block",textAlign:"center",fontSize:"16px"}}><NavLink to="/" className="riplabs-link"> Beranda</NavLink></span>
      </div>
    </div>
    </>
  )
}

export default Register;