import { appkey, restapilink, baseurl, yearnow, baseurlassets, basecolor1, basecolorred, appsetup } from './Autoload'; 
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import SubHeader from './SubHeader';
import Header from './Header';
import Footer from './Footer';
// import "../../node_modules/datatables.net-dt/js/dataTables.dataTables";
// import "../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css";
const $ = require("jquery");   
const Swal = require('sweetalert2');

function rupiah(angka) {  
  var bilangan = angka;         
  var	number_string = bilangan.toString(),  
      sisa 	= number_string.length % 3,
      rupiah 	= number_string.substr(0, sisa),
      ribuan 	= number_string.substr(sisa).match(/\d{3}/g);
  if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
  } 
  return rupiah;
}
function ucwords (str) {
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
      return $1.toUpperCase();
  });
} 
function strtolower (str) {
  return (str+'').toLowerCase();
} 
function ucfirst (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function ShareFilesDownload(props) {
    const {idsharefiles} = useParams();
    const token = localStorage.getItem('token'); 
    const tmptoken = localStorage.getItem('tmptoken'); 
    const navigate = useNavigate();
    const[datafile, setdatafile] = useState([]);
    const[jumlahdatafile, setjumlahdatafile] = useState(0);

    const cekFile = async () => {
        $("#loading_riplabs").css('display','block');
        await axios.post(restapilink+"api/sharefiles/cekfile", {
        key:appkey,
        idsharefiles:idsharefiles
        }).then((response) => {
            $("#loading_riplabs").css('display','none');
            // console.log(response);
            if(response.data.jumlah>0) {
                setdatafile(response.data.data);
            }
            if(response.data.jumlah!=="") {
                setjumlahdatafile(response.data.jumlah);
            }
        });
    }

  const DownloadFileHandler = () => {
    // var linkfile = restapilink+"sharefiles/"+datafile.id_sharefiles+"/"+datafile.sharefiles_file;
    document.location.href='https://file.sharefiles.riplabs.id/download/file/'+datafile.id_sharefiles;
    // alert(linkfile)
    // $(".downloadfile").attr('disabled','disabled');
    // // using Java Script method to get PDF file
    // fetch(linkfile).then(response => {
    //     response.blob().then(blob => {
    //         // Creating new object of PDF file
    //         const fileURL = window.URL.createObjectURL(blob);
    //         // Setting various property values
    //         let alink = document.createElement('a');
    //         alink.href = fileURL;
    //         alink.download = datafile.sharefiles_judul;
    //         alink.click();
    //         setTimeout(function(){
    //             $(".downloadfile").removeAttr('disabled');
    //         }, 2000);
    //     })
    // })
}

    useEffect(() => {
        cekFile();
        setTimeout(function(){
            $(".boxfilenotfound").css('display','flex');
      }, 1000);

    }, []);

    var logoriplabs = baseurlassets+"images/riplabs/Logo RipLabs.png";
    var logosharefiles = baseurlassets+"images/riplabs/Logo ShareFiles.png";
    if(datafile.sharefiles_kapasitas>0 && datafile.sharefiles_kapasitas<100000) {
        var sharefiles_kapasitas = (datafile.sharefiles_kapasitas / 100000).toFixed(2) + "KB";
    }
    else if(datafile.sharefiles_kapasitas>100000 && datafile.sharefiles_kapasitas<10000000) {
        var sharefiles_kapasitas = (datafile.sharefiles_kapasitas / 10000000).toFixed(2) + "MB";
    }
    // var sharefiles_kapasitas = (datafile.sharefiles_kapasitas / 100000).toFixed(1);
    
    return (
        <>
            <title>Aplikasi ShareFiles | RipLabs Indonesia</title>
            <meta name="title" content="Aplikasi ShareFiles | RipLabs Indonesia" />
            <meta itemProp="name" content="Aplikasi ShareFiles | RipLabs Indonesia" />
            <meta property="og:title" content="Aplikasi ShareFiles | RipLabs Indonesia" />
            <meta name="twitter:title" content="Aplikasi ShareFiles | RipLabs Indonesia" />
            <div className='container container-sharefiles-download'>
                <div className='header'>
                    <div className='left'>
                        <a href='https://riplabs.id/'><img src={logoriplabs} className='img1' /></a>
                        {/* <br/> */}
                        {/* Masuk/Daftar  */}
                    </div>
                    <NavLink to="/"><img src={logosharefiles} className='img2' /></NavLink>
                </div>
                {
                    jumlahdatafile > 0 && (
                        <>
                            <div className='boxdownloadfile'>
                                <div className='left'>
                                    <div className='icon'><i className='fa fa-file'></i></div>
                                    <div className='text'>
                                        <h5>{datafile.sharefiles_judul}</h5>
                                        <span>File Size: {sharefiles_kapasitas}</span>
                                        <span>Uploaded: {datafile.date_inserted}</span>
                                    </div>
                                </div>
                                <button type='button' onClick={DownloadFileHandler} className='btn btn-primary downloadfile'>Download File</button>
                            </div>
                        </>
                    )
                }
                {
                    jumlahdatafile <= 0 && (
                        <>
                            <div className='boxfilenotfound'>
                                File tidak ditemukan
                            </div>
                        </>
                    )
                }
                <div className='boxiklan'>
                    IKLAN
                </div>
                <div className='boxiklan'>
                    IKLAN
                </div>
                <div className='boxiklan'>
                    IKLAN
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default ShareFilesDownload;