import { appkey, restapilink, baseurl, yearnow, baseurlassets, basecolor1, basecolorred } from './Autoload'; 
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import SubHeader from './SubHeader';
import ReactPaginate from 'react-paginate';
const $ = require("jquery");   
const Swal = require('sweetalert2');

function rupiah(angka) {  
  var bilangan = angka;         
  var	number_string = bilangan.toString(),  
      sisa 	= number_string.length % 3,
      rupiah 	= number_string.substr(0, sisa),
      ribuan 	= number_string.substr(sisa).match(/\d{3}/g);
  if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
  } 
  return rupiah;
}
function ucwords (str) {
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
      return $1.toUpperCase();
  });
} 
function strtolower (str) {
  return (str+'').toLowerCase();
} 
function ucfirst (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function Produk(props) {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  // const [ceksesilogin, setceksesilogin] = useState(0);
  const [listprodukriplabs, setlistprodukriplabs] = useState([]);
  const [jumlahprodukriplabs, setjumlahprodukriplabs] = useState(0);
  const [kategoriprodukriplabs, setkategoriprodukriplabs] = useState([]);
  const [jumlahkategoriprodukriplabs, setjumlahkategoriprodukriplabs] = useState(0);
  const [judulPencarian, setJudulPencarian] = useState('');

  const [keyword, setkeyword] = useState('');
  const [kategori, setkategori] = useState('All');
  // getListProdukRipLabs
  const getListProdukRipLabs = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response3 = await axios.post(restapilink+"api/data/listprodukriplabs", {
        key:appkey,
        keyword:keyword,
        kategori:kategori
      });
      setlistprodukriplabs(response3.data.data);
      setjumlahprodukriplabs(response3.data.jumlah);
      setJudulPencarian(response3.data.judul);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {console.log(e.message);}
  }
  const getKategoriProdukRipLabs = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response3 = await axios.post(restapilink+"api/data/kategoriprodukriplabs", {
        key:appkey
      });
      setkategoriprodukriplabs(response3.data.data);
      setjumlahkategoriprodukriplabs(response3.data.jumlah);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {console.log(e.message);}
  }
  const searchHandler = async (e) => {
    setkeyword(e);
  }
  const kategoriHandler = async (e) => {
    setkategori(e);
  }
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 9;
  const handlePageClick = (e) => {
    const newOffset = (e.selected * itemsPerPage) % listprodukriplabs.length;
    setItemOffset(newOffset);
  }

  useEffect(() => {
    getListProdukRipLabs();
    getKategoriProdukRipLabs();

    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(listprodukriplabs.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(listprodukriplabs.length / itemsPerPage));

    var kategori2 = kategori.toLowerCase().replaceAll(" ","");
    $(".listkategorimenu").removeClass(" active ");
    $(".listkategorimenu"+kategori2).addClass(" active ");

  }, [ jumlahprodukriplabs, itemOffset, itemsPerPage, keyword, kategori ]); 

    return(
      <>
      <title>Produk RipLabs | RipLabs Indonesia</title>
      <meta name="title" content="Produk RipLabs | RipLabs Indonesia" />
      <meta itemProp="name" content="Produk RipLabs | RipLabs Indonesia" />
      <meta property="og:title" content="Produk RipLabs | RipLabs Indonesia" />
      <meta name="twitter:title" content="Produk RipLabs | RipLabs Indonesia" />
      <div className='bodypage pageproduk animated fadeIn'>
        <SubHeader title="Produk" desc="Beberapa Produk RipLabs yang bisa kamu order." />
        <section className='riplabsapps riplabsproducts riplabsportofolio' style={{marginBottom:"60px"}}>
          <div className='container'>
            {/* Search Data */}
            <input type="search" className='boxdompetku1 mb-0' placeholder='Cari judul atau kategori produk...' onChange={(e) => searchHandler(e.target.value)} />
            <br/>
            <div className='listkategoridata'>
            <span className="listkategorimenu listkategorimenuall" onClick={(e) => kategoriHandler(e.currentTarget.id)} id="All">All</span>
            {
              Array.isArray(kategoriprodukriplabs) ?
                kategoriprodukriplabs.map((data, i) => {
                  var classnamelist = "listkategorimenu listkategorimenu"+data.produk_kategori.toLowerCase().replaceAll(" ","");
                  return (
                    <span key={i} className={classnamelist} id={data.produk_kategori} onClick={(e) => kategoriHandler(e.currentTarget.id)}>{data.produk_kategori}</span>
                  )
                })
              : ""
            }
            </div>
            <hr/>
            <h4 className='font-semibold'>{judulPencarian}</h4>
            {
              jumlahprodukriplabs > 0 && (
                <>
                  <div className="row justify-content-center rowcard1 rowcardnoborder card-groupp">
                    {
                      Array.isArray(currentItems) ?
                      currentItems.map((data, i) => {
                        let listprodukriplabsimg = data.produk_thumbnail ? baseurlassets+'images/produk/thumbnail/' + data.produk_thumbnail : '';
                        let linkpesanproduk = "https://api.whatsapp.com/send?phone=6289514392694&text=Hallo min RipLabs, saya mau pesan produk " + data.produk_judul + " apakah ready min? ";
                        return (
                          <div key={i} className="card col-lg-4 p-3 mb-3">
                            <div className='image'>
                              <img src={listprodukriplabsimg} className="card-img-top" alt={listprodukriplabsimg} />
                            </div>
                            <div className="card-body">
                              <h5 className="card-title mb-1">{data.produk_judul}</h5>
                              <div className='color1 font-medium mb-3'>{data.produk_kategori} | {data.produk_terjual} Terjual</div>
                              <p className="card-text">{data.produk_deskripsi}</p>
                            </div>
                            <div className="card-footer">
                              <h5 className='estimasiharga2 d-block mt-3'>Rp. {rupiah(data.produk_harga)}</h5>
                              <div className='row'>
                                <div className='col-6'>
                                  <a target="_blank" href={data.produk_preview} className="textdecorationnone color1 font-medium d-block mt-3">Preview</a>
                                </div>
                                <div className='col-6'>
                                  <a href={linkpesanproduk} target="_blank" style={{textAlign:"right"}} className="textdecorationnone color1 font-medium d-block mt-3">Pesan Produk</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                      : ""
                    }
                  </div>
                  <br/>
                  <br/>
                  <center>
                    <ReactPaginate 
                      breakLabel="..."
                      nextLabel="Next"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={2}
                      marginPagesDisplayed={1}
                      pageCount={pageCount}
                      previousLabel="Previous"
                      renderOnZeroPageCount={null}
                      containerClassName="pagination m-auto d-inline-flex"
                      pageClassName="page-item"
                      breakClassName='page-item'
                      breakLinkClassName='page-link'
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      activeClassName="active"
                      activeLinkClassName="active"
                    />
                  </center>
                  <br/>
                  <p style={{color:"var(--basecolor6)"}} className="d-block mt-4 text-center">Ingin mengunggah Produk di RipLabs? <a href='https://api.whatsapp.com?phone=6289514392694&text=' className='textdecorationnone color1 font-medium' target='_blank'>Hubungi Admin RipLabs</a>.</p>
                </>
              )
            }
          </div>
        </section>
      </div>
      </>
    )
}

export default Produk;  