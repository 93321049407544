import { statusweb, appkey, restapilink, basecolor1, baseurl, yearnow, baseurlassets, appsetup } from './Autoload'; 
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
// import SubHeader from './SubHeader';
// import MenuNavbarDesktop from './MenuNavbarDesktop';
const $ = require("jquery");
const Swal = require('sweetalert2');

function rupiah(angka) {  
  var bilangan = angka;         
  var	number_string = bilangan.toString(),  
      sisa 	= number_string.length % 3,
      rupiah 	= number_string.substr(0, sisa),
      ribuan 	= number_string.substr(sisa).match(/\d{3}/g);
  if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
  } 
  return rupiah;
}
function rupiah2(angka) {  
  var bilangan = angka;         
  var	number_string = bilangan.toString(),  
      sisa 	= number_string.length % 3,
      rupiah 	= number_string.substr(0, sisa),
      ribuan 	= number_string.substr(sisa).match(/\d{3}/g);
  if (ribuan) {
      var separator = sisa ? ',' : '';
      rupiah += separator + ribuan.join(',');
  } 
  return rupiah;
}

function Dashboard(props) {
  const token = localStorage.getItem('token');
  const tmptoken = localStorage.getItem('tmptoken'); 
  const navigate = useNavigate();
  const [ceksesilogin, setceksesilogin] = useState(0);
  const [loadsaransaya, setloadsaransaya] = useState(1);
  const [ScrollHeightAktivitasKu, setScrollHeightAktivitasKu] = useState(0);
  const [PenggunaPopupIntroUtama, setPenggunaPopupIntroUtama] = useState('no');
  // const [PenggunaPopupIntro1, setPenggunaPopupIntro1] = useState('no');
  // const [PenggunaPopupIntro2, setPenggunaPopupIntro2] = useState('no');
  // const [PenggunaPopupIntro3, setPenggunaPopupIntro3] = useState('no');
  // const [PenggunaPopupIntro4, setPenggunaPopupIntro4] = useState('no');

  const [idCatatanKuEdit, setidCatatanKuEdit] = useState('');
  const [loadidsection, setloadidsection] = useState(0);
  const [idtugaskuprojekawal, setidtugaskuprojekawal] = useState('');
  // const [idceritakukategoriawal, setidceritakukategoriawal] = useState('');

  const [loaddataaktivitasku, setloaddataaktivitasku] = useState(0);
  const [limitdataaktivitasku, setLimitAktivitasKu] = useState(20);
  const [dataaktivitasku, setAktivitasKu] = useState([]);
  const [jumlahaktivitasku, setJumlahAktivitasKu] = useState(0);
  const [jumlahallaktivitasku, setJumlahAllAktivitasKu] = useState(0);

  const [loaddatacatatanku6, setloaddatacatatanku6] = useState(0);
  const [jumlahcatatan6, setCatatanKu6] = useState(0);
  const [datacatatanku6, setCatatanKu] = useState([]);
  
  const [loaddatatugasku6, setloaddatatugasku6] = useState(0);
  const [jumlahtugasku6, setTugasKu6] = useState(0);
  const [datatugasku6, setTugasKu] = useState([]);
  
  // const [loaddataceritaku6, setloaddataceritaku6] = useState(0);
  // const [jumlahceritaku6, setCeritaKu6] = useState(0);
  // const [dataceritaku6, setCeritaKu] = useState([]);

  // DompetKu
  const [dompet, setdompet] = useState('');
  const [datadompetdipilihsaya, setdatadompetdipilihsaya] = useState([]);
  const [jumlahDompetSaya, setJumlahDompetSaya] = useState('');
  const [datatransaksiterakhir, setdatatransaksiterakhir] = useState([]);
  const [jumlahdatatransaksiterakhir, setjumlahdatatransaksiterakhir] = useState(0);
  // DompetKu

  // ShareFiles
  const[sharefileskapasitas, setsharefileskapasitas] = useState('0');
  const[datafilesaya, setdatafilesaya] = useState([]);
  const[jumlahdatafilesaya, setjumlahdatafilesaya] = useState(0);
  const cekKapasitasTerpakai = async () => {
    await axios.post(restapilink+"api/sharefiles/cekfilesaya", {
      key:appkey,
      token:token,
      tmptoken:tmptoken,
      limit:'5'
    }).then((response) => {
      // console.log(response);
      if(response.data.kapasitasterpakai) {
        setsharefileskapasitas(response.data.kapasitasterpakai);
      }
      if(response.data.jumlah>0) {
        setdatafilesaya(response.data.data);
      }
      if(response.data.jumlah) {
        setjumlahdatafilesaya(response.data.jumlah);
      }
    });
  }
  // ShareFiles

  // getAktivitasKu 
  const getAktivitasKu = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response = await axios.post(restapilink+"api/data/aktivitasku", {
        key:appkey,
        token: token,
        limit:limitdataaktivitasku
      });
      $(".loading_riplabs").css('display','none');
      setAktivitasKu(response.data.data);
      setJumlahAktivitasKu(response.data.jumlah);
      setJumlahAllAktivitasKu(response.data.jumlahall);
      setloaddataaktivitasku(1)
    }
    catch(e) {
      // console.log(e);
      setloaddataaktivitasku(0)
    }
  }
  // getCatatanKu6
  const getCatatanKu6 = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response = await axios.post(restapilink+"api/data/catatanku6", {
        key:appkey,
        token: token
      });
      $(".loading_riplabs").css('display','none');
      setCatatanKu(response.data.data);
      setCatatanKu6(response.data.jumlah);
      setloaddatacatatanku6(1);
    }
    catch(e) {
      // console.log(e);
      setloaddatacatatanku6(0);
    }
  }
  // getTugasKu6
  const getTugasKu6 = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response = await axios.post(restapilink+"api/data/tugasku6", {
        key:appkey,
        token: token
      });
      $(".loading_riplabs").css('display','none');
      setTugasKu(response.data.data);
      setTugasKu6(response.data.jumlah);
      setloaddatatugasku6(1);
    }
    catch(e) {
      // console.log(e);
      setloaddatatugasku6(0);
    }
  }
  // getCeritaKu6
  // const getCeritaKu6 = async () => {
  //   $(".loading_riplabs").css('display','block');
  //   try {
  //     let response = await axios.post(restapilink+"api/data/ceritaku6", {
  //       key:appkey,
  //       token: token
  //     });
  //     $(".loading_riplabs").css('display','none');
  //     setCeritaKu(response.data.data);
  //     setCeritaKu6(response.data.jumlah);
  //     setloaddataceritaku6(1);
  //   }
  //   catch(e) {console.log(e);setloaddataceritaku6(0);}
  // }

  const[namapendekku, setSessionNamaPendekKu] = useState('');
  const[namapanjangku, setSessionNamaPanjangKu] = useState('');
  const[emailku, setSessionEmailKu] = useState('');
  const fetchData = async () => {
    await axios.post(restapilink+"api/auth/ceklogin", {
      key:appkey,
      token:token
    }).then((response) => {
      setceksesilogin(response.data.ceklogin)
      if(response.data.ceklogin == 0) {
        localStorage.removeItem('token');
        localStorage.removeItem('kategoricatatankudefault');
        localStorage.removeItem('catatanlist');
        navigate("/login/");
      }
      else {
        setSessionNamaPendekKu(response.data.nama);
        setSessionNamaPanjangKu(response.data.namapanjangku);
        setSessionEmailKu(response.data.email);
        setloadidsection(1);
        if(response.data.datapengguna.pengguna_popupintroutama=="yes") {
          setPenggunaPopupIntroUtama(response.data.datapengguna.pengguna_popupintroutama);
        }
        // if(response.data.datapengguna.pengguna_popupintro1) {
        //   setPenggunaPopupIntro1(response.data.datapengguna.pengguna_popupintro1);
        // }
        // if(response.data.datapengguna.pengguna_popupintro2) {
        //   setPenggunaPopupIntro2(response.data.datapengguna.pengguna_popupintro2);
        // }
        // if(response.data.datapengguna.pengguna_popupintro3) {
        //   setPenggunaPopupIntro3(response.data.datapengguna.pengguna_popupintro3);
        // }
        // if(response.data.datapengguna.pengguna_popupintro4) {
        //   setPenggunaPopupIntro4(response.data.datapengguna.pengguna_popupintro4);
        // }
      }
    });
  }

  const lihataktivitassebelumnya = async(e) => {
    var newlimitdataaktivitasku = limitdataaktivitasku + 20;
    setLimitAktivitasKu(newlimitdataaktivitasku);
    getAktivitasKu(); 
    setScrollHeightAktivitasKu(1);
  }
  
  const cekProjekTugasAwal = async() => {
    try {
      let response = await axios.post(restapilink+"api/tugasku/cekprojektugasawal", {
        key:appkey,
        token: token
      });
      setidtugaskuprojekawal(response.data.id);
    }
    catch(e) {}
  }
  // const cekKategoriCeritaAwal = async() => {
  //   try {
  //     let response = await axios.post(restapilink+"api/ceritaku/cekkategoriceritaawal", {
  //       key:appkey,
  //       token: token
  //     });
  //     setidceritakukategoriawal(response.data.id);
  //   }
  //   catch(e) {console.log(e);}
  // }

  // DompetKu
  const cekDompetSaya = async() => {
    try {
      let response = await axios.post(restapilink+"api/dompetku/cekdompetsaya", {
        key:appkey,
        token: token
      });
      setdompet(response.data.data.id_dompetku);
      setdatadompetdipilihsaya(response.data.datadompetdipilihsaya.dompetku_nama);
      setJumlahDompetSaya(response.data.jumlahall);
      if(response.data.data.dompetku_saldo<0) {
        $(".saldoSaya").html("Rp -"+rupiah2(Math.abs(response.data.data.dompetku_saldo)));
      }
      else if(response.data.data.dompetku_saldo==0) {
        $(".saldoSaya").html("Rp "+response.data.data.dompetku_saldo);
      }
      else if(response.data.data.dompetku_saldo>0) {
        $(".saldoSaya").html("Rp "+rupiah2(response.data.data.dompetku_saldo));
      }
    }
    catch(e) {
      // console.log(e);
    } 
  }
  const cekTransaksiTerakhir = async() => {
    try {
      let response = await axios.post(restapilink+"api/dompetku/cektransaksiterakhir", {
        key:appkey,
        token: token,
        iddompet: dompet
      });
      setdatatransaksiterakhir(response.data.data);
      setjumlahdatatransaksiterakhir(response.data.jumlah);
    }
    catch(e) {
      // console.log(e);
    }
  }
  // DompetKu

  const autoShowPopupAddCatatanKu = async(e) => {
    if(appsetup=="catatanku") {
      navigate('/catatan/all');
    }
    else {
      navigate('/catatanku/catatan/all');
    }
    setTimeout(function(){
      $(".menukategoriall").addClass(" active ");
    }, 500);
    setTimeout(function() {
      $(".btnshowmodalnyatet").click();
    }, 1500); 
  }

  const autoShowPopupViewCatatanKu = async (e) => {
    var pecahe = e.split("|");
    if(appsetup=="catatanku") {
      navigate('/catatan/'+pecahe[1]);
    }
    else {
      navigate('/catatanku/catatan/'+pecahe[1]);
    }
    setTimeout(function(){
      $(".menukategori"+pecahe[1]).addClass(" active ");
    }, 1000);
    setTimeout(function() {
      $(".btneditcatatanku"+pecahe[0]).click();
    }, 1500);
  }

  const autoShowPopupAddTugasKu = async(e) => {
    if(appsetup=="tugasku") {
      navigate('/tugas/'+idtugaskuprojekawal);
    }
    else {
      navigate('/tugasku/tugas/'+idtugaskuprojekawal);
    }
    setTimeout(function(){
      $(".menukategori"+idtugaskuprojekawal).addClass(" active ");
    }, 1000);
    setTimeout(function() {
      $(".btnshowmodalnugas").click();
    }, 1500);
  }
  const autoShowPopupViewTugasKu = async (e) => {
    var pecahe = e.split("|");
    if(appsetup=="tugasku") {
      navigate('/tugas/'+pecahe[1]);
    }
    else {
      navigate('/tugasku/tugas/'+pecahe[1]);
    }
    setTimeout(function(){
      $(".menukategori"+pecahe[1]).addClass(" active ");
    }, 1000);
    setTimeout(function() {
      $(".btnedittugasku"+pecahe[0]).click();
    }, 1500);
  }
  
  const autoShowPopupAddTransaksi = async(e) => {
    if(appsetup=="dompetku") {
      navigate('/');
    }
    else {
      navigate('/dompetku/');
    }
    setTimeout(function() { 
      $(".tambahtransaksi").click();
    }, 1000);
  }
  const autoShowPopupAddTransaksi2 = async(e) => {
    if(appsetup=="dompetku") {
      navigate('/');
    }
    else {
      navigate('/dompetku/');
    }
  }
  const autoShowPopupViewTransaksi = async (e) => {
    var pecahe = e.split("|");
    var id = pecahe[0];
    var periode = pecahe[1];
    if(appsetup=="dompetku") {
      navigate('/');
    }
    else {
      navigate('/dompetku/');
    }
    setTimeout(function() {
      $(".edittransaksi"+pecahe[0]).click();
    }, 2000);
  }

  const autoShowPopupAddShareFile = async(e) => {
    if(appsetup=="sharefiles") {
      navigate('/');
    }
    else {
      navigate('/sharefiles/');
    }
    setTimeout(function() { 
      $(".unggahfile").click();
    }, 2000);
  }

  // const autoShowPopupAddCeritaKu = async(e) => {
  //   if(appsetup=="utama") {
  //     navigate('/tugasku/tugas/'+idtugaskuprojekawal);
  //   }
  //   else {
  //     navigate('/tugas/'+idtugaskuprojekawal);
  //   }
  //   setTimeout(function(){
  //     $(".menukategori"+idtugaskuprojekawal).addClass(" active ");
  //   }, 1000);
  //   setTimeout(function() {
  //     $(".btnshowmodalnugas").click();
  //   }, 1500);
  // }
  // const autoShowPopupViewCeritaKu = async (e) => {
  //   var pecahe = e.split("|");
  //   if(appsetup=="utama") {
  //     navigate('/tugasku/tugas/'+pecahe[1]);
  //   }
  //   else {
  //     navigate('/tugas/'+pecahe[1]);
  //   }
  //   setTimeout(function(){
  //     $(".menukategori"+pecahe[1]).addClass(" active ");
  //   }, 1000);
  //   setTimeout(function() {
  //     $(".btnedittugasku"+pecahe[0]).click();
  //   }, 1500);
  // }

  // Lapor Bug & Ulasan
  const formlaporbugulasan = async (e) => {
    e.preventDefault();
    $("form#formlaporbugulasan #submit").attr('disabled','disabled');
    var saran_isi = $("form#formlaporbugulasan #saran_isi").val();
    if(saran_isi.trim()=="") {
      Swal.fire({
        title: 'Oops...',
        html: 'Jangan kosongkan Formulir Saran ya <i className="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      }); 
      return false;
    }
    else {
      $(".loading_riplabs").css('display','block');
      try {
        await axios.post(restapilink+"api/saran/tambahsaran", {
          key:appkey,
          token: token,
          saran_isi:saran_isi
        });
        $(".loading_riplabs").css('display','none');
        $(".modal .close").click();
        setloadsaransaya(1);
        $("form#formlaporbugulasan #submit").removeAttr('disabled');
        Swal.fire({
          title: 'Berhasil',
          html: 'Laporan kamu berhasil dikirim, terima kasih banyak, semoga membantu <i className="fa fa-smile-beam"></i>',
          icon: 'success',
          confirmButtonColor: basecolor1
        });
      }
      catch(e) {}
    }
  }
  const cekSaranSaya = async () => {
    await axios.post(restapilink+"api/saran/ceksaransaya", {
      key:appkey,
      token:token
    }).then((response) => {
      setloadsaransaya(response.data.jumlah);
    });
  }
  // End Lapor Bug & Ulasan

  // Tutup Popup Intro Utama
  const TutupPopupIntro = async (e) => {
    try {
      axios.post(restapilink+"api/auth/tutuppopupintro", {
        key:appkey,
        token: token,
        popup: 'utama'
      });
      setTimeout(function(){
        $(".modal .close").click();
        $("html, body").animate({ scrollTop: 0 }, 50);
      }, 500);
    }
    catch(e) {
      // console.log(e);
    }
  }
  // Tutup Popup Intro Utama

  useEffect(() => {
    cekProjekTugasAwal();
    // cekKategoriCeritaAwal();
    fetchData();
    getCatatanKu6();
    getTugasKu6();
    cekDompetSaya();
    cekTransaksiTerakhir();
    // getTransaksiKu6();
    // getCeritaKu6();
    getAktivitasKu();
    cekSaranSaya();
    cekKapasitasTerpakai();

    if(jumlahaktivitasku>0 && ScrollHeightAktivitasKu<1) {
      $(".aktivitasku").scrollTop($('.aktivitasku')[0].scrollHeight);
    }
    if(jumlahallaktivitasku > jumlahaktivitasku) {
      $(".btnloadmoreaktivitasku").css('display','block');
    }
    else {
      $(".btnloadmoreaktivitasku").css('display','none');
    }

    // setTimeout(function(){
    //   $(".modal .close").click();
    // }, 1000);

    $(".menutogglepopupintro").on('click',function(){
      var target = $(this).attr('target');
      $(".boxintro").removeClass(' show ');
      // $(".boxintro").slideUp();
      // setTimeout(function() {
        // $("."+target).slideDown();
        $("."+target).addClass(' show ');
      // }, 500);
    });

    if(ceksesilogin > 0 && PenggunaPopupIntroUtama !== 'yes') {
      setTimeout(function() {
        $(".showmodalintroutama").click();
      }, 2000);
    }

    setTimeout(function(){
      $(".colsharefiles").css('display','block');
    }, 1000);


  }, [ ceksesilogin, jumlahaktivitasku, jumlahallaktivitasku, limitdataaktivitasku, loadsaransaya, jumlahdatafilesaya, PenggunaPopupIntroUtama ]);


  // setTimeout(function(){
  //   document.location.href=baseurl+'dashboard/';
  // }, 1800000);

  let date = new Date(); 
  let hh = date.getHours();
  let mm = date.getMinutes();
  let ss = date.getSeconds();
  let session = "AM";
  if(hh > 12){ session = "PM";}
  hh = (hh < 10) ? "0" + hh : hh;
  mm = (mm < 10) ? "0" + mm : mm;
  ss = (ss < 10) ? "0" + ss : ss;
  let time = hh + ":" + mm + ":" + ss + " " + session;
  var jam = hh+""+mm;

  if(namapendekku!=="") {
    var grettingtext = "";
    if(jam >= "0000" && jam <= "0500") {grettingtext += "Selamat Malam, " + namapendekku +".";}
    if(jam >= "0500" && jam <= "1000") {grettingtext += "Selamat Pagi, " + namapendekku +".";}
    if(jam >= "1000" && jam <= "1100") {grettingtext += "Selamat Pagi Menjelang Siang, " + namapendekku +".";}
    if(jam >= "1100" && jam <= "1500") {grettingtext += "Selamat Siang, " + namapendekku +".";}
    if(jam >= "1500" && jam <= "1800") {grettingtext += "Selamat Sore, " + namapendekku +".";}
    if(jam >= "1800" && jam <= "1900") {grettingtext += "Selamat Sore Menjelang Malam, " + namapendekku +".";}
    if(jam >= "1900" && jam <= "2400") {grettingtext += "Selamat Malam, " + namapendekku +".";}
  }
  
  var logoaktivitasku = baseurlassets+"images/riplabs/Logo AktivitasKu.png";
  var logocatatanku = baseurlassets+"images/riplabs/Logo CatatanKu.png";
  var logotugasku = baseurlassets+"images/riplabs/Logo TugasKu.png";
  var logodompetku = baseurlassets+"images/riplabs/Logo DompetKu.png";
  var logosharefiles = baseurlassets+"images/riplabs/Logo ShareFiles.png";
  // var logoceritaku = baseurlassets+"images/riplabs/Logo CeritaKu.png";

  if(appsetup=="catatanku") {
    var linktopagecatatanku = "/catatan/all/";
    var linktopagetugasku = "/tugasku/tugas/"+idtugaskuprojekawal+"/";
    var linktopagedompetku = "/dompetku/";
    var linktopagesharefiles = "/sharefiles/";
  }
  else if(appsetup=="tugasku") {
    var linktopagecatatanku = "/catatanku/catatan/all/";
    var linktopagetugasku = "/tugas/"+idtugaskuprojekawal+"/";
    var linktopagedompetku = "/dompetku/";
    var linktopagesharefiles = "/sharefiles/";
  }
  else if(appsetup=="dompetku") {
    var linktopagecatatanku = "/catatanku/catatan/all/";
    var linktopagetugasku = "/tugasku/tugas/"+idtugaskuprojekawal+"/";
    var linktopagedompetku = "/";
    var linktopagesharefiles = "/sharefiles/";
  }
  else if(appsetup=="sharefiles") {
    var linktopagecatatanku = "/catatanku/catatan/all/";
    var linktopagetugasku = "/tugasku/tugas/"+idtugaskuprojekawal+"/";
    var linktopagedompetku = "/dompetku/";
    var linktopagesharefiles = "/";
  }
  else {
    var linktopagecatatanku = "/catatanku/catatan/all/";
    var linktopagetugasku = "/tugasku/tugas/"+idtugaskuprojekawal+"/";
    var linktopagedompetku = "/dompetku/";
    var linktopagesharefiles = "/sharefiles/";
  }
  // var linktopageceritaku = "/ceritaku/cerita/"+idceritakukategoriawal+"/";

  return(
    <>
      <title>Dashboard | RipLabs Indonesia</title>
      <div className='bodypage pagedashboard'>
        <br/>
        <br/>
          <div className='container'>
              <h2>{grettingtext}</h2> 
              <br/>
              <div className='boxpagedashboard row'>

                {
                  namapanjangku && (
                    <>
                      <div className='col-lg-3 mb-4 boxidsection'>
                        <NavLink to='/account/' className='textdecorationnone'>
                        <div className='box bgcolor5_3 text-center d-flex' style={{alignItems:"center",justifyContent:"center"}}>
                          <div className='body'>
                            <div className='image color2'>
                              <img />
                              <i className='fa fa-user-circle color2 fa-8x'></i>
                            </div>
                            <h3 className='namapanjangku mt-3 mb-1 font-semibold color2'>{namapanjangku}</h3>
                            <span className='emailku d-block color2 textdecorationnone'>{emailku}</span>
                            <NavLink to='/account/' className='mt-3 d-block font-medium textdecorationnone color1'>Pengaturan</NavLink>
                          </div>
                        </div>
                        </NavLink>
                      </div>
                    </>
                  )
                }
                
                {
                  loaddataaktivitasku > 0 && (
                    <>
                      {
                        jumlahaktivitasku > 0 && (
                          <>
                            <div className='col-lg-9 mb-4'>
                              <div className='box'>
                                <div className='header' style={{padding:"10px"}}>
                                  <div className='textdecorationnone btntitleapps'>
                                    <img src={logoaktivitasku} style={{width:"170px"}} />
                                  </div>
                                </div>
                                <div className='bodyX'>
                                  <div className='aktivitasku'>
                                    {
                                      jumlahallaktivitasku > jumlahaktivitasku && (
                                        <div className='btnloadmoreaktivitasku'>
                                          <button type='button' onClick={lihataktivitassebelumnya} className='button-blue-1 p-2 font-12 d-block w-100'>Lihat Aktivitas Sebelumnya</button>
                                          <hr/>
                                        </div>
                                      )
                                    }
                                    <small className='d-block text-center font-13'>Log Aktivitas 30 hari sebelumnya akan terhapus setiap harinya</small>
                                    <hr/>
                                    {
                                      Array.isArray(dataaktivitasku) ?
                                      dataaktivitasku.map((data, i) => {
                                        return (
                                          <div key={i} className='log'>
                                            <h6 title={data.aktivitasku_judul}>{data.aktivitasku_judul.replaceAll("\\'","'")}</h6>
                                            <p title={data.date_inserted}>{data.date_inserted}</p>
                                          </div>
                                        )
                                      })
                                      : ""
                                    }

                                  </div>
                                </div>

                              </div>
                            </div>
                          </>
                        )
                      }
                      {
                        jumlahaktivitasku <= 0 && (
                          <>
                            <div className='col-lg-9 mb-4'>
                              <div className='box'>
                                <div className='header' style={{padding:"10px"}}>
                                  <div className='textdecorationnone btntitleapps'>
                                    <img src={logoaktivitasku} style={{width:"170px"}} />
                                  </div>
                                </div>
                                <div className='bodyX'>
                                  <div className='aktivitaskuX d-flex align-items-center text-center justify-content-center'>
                                    <h6 className='font-16'>Belum ada Aktivitas</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      }
                    </>
                  )
                }

                {
                  loaddatacatatanku6 > 0 && (
                    <>
                      <div className='col-lg-6 mb-4'>
                        <div className='box'>
                          <div className='header' style={{padding:"10px"}}>
                            <div className='d-flex justify-content-between align-items-center'>
                              <NavLink to={linktopagecatatanku} className='textdecorationnone btntitleapps'>
                                <img src={logocatatanku} style={{width:"160px"}} />
                              </NavLink>
                              <span className='color1 btnaddapps' style={{fontSize:"20px",cursor:"pointer"}} onClick={autoShowPopupAddCatatanKu}>
                                <i className='fa fa-plus'></i>
                              </span>
                            </div>
                          </div>

                          <div className='body'>
                            {
                              jumlahcatatan6 > 0 && (
                                <div className='row list6catatanku'>
                                  {
                                    datacatatanku6.map((data, i) => {
                                      var catatankuisi = data.catatanku_isi.substr(0,100).replaceAll("\\'","'")+'...';
                                      var idlist = data.id_catatanku+"|"+data.id_catatanku_kategori;
                                      return (
                                        <div key={i} className='col-lg-6 mb-1'>
                                          <div className='list' onClick={(e) => autoShowPopupViewCatatanKu(e.currentTarget.id)} id={idlist} style={{cursor:"pointer"}}>
                                            <span className="textdecorationnone color2">
                                              <h5 title={data.catatanku_judul.replaceAll("\\'","'")}>{data.catatanku_judul.replaceAll("\\'","'")}</h5>
                                              <p title={catatankuisi}>{catatankuisi}...</p>
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              )
                            }

                            {
                              jumlahcatatan6 <= 0 && (
                                <div className='alert alert-info d-block w-100 text-center'>
                                  Belum ada Catatan
                                </div>
                              )
                            }
                            
                          </div>
                          
                          <div className='body' style={{paddingTop:"0px"}}>
                            <NavLink to={linktopagecatatanku} className="font-medium color1 textdecorationnone font-15">Buka Aplikasi CatatanKu</NavLink>
                          </div>

                        </div>
                      </div>
                    </>
                  )
                }

                {
                  loaddatatugasku6 > 0 && (
                    <>
                      <div className='col-lg-6 mb-4'>
                        <div className='box'>
                          <div className='header' style={{padding:"10px"}}>
                            <div className='d-flex justify-content-between align-items-center'>
                              <NavLink to={linktopagetugasku} className='textdecorationnone btntitleapps'>
                                <img src={logotugasku} style={{width:"135px"}} />
                              </NavLink>
                              <span className='color1 btnaddapps' style={{fontSize:"20px",cursor:"pointer"}} onClick={autoShowPopupAddTugasKu}>
                                <i className='fa fa-plus'></i>
                              </span>
                            </div>
                          </div>
                          <div className='body'>
                            {
                              jumlahtugasku6 > 0 && (
                                <div className='row list6tugasku'>
                                  {
                                    datatugasku6.map((data, i) => {
                                      var tugaskuisi = data.tugasku_isi.substr(0,100).replaceAll("\\'","'")+'...';
                                      var idlist = data.id_tugasku+"|"+data.id_tugasku_projek;
                                      return (
                                        <div key={i} className='col-lg-6 mb-1'>
                                          <div className='list' onClick={(e) => autoShowPopupViewTugasKu(e.currentTarget.id)} id={idlist} style={{cursor:"pointer"}}>
                                            <span className="textdecorationnone color2">
                                              <h5 title={data.tugasku_judul.replaceAll("\\'","'")}>{data.tugasku_judul.replaceAll("\\'","'")}</h5>
                                              <p title={tugaskuisi}>{tugaskuisi}...</p>
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              )
                            }
                            {
                              jumlahtugasku6 <= 0 && (
                                <div className='alert alert-info d-block w-100 text-center'>
                                  Belum ada Tugas
                                </div>
                              )
                            }
                          </div>
                          <div className='body' style={{paddingTop:"0px"}}>
                            <NavLink to={linktopagetugasku} className="font-medium color1 textdecorationnone font-15">Buka Aplikasi TugasKu</NavLink>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }

                {
                  jumlahDompetSaya > 0 && (
                    <>
                      <div className='col-lg-6 mb-4'>
                        <div className='box'>
                          <div className='header' style={{padding:"10px"}}>
                            <div className='d-flex justify-content-between align-items-center'>
                              <NavLink to={linktopagedompetku} className='textdecorationnone btntitleapps'>
                                <img src={logodompetku} style={{width:"155px"}} />
                              </NavLink>
                              <span className='color1 btnaddapps' style={{fontSize:"20px",cursor:"pointer"}} onClick={autoShowPopupAddTransaksi}>
                                <i className='fa fa-plus'></i>
                              </span>
                            </div>
                          </div>
                          <div className='body'>
                            {
                              jumlahDompetSaya > 0 && (
                                <div className='row list6tugasku'>
                                  <div className='col-lg-12 mb-1'>
                                    <div className='cursorpointer list' onClick={autoShowPopupAddTransaksi2}>
                                      <span className="textdecorationnone color2">
                                        <h5>Saldo {datadompetdipilihsaya}</h5>
                                        <p className='font-25 saldoSaya font-medium'></p>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                            {
                              jumlahdatatransaksiterakhir > 0 && (
                                <>
                                <h5 className='font-medium color1 my-3 font-17' style={{padding:"0px 15px"}}>Transaksi Terakhir</h5>
                                <div className='row list6tugasku'>
                                  {
                                    datatransaksiterakhir.map((data, i) => {
                                      var transaksitanggal = data.transaksi_tanggal.replaceAll("\\'","'");
                                      if(data.transaksi_kategori_tipe=="income") {
                                        var classnamejumlah = "colorgreen font-15";
                                      }
                                      else {
                                        var classnamejumlah = "colorred font-15";
                                      }
                                      return (
                                        <div key={i} className='col-lg-12 mb-1'>
                                          <div className='list cursorpointer d-flex justify-content-between align-items-center' onClick={(e) => autoShowPopupViewTransaksi(e.currentTarget.id)} id={data.id_transaksi}>
                                            <div className="textdecorationnone color2">
                                              <h5>{data.transaksi_kategori_judul}</h5>
                                              <p>{transaksitanggal}</p>
                                            </div>
                                            <div>
                                              <p className={classnamejumlah}>{rupiah2(data.transaksi_jumlah)}</p>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                                </>
                              )
                            }
                            {
                              jumlahdatatransaksiterakhir <= 0 && (
                                <div className='alert alert-info d-block w-100 text-center'>
                                  Belum ada Transaksi
                                </div>
                              )
                            }
                          </div>
                          <div className='body' style={{paddingTop:"0px"}}>
                            <NavLink to={linktopagedompetku} className="font-medium color1 textdecorationnone font-15">Buka Aplikasi DompetKu</NavLink>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }

                
                <div className='col-lg-6 mb-4 colsharefiles'>
                  <div className='box'>
                    <div className='header' style={{padding:"10px"}}>
                      <div className='d-flex justify-content-between align-items-center'>
                        <NavLink to={linktopagesharefiles} className='textdecorationnone btntitleapps'>
                          <img src={logosharefiles} style={{width:"155px"}} />
                        </NavLink>
                        <span className='color1 btnaddapps' style={{fontSize:"20px",cursor:"pointer"}} onClick={autoShowPopupAddShareFile}>
                          <i className='fa fa-plus'></i>
                        </span>
                      </div>
                    </div>
                    <div className='body'>
                      {
                        jumlahdatafilesaya > 0 && (
                          <>
                          <div className='row list6tugasku'>
                            {
                              datafilesaya.map((data, i) => {
                                var sharefiles_kapasitas = (data.sharefiles_kapasitas / 100000).toFixed(1);
                                if(statusweb=="development") {
                                  var baseurlsharefiles = baseurl;
                                }
                                else {
                                  var baseurlsharefiles = "https://sharefiles.riplabs.id";
                                }
                                var linktofile = baseurlsharefiles+"file/"+data.id_sharefiles+"/";
                                return (
                                  <div key={i} className='col-lg-12 mb-1'>
                                    <a href={linktofile} className='textdecorationnone list cursorpointer d-flex justify-content-between align-items-center'>
                                      <div className="textdecorationnone color2">
                                        <h5>{data.sharefiles_judul}</h5>
                                        <p>{sharefiles_kapasitas}</p>
                                      </div>
                                    </a>
                                  </div>
                                )
                              })
                            }
                          </div>
                          </>
                        )
                      }
                      {
                        jumlahdatafilesaya <= 0 && (
                          <div className='alert alert-info d-block w-100 text-center'>
                            Belum ada File
                          </div>
                        )
                      }
                    </div>
                    <div className='body' style={{paddingTop:"0px"}}>
                      <NavLink to={linktopagesharefiles} className="font-medium color1 textdecorationnone font-15">Buka Aplikasi ShareFiles</NavLink>
                    </div>
                  </div>
                </div>


                {/* {
                  loaddataceritaku6 > 0 && (
                    <>
                      <div className='col-lg-6 mb-4'>
                        <div className='box'>
                          <div className='header' style={{padding:"10px"}}>
                            <div className='d-flex justify-content-between align-items-center'>
                              <NavLink to={linktopageceritaku} className='textdecorationnone btntitleapps'>
                                <img src={logoceritaku} style={{width:"140px"}} />
                              </NavLink>
                              <span className='color1 btnaddapps' style={{fontSize:"20px",cursor:"pointer"}} onClick={autoShowPopupAddCeritaKu}>
                                <i className='fa fa-plus'></i>
                              </span>
                            </div>
                          </div>
                          <div className='body'>
                            {
                              jumlahceritaku6 > 0 && (
                                <div className='row list6ceritaku list6tugasku'>
                                  {
                                    dataceritaku6.map((data, i) => {
                                      var ceritakuisi = data.ceritaku_isi.substr(0,100)+'...';
                                      var idlist = data.id_ceritaku+"|"+data.id_ceritaku_projek;
                                      return (
                                        <div key={i} className='col-lg-6 mb-1'>
                                          <div className='list' onClick={(e) => autoShowPopupViewCeritaKu(e.currentTarget.id)} id={idlist} style={{cursor:"pointer"}}>
                                            <span className="textdecorationnone color2">
                                              <h5 title={data.ceritaku_judul}>{data.ceritaku_judul}</h5>
                                              <p title={ceritakuisi}>{ceritakuisi}...</p>
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              )
                            }
                            {
                              jumlahceritaku6 <= 0 && (
                                <div className='alert alert-info d-block w-100 text-center'>
                                  Belum ada Cerita
                                </div>
                              )
                            }
                          </div>
                          <div className='body' style={{paddingTop:"0px"}}>
                            <NavLink to={linktopageceritaku} className="font-medium color1 textdecorationnone font-15">Buka Aplikasi CeritaKu</NavLink>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                } */}

                {
                  loaddatacatatanku6 > 0 && loaddatatugasku6 > 0 && (
                    <>
                      <div className='col-lg-12 mb-4'>
                        <div className='box d-flex align-items-center text-center justify-content-center'>
                          <div className='body'><h5>Coming Soon</h5></div>
                        </div>
                        <button type="button" className="button-mini showmodalintroutama" data-bs-toggle="modal" data-bs-target="#modalintroutama"></button>
                      </div>
                    </>
                  )
                }

              </div>
        </div>
        
        {
          loaddatacatatanku6 > 0 && loaddatatugasku6 > 0 && (
            <>
              <div className='boxdonasiriplabs'>
                <p>Merasa terbantu oleh RipLabs? Donasi untuk RipLabs, <a target="_blank" href="https://saweria.co/arifsiddikm" className='textdecorationnone color4'>klik di sini</a>.</p>
              </div>
            </>
          )
        }

      </div>
      {
        loadsaransaya <= 0 && (
          <>
            {/* Lapor Bug & Ulasan */}
            <button type="button" className="btn btn-primary btnlaporbugulasan" data-bs-toggle="modal" data-bs-target="#laporbugulasan">
              Lapor Bug & Ulasan
            </button>
            <div className="modal fade" id="laporbugulasan" tabindex="-1" aria-labelledby="laporbugulasanLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-40">
                <div className="modal-content">
                  <form method='post' id="formlaporbugulasan" onSubmit={formlaporbugulasan}>
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="laporbugulasanLabel">Lapor Bug & Ulasan</h1>
                    <button type="button" className="btn-close close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    Silahkan lapor bug yang kamu temukan, serta kamu bisa memberikan Saran/Ide/Masukkan/Kritik tentang Website/Aplikasi RipLabs pada formulir di bawah ini, terima kasih <i className='fa fa-smile'></i>
                    <br/>
                    <textarea required rows="7" className='mt-3 mb-1 form-control' id='saran_isi'></textarea>
                    <span className='font-13 text-danger'><i className='fa fa-info-circle'></i> Maksimum mengirim laporan 1x dalam seminggu.</span>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" id='submit' className="btn btn-primary">Kirim</button>
                  </div>
                  </form>
                </div>
              </div>
            </div>
            {/* End Lapor Bug & Ulasan */}

            <div className="modal fade modalintro" id="modalintroutama" tabindex="-1" aria-labelledby="modalintroutamaLabel" data-bs-backdrop="static" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="modalintroutamaLabel">Kenalan Dulu Yuk!</h1>
                    <button type="button" className="btn-close close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <p>
                      <h5><span className='font-semibold color1'>Rip</span><span className='font-semibold color2'>Labs</span></h5> merupakan Platform Online yang menyediakan Aplikasi Digital yang bisa kamu gunakan secara bebas. <br/> RipLabs juga menyediakan Jasa dan Produk Digital.
                    </p>
                    <h4 className='my-4 font-semibold color-2'>Aplikasi Difiturkan</h4>

                    <div className='menutogglepopupintro' target="boxintro1">
                      <h5 className='m-0'><span className='font-semibold color1'>Catatan</span><span className='font-semibold color2'>Ku</span></h5>
                      <i className='fa fa-angle-down'></i>
                    </div>
                    <div className='boxintro boxintro1 show'>
                      <p>
                        Sebuah Aplikasi Catatan Online yang sangat ringan ini bisa kamu gunakan secara gratis di RipLabs.
                      </p>
                      <p>
                        <span className='font-medium font-18'>Fitur</span><br/>
                        <ul>
                          <li>Membuat & mengedit Catatan</li>
                          <li>Membuat & mengedit Kategori Catatan</li>
                        </ul>
                      </p>
                      <p>
                        <span className='font-medium font-18'>Cara Penggunaan</span><br/>
                        <ul>
                          <li>Masuk laman <NavLink className="textdecorationnone" to="/catatanku/">catatanku.riplabs.id</NavLink></li>
                          <li>Klik tombol + Nyatet untuk membuat Catatan baru, lalu pilih Kategori, masukkan Judul dan Isi Catatan</li>
                          <li>Jika sudah, klik daftar Catatan tertentu yang tertera, untuk menampilkan popup Edit/Hapus Catatan</li>
                          <li>Untuk menambahkan Kategori Catatan baru, bisa membuka menu 'Edit Kategori' pada menu sidebar sebelah kiri bawah pada tampilan Desktop, atau bisa membuat menu 'Kategori' di pojok kanan atas, lalu pilih menu 'Edit Kategori' pada tampilan Mobile</li>
                          <li>Jika sudah, silahkan pilih menu Kategori yang ingin dibuka</li>
                          <li>Untuk menu 'Sampah' merupakan daftar Catatan yang sudah dihapus, dan akan hilang selama 7 hari secara otomatis, dan bisa juga dikosongkan secara manual</li>
                        </ul>
                      </p>
                    </div>

                    <div className='menutogglepopupintro' target="boxintro2">
                      <h5 className='m-0'><span className='font-semibold color1'>Tugas</span><span className='font-semibold color2'>Ku</span></h5>
                      <i className='fa fa-angle-down'></i>
                    </div>
                    <div className='boxintro boxintro2'>
                      <p>
                        Sebuah Aplikasi Catatan Tugas Online yang sangat ringan ini bisa kamu gunakan secara gratis di RipLabs.
                      </p>
                      <p>
                        <span className='font-medium font-18'>Fitur</span><br/>
                        <ul>
                          <li>Membuat & mengedit Tugas</li>
                          <li>Membuat & mengedit Kategori Tugas</li>
                          <li>Membagikan Projek Tugas kepada teman/partner kamu</li>
                        </ul>
                      </p>
                      <p>
                        <span className='font-medium font-18'>Cara Penggunaan</span><br/>
                        <ul>
                          <li>Masuk laman <NavLink className="textdecorationnone" to="/tugasku/">tugasku.riplabs.id</NavLink></li>
                          <li>Klik tombol + Nugas untuk membuat Tugas baru, lalu masukkan Judul dan Deskripsi Tugas</li>
                          <li>Jika sudah, klik daftar Tugas tertentu yang tertera, untuk menampilkan popup Edit/Hapus Tugas</li>
                          <li>Selanjutnya, kamu bisa menyelesaikan Tugas dengan cara mengklik tombol Ceklis pada sebelah kiri Judul Tugas</li>
                          <li>Lalu bisa melihat Tugas Terselesaikan dengan mengklik tombol 'Lihat Tugas Terselesaikan' pada bagian bawah daftar Tugas</li>
                          <li>Kamu juga bisa mengembalikan Tugas dengan cara mengklik tombol Reload pada sebelah kiri Judul Tugas Terselesaikan</li>
                          <li>Untuk menambahkan Kategori/Projek Tugas baru, bisa membuka menu 'Edit Projek Tugas' pada menu sidebar sebelah kiri bawah pada tampilan Desktop, atau bisa membuat menu 'Kategori' di pojok kanan atas, lalu pilih menu 'Edit Projek Tugas' pada tampilan Mobile</li>
                          <li>Jika sudah, silahkan pilih menu Kategori yang ingin dibuka</li>
                          <li>Lalu yang tidak kalah penting, kamu juga bisa membagikan Projek Tugas ke teman, partner dan lainnya, dengan cara mengklik tombol Bagikan pada sebelah kanan Judul Projek Tugas bagian atas, yang dimana akan menampilkan popup Bagikan Tugas, bisa membagikannya dengan cara memasukkan Email Tujuan, serta mengatur Hak Akses Full atau Hanya Baca, lalu klik Bagikan, dan kamu juga bisa mengedit/menghapus List Email Tujuan</li>
                        </ul>
                      </p>
                    </div>

                    <div className='menutogglepopupintro' target="boxintro3">
                      <h5 className='m-0'><span className='font-semibold color1'>Dompet</span><span className='font-semibold color2'>Ku</span></h5>
                      <i className='fa fa-angle-down'></i>
                    </div>
                    <div className='boxintro boxintro3'>
                      <p>
                        Sebuah Aplikasi Manajemen Keuangan yang sangat ringan ini bisa kamu gunakan secara gratis di RipLabs.
                      </p>
                      <p>
                        <span className='font-medium font-18'>Fitur</span><br/>
                        <ul>
                          <li>Menambah & mengedit Transaksi dan saldo Dompet kamu</li>
                          <li>Membuat & mengedit Kategori Transaksi</li>
                          <li>Membuat & mengedit Daftar Target Belanja kamu</li>
                        </ul>
                      </p>
                      <p>
                        <span className='font-medium font-18'>Cara Penggunaan</span><br/>
                        <ul>
                          <li>Masuk laman <NavLink className="textdecorationnone" to="/dompetku/">dompetku.riplabs.id</NavLink></li>
                          <li>Kamu akan memasuki laman awal DompetKu yang berisikan Saldo Dompet, daftar Transaksi terakhir, dan laporan keuangan.</li>
                          <li>Klik tombol + untuk menambah Transaksi baru, lalu masukkan jumlah, kategori, tanggal beserta catatan transaksi jika perlu</li>
                          <li>Pada popup Transaksi baru, kamu juga bisa mengelola Kategori Transaksi</li>
                          <li>Selanjutnya kamu bisa memasuki laman daftar semua Transaksi <NavLink className="textdecorationnone" to="/dompetku/transaksi/">di sini</NavLink></li>
                          <li>Terdapat daftar Periode Transaksi yang bisa kamu pilih</li>
                          <li>Klik daftar Transaksi tertentu yang tertera untuk menampilkan popup Edit/Hapus Transaksi kamu</li>
                        </ul>
                      </p>
                      <p>
                        <span className='font-medium font-18'>Menu Lainnya</span><br/>
                        <ul>
                          <li>Terdapat menu lainnya pada DompetKu, bisa dilihat pada laman laman <NavLink className="textdecorationnone" to="/dompetku/lainnya/">di sini</NavLink></li>
                          <li>Yang pertama, kamu bisa melakukan Kalkulasi Saldo kamu saat ini dengan memilih menu 'Kalkulasikan Saldo'</li>
                          <li>Yang kedua, terdapat menu Daftar Target Belanja, yang dimana bisa kamu tambahkan serta melakukan ceklis target, untuk lebih lanjut bisa buka menu <NavLink to="/dompetku/targetbelanja/">Daftar Target Belanja</NavLink></li>
                          <li>Klik tombol + untuk menambah Target Belanja baru, lalu isikan formulir yang tersedia</li>
                          <li>Klik daftar Target Belanja tertentu yang tertera untuk menampilkan popup Edit/Hapus Target Belanja kamu</li>
                          <li>Kamu juga bisa mengubah status Target Belanja kamu menjadi selesai/proses dengan cara mengklik tombol Ceklis/Undo pada sebelah kanan daftar Target Belanja</li>
                          <li>Kamu bisa mencari data pada kolom pencarian, serta bisa melakukan filter status data melalui selection</li>
                          <li>Untuk menu lainnya, masih dalam pengembangan</li>
                        </ul>
                      </p>
                    </div>

                    <div className='menutogglepopupintro' target="boxintro4">
                      <h5 className='m-0'><span className='font-semibold color1'>Share</span><span className='font-semibold color2'>Files</span></h5>
                      <i className='fa fa-angle-down'></i>
                    </div>
                    <div className='boxintro boxintro4'>
                      <p>
                        Sebuah Aplikasi Penyimpanan dan Berbagi Dokumen yang sangat ringan ini bisa kamu gunakan secara gratis di RipLabs.
                        Untuk saat ini, ShareFiles RipLabs versi gratis ada limit sebesar 100Mb per minggu, dan file akan otomatis terhapus selama 7hari berlalu.
                      </p>
                      <p>
                        <span className='font-medium font-18'>Fitur</span><br/>
                        <ul>
                          <li>Mengunggah Dokumen</li>
                          <li>Membagikan Link Unduhan Dokumen kepada publik</li>
                        </ul>
                      </p>
                      <p>
                        <span className='font-medium font-18'>Cara Penggunaan</span><br/>
                        <ul>
                          <li>Masuk laman <NavLink className="textdecorationnone" to="/sharefiles/">sharefiles.riplabs.id</NavLink></li>
                          <li>Klik tombol 'UPLOAD FILE' untuk mengunggah Dokumen baru, lalu klik tombol Upload</li>
                          <li>Jika sudah, Dokumen akan muncul pada table daftar Dokumen di bawah, serta limit Pengunggahan Dokumen juga sudah berubah</li>
                          <li>Klik tombol Pesawat pada table untuk mengunjungi laman penggungahan dokumen</li>
                          <li>Klik tombol Copy pada table untuk menyalin link penggungahan dokumen</li>
                          <li>Klik tombol Hapus pada table untuk menghapus dokumen</li>
                        </ul>
                      </p>
                    </div>  

                  </div>
                  {/* <div className="modal-footer"> */}
                  <div className="modal-footer d-flex justify-content-between">
                    <div>
                      <div className="form-check">
                        <input onClick={TutupPopupIntro} className="form-check-input cursorpointer" type="checkbox" name="flexRadioDefault" value="" id="tutupjangantampilkanintroutama" />
                        <label onClick={TutupPopupIntro} className="form-check-label cursorpointer" for="tutupjangantampilkanintroutama">Tutup dan jangan tampilkan lagi</label>
                      </div>
                    </div>
                    <div>&nbsp;</div>
                    {/* <button type="button" className="btn btn-danger close" data-bs-dismiss="modal">Close</button> */}
                  </div>
                </div>
              </div>
            </div>

          </>
        )
      }
      
    </>
  )
}

export default Dashboard;