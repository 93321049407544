import { appkey, restapilink, baseurl, yearnow, baseurlassets, basecolor1, basecolorred } from './Autoload'; 
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import SubHeader from './SubHeader';
import ReactPaginate from 'react-paginate';
const $ = require("jquery");   
const Swal = require('sweetalert2');

function rupiah(angka) {  
  var bilangan = angka;         
  var	number_string = bilangan.toString(),  
      sisa 	= number_string.length % 3,
      rupiah 	= number_string.substr(0, sisa),
      ribuan 	= number_string.substr(sisa).match(/\d{3}/g);
  if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
  } 
  return rupiah;
}
function ucwords (str) {
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
      return $1.toUpperCase();
  });
} 
function strtolower (str) {
  return (str+'').toLowerCase();
} 
function ucfirst (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function Portofolio(props) {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  // const [ceksesilogin, setceksesilogin] = useState(0);
  const [listportofolioriplabs, setlistportofolioriplabs] = useState([]);
  const [jumlahportofolioriplabs, setjumlahportofolioriplabs] = useState(0);
  const [kategoriportofolioriplabs, setkategoriportofolioriplabs] = useState([]);
  const [jumlahkategoriportofolioriplabs, setjumlahkategoriportofolioriplabs] = useState(0);
  const [judulPencarian, setJudulPencarian] = useState('');

  const [keyword, setkeyword] = useState('');
  const [kategori, setkategori] = useState('All');
  // getListPortofolioRipLabs
  const getListPortofolioRipLabs = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response3 = await axios.post(restapilink+"api/data/listportofolioriplabs", {
        key:appkey,
        keyword:keyword,
        kategori:kategori
      });
      setlistportofolioriplabs(response3.data.data);
      setjumlahportofolioriplabs(response3.data.jumlah);
      setJudulPencarian(response3.data.judul);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {console.log(e.message);}
  }
  const getKategoriPortofolioRipLabs = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response3 = await axios.post(restapilink+"api/data/kategoriportofolioriplabs", {
        key:appkey
      });
      setkategoriportofolioriplabs(response3.data.data);
      setjumlahkategoriportofolioriplabs(response3.data.jumlah);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {console.log(e.message);}
  }
  const searchHandler = async (e) => {
    setkeyword(e);
  }
  const kategoriHandler = async (e) => {
    setkategori(e);
  }
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 9;
  const handlePageClick = (e) => {
    const newOffset = (e.selected * itemsPerPage) % listportofolioriplabs.length;
    setItemOffset(newOffset);
  }

  useEffect(() => {
    getListPortofolioRipLabs();
    getKategoriPortofolioRipLabs();

    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(listportofolioriplabs.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(listportofolioriplabs.length / itemsPerPage));

    var kategori2 = kategori.toLowerCase().replaceAll(" ","");
    $(".listkategorimenu").removeClass(" active ");
    $(".listkategorimenu"+kategori2).addClass(" active ");

  }, [ jumlahportofolioriplabs, itemOffset, itemsPerPage, keyword, kategori ]);

    return(
      <>
      <title>Portofolio RipLabs | RipLabs Indonesia</title>
      <meta name="title" content="Portofolio RipLabs | RipLabs Indonesia" />
      <meta itemProp="name" content="Portofolio RipLabs | RipLabs Indonesia" />
      <meta property="og:title" content="Portofolio RipLabs | RipLabs Indonesia" />
      <meta name="twitter:title" content="Portofolio RipLabs | RipLabs Indonesia" />
      <div className='bodypage pageportofolio animated fadeIn'>
        <SubHeader title="Portofolio" desc="Beberapa Portofolio Projek dari RipLabs yang bisa kamu lihat." /> 
        <section className='riplabsapps riplabsportofolio' style={{marginBottom:"60px"}}>
          <div className='container'>
            {/* Search Data */}
            <input type="search" className='boxdompetku1 mb-0' placeholder='Cari judul atau kategori portofolio...' onChange={(e) => searchHandler(e.target.value)} />
            <br/>
            <div className='listkategoridata'>
            <span className="listkategorimenu listkategorimenuall" onClick={(e) => kategoriHandler(e.currentTarget.id)} id="All">All</span>
            {
              Array.isArray(kategoriportofolioriplabs) ?
                kategoriportofolioriplabs.map((data, i) => {
                  var classnamelist = "listkategorimenu listkategorimenu"+data.portofolio_kategori.toLowerCase().replaceAll(" ","");
                  return (
                    <span key={i} className={classnamelist} id={data.portofolio_kategori} onClick={(e) => kategoriHandler(e.currentTarget.id)}>{data.portofolio_kategori}</span>
                  )
                })
              : ""
            }
            </div>
            <hr/>
            <h4 className='font-semibold'>{judulPencarian}</h4>
            {
              jumlahportofolioriplabs > 0 && (
                <>
                  <div className="row justify-content-center rowcard1 rowcardnoborder card-groupp">
                    {
                      Array.isArray(currentItems) ?
                      currentItems.map((data, i) => {
                        let listportofolioriplabsimg = data.portofolio_thumbnail ? baseurlassets+'images/portofolio/thumbnail/' + data.portofolio_thumbnail : '';
                        let portofoliopreview = data.portofolio_preview == "" ? "javascript:void(0);" : data.portofolio_preview;
                        let portofoliotarget = data.portofolio_preview == "" ? "" : "_blank";
                        return (
                          <div key={i} className="card col-lg-4 p-3 mb-3">
                            <div className='image'>
                              <img src={listportofolioriplabsimg} className="card-img-top" alt={listportofolioriplabsimg} />
                            </div>
                            <div className="card-body">
                              <h5 className="card-title mb-1">{data.portofolio_judul}</h5>
                              <div className='color1 font-medium mb-3'>{data.portofolio_kategori}</div>
                              <p className="card-text">{data.portofolio_deskripsi}</p>
                            </div>
                            <div className="card-footer">
                              <a target={portofoliotarget} href={portofoliopreview} className="textdecorationnone color1 font-medium d-block mt-3">Preview</a>
                            </div>
                          </div>
                        )
                      })
                      : ""
                    }
                  </div>
                  <br/>
                  <br/>
                  <center>
                    <ReactPaginate 
                      breakLabel="..."
                      nextLabel="Next"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={2}
                      marginPagesDisplayed={1}
                      pageCount={pageCount}
                      previousLabel="Previous"
                      renderOnZeroPageCount={null}
                      containerClassName="pagination m-auto d-inline-flex"
                      pageClassName="page-item"
                      breakClassName='page-item'
                      breakLinkClassName='page-link'
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      activeClassName="active"
                      activeLinkClassName="active"
                    />
                  </center>
                </>
              )
            }
            {
              jumlahportofolioriplabs <= 0 && (
                <div className='alert alert-danger d-block text-center'>Data tidak ditemukan</div>
              )
            }
          </div>
        </section>
      </div>
      </>
    )
}

export default Portofolio;