import * as autoload from './Autoload'; 
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import SubHeader from './SubHeader';
const $ = require("jquery");   
const Swal = require('sweetalert2');

function rupiah(angka) {  
  var bilangan = angka;         
  var	number_string = bilangan.toString(),  
      sisa 	= number_string.length % 3,
      rupiah 	= number_string.substr(0, sisa),
      ribuan 	= number_string.substr(sisa).match(/\d{3}/g);
  if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
  } 
  return rupiah;
}
function ucwords (str) {
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
      return $1.toUpperCase();
  });
} 
function strtolower (str) {
  return (str+'').toLowerCase();
} 
function ucfirst (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function Home(props) {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [ceksesilogin, setceksesilogin] = useState(0);
  const [listaplikasiriplabs, setlistaplikasiriplabs] = useState([]);
  const [jumlahaplikasiriplabs, setjumlahaplikasiriplabs] = useState(0);
  const [listportofolioriplabs, setlistportofolioriplabs] = useState([]);
  const [jumlahportofolioriplabs, setjumlahportofolioriplabs] = useState(0);
  const [listjasariplabs, setlistjasariplabs] = useState([]);
  const [jumlahjasariplabs, setjumlahjasariplabs] = useState(0);
  const [listprodukriplabs, setlistprodukriplabs] = useState([]);
  const [jumlahprodukriplabs, setjumlahprodukriplabs] = useState(0);
  // getListAplikasiRipLabs
  const getListAplikasiRipLabs = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response1 = await axios.post(autoload.restapilink+"api/data/listaplikasiriplabs3", {
        key:autoload.appkey
      });
      setlistaplikasiriplabs(response1.data.data);
      setjumlahaplikasiriplabs(response1.data.jumlah);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {console.log(e.message);}
  }
  // getListPortofolioRipLabs
  const getListPortofolioRipLabs = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response3 = await axios.post(autoload.restapilink+"api/data/listportofolioriplabs3", {
        key:autoload.appkey
      });
      setlistportofolioriplabs(response3.data.data);
      setjumlahportofolioriplabs(response3.data.jumlah);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {console.log(e.message);}
  }
  // getListJasaRipLabs
  const getListJasaRipLabs = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response2 = await axios.post(autoload.restapilink+"api/data/listjasariplabs3", {
        key:autoload.appkey
      });
      setlistjasariplabs(response2.data.data);
      setjumlahjasariplabs(response2.data.jumlah);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {console.log(e.message);}
  }
  // getListProdukRipLabs
  const getListProdukRipLabs = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response3 = await axios.post(autoload.restapilink+"api/data/listprodukriplabs3", {
        key:autoload.appkey
      });
      setlistprodukriplabs(response3.data.data);
      setjumlahprodukriplabs(response3.data.jumlah);
      $(".loading_riplabs").css('display','none');
    }
    catch(e) {console.log(e.message);}
  }

  // const [contactarif, setcontactarif] = useState([]);
  // getcontactarif
  // const getcontactarif = async () => {
  //   try {
  //     let response = await axios.get(autoload.restapilink+"api/data/contactarif");
  //     setcontactarif(response.data);
  //   }
  //   catch(e) {console.log(e.message);}
  // }

  const[nama, setSessionNama] = useState('');
  const fetchData = async () => {
    await axios.post(autoload.restapilink+"api/auth/ceklogin", {
      key:autoload.appkey,
      token:token
    }).then((response) => {
      if(response.data.ceklogin == 0) {
        localStorage.removeItem('token');
      }
      setceksesilogin(response.data.ceklogin)
      setSessionNama(response.data.nama);
    });
  }

  const classnameonlick = async(e) => {
    if(ceksesilogin<=0) {
      Swal.fire({
        title: 'Oops...',
        html: 'Untuk membuka Aplikasi, kamu harus Login terlebih dahulu <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: autoload.basecolor1,
        cancelButtonColor: autoload.basecolorred,
        confirmButtonText: 'Login Sekarang',
        cancelButtonText: 'Nanti Saja'
      }).then((result) => {
        if (result.value) {
          navigate('/login/')
        }
      });
    }
  }

  useEffect(() => {
    fetchData();
    getListAplikasiRipLabs(); 
    getListPortofolioRipLabs()
    getListJasaRipLabs();
    getListProdukRipLabs();

    // setTimeout(function(){
    //   $(".menu").removeClass(' active ');
    //   $(".menu-home").addClass(' active ');
    //   $(".menu-mobile").removeClass(' active ');
    //   $(".menu-mobile-home").addClass(' active ');
    // }, 100);
  }, [ jumlahaplikasiriplabs, jumlahportofolioriplabs, jumlahjasariplabs, jumlahprodukriplabs, ceksesilogin ]); 
  
  return(
    <>
      <title>RipLabs Indonesia: Platform Penyedia Aplikasi Gratis, Jasa, dan Produk Digital.</title>
      <meta name="title" content="RipLabs Indonesia: Platform Penyedia Aplikasi Gratis, Jasa, dan Produk Digital." />
      <meta itemProp="name" content="RipLabs Indonesia: Platform Penyedia Aplikasi Gratis, Jasa, dan Produk Digital." />
      <meta property="og:title" content="RipLabs Indonesia: Platform Penyedia Aplikasi Gratis, Jasa, dan Produk Digital." />
      <meta name="twitter:title" content="RipLabs Indonesia: Platform Penyedia Aplikasi Gratis, Jasa, dan Produk Digital." />
      <div className="bodypage pagehome animated fadeIn">
        <div className='hero'>
          <div className='container'>
            <h1><span span style={{color:"var(--basecolor1)"}}>Hallo</span>, Selamat Datang {nama}</h1>
            <p>RipLabs merupakan Platform Online yang menyediakan Aplikasi Digital yang bisa kamu gunakan secara bebas. <br/> RipLabs juga menyediakan Jasa dan Produk Digital.</p>
            {
              !token && (
                <>
                  <NavLink to="/login/" className='btn_hero_1'>Coba Sekarang&nbsp;<i className='fa fa-angle-right'></i></NavLink>
                  <NavLink to="/aplikasi/" className='btn_hero_2'>Lihat Aplikasi&nbsp;<i className='fa fa-angle-right'></i></NavLink>
                </>
                )
            }
            {
              token && (
                <>
                  <NavLink to="/dashboard/" className='btn_hero_1'>Lihat Aplikasi&nbsp;<i className='fa fa-angle-right'></i></NavLink>
                  <NavLink to="/produk/" className='btn_hero_2'>Lihat Produk&nbsp;<i className='fa fa-angle-right'></i></NavLink>
                </>
                )
            }
          </div>
        </div>      
        {
          jumlahaplikasiriplabs > 0 && (
            <>
            <section className='riplabsapps2'>
              <div className='container'>
                <h3 className='riplabs-h3'>Aplikasi RipLabs</h3>
                <p style={{color:"var(--basecolor6)"}} className="mt-3">Beberapa Aplikasi RipLabs yang bisa kamu gunakan secara gratis.</p>
                <div className="row justify-content-center rowcardapps rowcard1 rowcardnoborder card-groupp">
                  {
                    Array.isArray(listaplikasiriplabs) ?
                    listaplikasiriplabs.map((data, i) => {
                      let listaplikasiriplabsimg = data.aplikasi_thumbnail ? autoload.baseurlassets+'images/aplikasi/thumbnail/' + data.aplikasi_thumbnail : '';
                      // if(ceksesilogin>0) {
                        var linkaplikasipreview = '/'+data.aplikasi_preview;
                      // }
                      // else {
                      //   var linkaplikasipreview = "/";
                      // }
                      return (
                        <div key={i} className="card col-lg-4 p-3 mb-3">
                          <NavLink to={linkaplikasipreview} className="textdecorationnone">
                          <div className='image'>
                            <img src={listaplikasiriplabsimg} className="card-img-top" alt={listaplikasiriplabsimg} />
                          </div>
                          <div className="card-body textdecorationnone">
                            <h5 className="card-title textdecorationnone mb-1">{data.aplikasi_judul}</h5>
                            <div className='color1 textdecorationnone font-medium mb-3'>{data.aplikasi_kategori}</div>
                            <p className="card-text textdecorationnone color2">{data.aplikasi_deskripsi}</p>
                          </div>
                          </NavLink>
                          <div className="card-footer">
                            <div className='row'>
                              <div className='col-6'>
                                <NavLink to={linkaplikasipreview} className="color1 d-block mt-3 font-medium textdecorationnone">Buka Aplikasi</NavLink>
                              </div>
                              <div className='col-6'>
                                <a href={data.aplikasi_playstore} target="_blank" style={{textAlign:"right"}} className="textdecorationnone color1 font-medium d-block mt-3">PlayStore</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                    : ""
                  }
                </div>
                <NavLink to="/aplikasi/" className="color1 d-block mt-4 font-medium textdecorationnone">Lihat Aplikasi RipLabs Lainnya <i className='fa fa-angle-right'></i></NavLink>
              </div>
            </section>
            </>
          )
        }
        {
          jumlahjasariplabs > 0 && (
            <>
            <section className='riplabsapps2 riplabsservices'>
              <div className='container'>
                <h3 className='riplabs-h3'>Jasa RipLabs</h3>
                <p style={{color:"var(--basecolor6)"}} className="mt-3">Beberapa Jasa RipLabs yang bisa kamu order.</p>
                <div className="row justify-content-center rowcard1 rowcardnoborder card-groupp">
                  {
                    Array.isArray(listjasariplabs) ?
                    listjasariplabs.map((data, i) => {
                      let listjasariplabsimg = data.jasa_thumbnail ? autoload.baseurlassets+'images/jasa/thumbnail/' + data.jasa_thumbnail : '';
                      return (
                        <div key={i} className="card col-lg-4 p-3 mb-3">
                          <div className='image'>
                            <img src={listjasariplabsimg} className="card-img-top" alt={listjasariplabsimg} />
                          </div>
                          <div className="card-body">
                            <h5 className="card-title mb-1">{data.jasa_judul}</h5>
                            <div className='color1 font-medium mb-3'>{data.jasa_kategori} | {data.jasa_digunakan}x Digunakan</div>
                            <p className="card-text">{data.jasa_deskripsi}</p>
                          </div>
                          <div className="card-footer">
                            <h5 className='estimasiharga d-block mt-3'>{data.jasa_harga}</h5>
                            <a target="_blank" href={data.jasa_cta_link} className="font-medium d-block mt-3 textdecorationnone color1">{data.jasa_cta_teks}</a>
                          </div>
                        </div>
                      )
                    })
                    : ""
                  }
                </div>
                <NavLink to="/jasa/" className="color1 d-block mt-4 font-medium textdecorationnone">Lihat Jasa RipLabs Lainnya <i className='fa fa-angle-right'></i></NavLink>
              </div>
            </section>
            </>
          )
        }
        {
          jumlahportofolioriplabs > 0 && (
            <>
              <section className='riplabsapps2 riplabsportofolio'>
                <div className='container'>
                  <h3 className='riplabs-h3'>Portofolio RipLabs</h3>
                  <p style={{color:"var(--basecolor6)"}} className="mt-3">Beberapa Portofolio Projek dari RipLabs yang bisa kamu lihat.</p>
                  <div className="row justify-content-center rowcard1 rowcardnoborder card-groupp">
                    {
                      Array.isArray(listportofolioriplabs) ?
                      listportofolioriplabs.map((data, i) => {
                        let listportofolioriplabsimg = data.portofolio_thumbnail ? autoload.baseurlassets+'images/portofolio/thumbnail/' + data.portofolio_thumbnail : '';
                        let portofoliopreview = data.portofolio_preview == "" ? "javascript:void(0);" : data.portofolio_preview;
                        let portofoliotarget = data.portofolio_preview == "" ? "" : "_blank";
                        return (
                          <div key={i} className="card col-lg-4 p-3 mb-3">
                            <div className='image'>
                              <img src={listportofolioriplabsimg} className="card-img-top" alt={listportofolioriplabsimg} />
                            </div>
                            <div className="card-body">
                              <h5 className="card-title mb-1">{data.portofolio_judul}</h5>
                              <div className='color1 font-medium mb-3'>{data.portofolio_kategori}</div>
                              <p className="card-text">{data.portofolio_deskripsi}</p>
                            </div>
                            <div className="card-footer">
                              <a target={portofoliotarget} href={portofoliopreview} className="textdecorationnone color1 font-medium d-block mt-3">Preview</a>
                            </div>
                          </div>
                        )
                      })
                      : ""
                    }
                  </div>
                </div>
              </section>
            </>
          )
        }
        {
          jumlahprodukriplabs > 0 && (
            <>
              <section className='riplabsapps2 riplabsproducts riplabsportofolio'>
                <div className='container'>
                  <h3 className='riplabs-h3'>Produk RipLabs</h3>
                  <p style={{color:"var(--basecolor6)"}} className="mt-3">Beberapa Produk RipLabs yang bisa kamu order.</p>
                  <div className="row justify-content-center rowcard1 rowcardnoborder card-groupp">
                    {
                      Array.isArray(listprodukriplabs) ?
                      listprodukriplabs.map((data, i) => {
                        let listprodukriplabsimg = data.produk_thumbnail ? autoload.baseurlassets+'images/produk/thumbnail/' + data.produk_thumbnail : '';
                        let linkpesanproduk = "https://api.whatsapp.com/send?phone=6289514392694&text=Hallo min RipLabs, saya mau pesan produk " + data.produk_judul + " apakah ready min? ";
                        return (
                          <div key={i} className="card col-lg-4 p-3 mb-3">
                            <div className='image'>
                              <img src={listprodukriplabsimg} className="card-img-top" alt={listprodukriplabsimg} />
                            </div>
                            <div className="card-body">
                              <h5 className="card-title mb-1">{data.produk_judul}</h5>
                              <div className='color1 font-medium mb-3'>{data.produk_kategori} | {data.produk_terjual} Terjual</div>
                              <p className="card-text">{data.produk_deskripsi}</p>
                            </div>
                            <div className="card-footer">
                              <h5 className='estimasiharga2 d-block mt-3'>Rp. {rupiah(data.produk_harga)}</h5>
                              <div className='row'>
                                <div className='col-6'>
                                  <a target="_blank" href={data.produk_preview} className="textdecorationnone color1 font-medium d-block mt-3">Preview</a>
                                </div>
                                <div className='col-6'>
                                  <a href={linkpesanproduk} target="_blank" style={{textAlign:"right"}} className="textdecorationnone color1 font-medium d-block mt-3">Pesan Produk</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                      : ""
                    }
                  </div>
                  <NavLink to="/produk/" className="color1 d-block mt-4 mb-3 font-medium textdecorationnone">Lihat Produk RipLabs Lainnya <i className='fa fa-angle-right'></i></NavLink>
                  <p style={{color:"var(--basecolor6)"}}>Ingin mengunggah Produk di RipLabs? <a href='https://api.whatsapp.com?phone=6289514392694&text=' className='textdecorationnone color1 font-medium' target='_blank'>Hubungi Admin RipLabs</a>.</p>
                </div>
              </section>
            </>
          )
        }
        <section className='riplabspartners'>
          <div className='container'>
            <h2 className='font-semibold'>Partner RipLabs</h2>
            <br/>
            <div className='row justify-content-center'>  
              <div className='col-lg-1 col-4 my-3'>
                <a href='https://cvmilenial.com' target="_blank"><img src='https://assets.riplabs.id/images/partners/img-partner-cvmilenial.jpg' style={{borderRadius:"12px",width:"80px"}} /></a>
              </div>
              <div className='col-lg-1 col-4 my-3'>
                <a href='https://planningday.id' target="_blank"><img src='https://assets.riplabs.id/images/partners/img-partner-planningday.jpg' style={{borderRadius:"12px",width:"80px"}} /></a>
              </div>
              <div className='col-lg-1 col-4 my-3'>
                <a href='https://www.instagram.com/xvonsteam/' target="_blank"><img src='https://assets.riplabs.id/images/partners/img-partner-xvonsteam.jpg' style={{borderRadius:"12px",width:"80px"}} /></a>
              </div>
            </div>
            <br/>
            <span>Ingin berkolaborasi dengan RipLabs? <a href='https://api.whatsapp.com?phone=6289514392694&text=' className='font-medium textdecorationnone font-medium color4' target='_blank'>Hubungi Admin RipLabs</a>.</span>
          </div>
        </section>
      </div>
    </>
  )
}

export default Home;