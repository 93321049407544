import { appkey, restapilink, baseurl, yearnow, baseurlassets, basecolor1, basecolorred, appsetup } from './Autoload'; 
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
// import "../../node_modules/datatables.net-dt/js/dataTables.dataTables";
// import "../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css";
const $ = require("jquery");
const Swal = require('sweetalert2');

function Footer(props) {
    const page = window.location.href; 

    const getGeolocation = async () => {
        await axios.post("https://geolocation-db.com/json/").then((response) => {
          const ipv4 = response.data.IPv4;  
          axios.post(restapilink+"api/visitor/tambahvisitor", {
            key:appkey,
            ip:ipv4,
            page:page
          });
        });
    }
    
    useEffect(() => {
        getGeolocation();
        setTimeout(function(){
            $(".riplabspartners").css('display','block');
            $(".footer").css('display','block');
            $(".footer-copyright").css('display','block');
          }, 3000);
    }, [ page ]); 

    var logoriplabs = baseurlassets+"images/riplabs/Logo RipLabs.png";
    var imggoogleplay = baseurlassets+"images/get-it-on-google-play.png";

    return(
        <>
            <div className="footer">
                <div className="container">
                    <div className='row'>
                        <div className='mb-3 col-lg-3'>
                            <img src={logoriplabs} style={{width:"120px",marginBottom:"15px"}} />
                            <p className='font-15' style={{lineHeight:"25px"}}>
                                <span className='font-medium'>RipLabs</span> merupakan Platform Online yang menyediakan Aplikasi Digital yang bisa kamu gunakan secara bebas. RipLabs juga menyediakan Jasa dan Produk Digital.
                            </p>
                        </div>
                        <div className='mb-3 col-lg-2 col-6'>
                            <h5 className='font-semibold' style={{marginBottom:"32.5px"}}>Lainnya</h5>
                            <NavLink to='/tentang/' className='d-block font-15 mb-3 color2 textdecorationnone'>Tentang RipLabs</NavLink>
                            <a target="_blank" href='https://saweria.co/arifsiddikm' className='d-block font-15 mb-3 color2 textdecorationnone'>Donasi</a>
                            <NavLink to='/faqs/' className='d-block font-15 mb-3 color2 textdecorationnone'>FAQ's</NavLink>
                            <NavLink to='/kebijakan_privasi/' className='d-block font-15 mb-3 color2 textdecorationnone'>Kebijakan Privasi</NavLink>
                            <NavLink to='/syarat_ketentuan/' className='d-block font-15 mb-3 color2 textdecorationnone'>Syarat & Ketentuan</NavLink>
                        </div>
                        <div className='mb-3 col-lg-2 col-6'>
                            <h5 className='font-semibold' style={{marginBottom:"32.5px"}}>Aplikasi</h5>
                            <NavLink to='/catatanku/' className='d-block font-15 mb-3 color2 textdecorationnone'>CatatanKu</NavLink>
                            <NavLink to='/tugasku/' className='d-block font-15 mb-3 color2 textdecorationnone'>TugasKu</NavLink>
                            <NavLink to='/dompetku/' className='d-block font-15 mb-3 color2 textdecorationnone'>DompetKu</NavLink>
                            <NavLink to='/sharefiles/' className='d-block font-15 mb-3 color2 textdecorationnone'>ShareFiles</NavLink>
                            <NavLink to='/aplikasi/' className='d-block font-15 mb-3 color2 textdecorationnone'>Lainnya</NavLink>
                        </div>
                        <div className='mb-3 col-lg-2'>
                            <h5 className='font-semibold' style={{marginBottom:"32.5px"}}>Sosial</h5>
                            <a href='https://instagram.com/riplabs.id/' target="_blank" className='d-block font-15 mb-3 color2 textdecorationnone'>Instagram</a>
                            <a href='https://github.com/riplabsindonesia/' target="_blank" className='d-block font-15 mb-3 color2 textdecorationnone'>GitHub</a>
                            <a href='https://t.me/riplabsforum' target="_blank" className='d-block font-15 mb-3 color2 textdecorationnone'>Telegram</a>
                            <a href='mailto:riplabs.indonesia@gmail.com' target="_blank" className='d-block font-15 mb-3 color2 textdecorationnone'>Email</a>
                            <a href='https://wa.me/6289514392694' target="_blank" className='d-block font-15 mb-3 color2 textdecorationnone'>WhatsApp</a>
                        </div>
                        <div className='col-lg-2'>
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=app.riplabs.id"><img src={imggoogleplay} style={{width:"150px"}} /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-copyright'>
                © 2023 <span className='font-medium'>RipLabs</span> v1.0
            </div>
        </>
    )
}

export default Footer;