import { appkey, restapilink, baseurl, yearnow, baseurlassets, basecolor1, basecolorred } from './Autoload'; 
import axios from 'axios';
import React, { useEffect, useReducer, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import SubHeader from './SubHeader';
import Test from './Test';
const $ = require("jquery");   
const Swal = require('sweetalert2');

function Aplikasi(props) {
  // const [state, dispatch] = useReducer(x => x + 1, 0);

  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  const [ceksesilogin, setceksesilogin] = useState(0);
  const [listaplikasiriplabs, setlistaplikasiriplabs] = useState([]);
  const [jumlahaplikasiriplabs, setjumlahaplikasiriplabs] = useState(0);

  // getListAplikasiRipLabs
  const getListAplikasiRipLabs = async () => {
    $(".loading_riplabs").css('display','block');
    try {
      let response1 = await axios.post(restapilink+"api/data/listaplikasiriplabs", {
        key:appkey
      });
      setlistaplikasiriplabs(response1.data.data);
      setjumlahaplikasiriplabs(response1.data.jumlah);
      $(".loading_riplabs").css('display','none'); 
    }
    catch(e) {console.log(e.message);}
  }
  
  const fetchData = async () => {
    await axios.post(restapilink+"api/auth/ceklogin", {
      key:appkey,
      token:token
    }).then((response) => {
      setceksesilogin(response.data.ceklogin)
    });
  }

  const classnameonlick = async(e) => {
    if(ceksesilogin<=0) {
      Swal.fire({
        title: 'Oops...',
        html: 'Untuk membuka Aplikasi, kamu harus Login terlebih dahulu <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: basecolor1,
        cancelButtonColor: basecolorred,
        confirmButtonText: 'Login Sekarang',
        cancelButtonText: 'Nanti Saja'
      }).then((result) => {
        if (result.value) {
          navigate('/login/')
        }
      });
    }
  }

  // const refresh = (e) => {
  //   e.preventDefault();
  //   dispatch();
  // }
  // const refresh2 = (e) => {
  //   e.preventDefault();
  //   dispatch();
  // }

  useEffect(() => {
    fetchData();
    getListAplikasiRipLabs(); 

    // setTimeout(function(){
    //   $(".menu").removeClass(' active ');
    //   $(".menu-home").addClass(' active ');
    //   $(".menu-mobile").removeClass(' active ');
    //   $(".menu-mobile-home").addClass(' active ');
    // }, 100);
  }, [ jumlahaplikasiriplabs, ceksesilogin ]); 

  return(
    <>

    {/* <form method='POST' onSubmit={refresh}>
      <button type="submit">refresh1</button>
    </form>
    <form method='POST' onSubmit={refresh2}>
      <button type="submit">refresh2</button>
    </form> */}
    {/* <Test /> */}

      <div className='bodypage pageaplikasi animated fadeIn'>
        <title>Aplikasi RipLabs | RipLabs Indonesia</title>
        <meta name="title" content="Aplikasi RipLabs | RipLabs Indonesia" />
        <meta itemProp="name" content="Aplikasi RipLabs | RipLabs Indonesia" />
        <meta property="og:title" content="Aplikasi RipLabs | RipLabs Indonesia" />
        <meta name="twitter:title" content="Aplikasi RipLabs | RipLabs Indonesia" />
        <SubHeader title="Aplikasi" desc="Beberapa Aplikasi RipLabs yang bisa kamu gunakan secara gratis." />

          {
          jumlahaplikasiriplabs > 0 && (
            <>
            <section className='riplabsapps' style={{marginBottom:"60px"}}>
              <div className='container'>
                {/* <h3 className='riplabs-h3'>Aplikasi RipLabs</h3> */}
                {/* <p style={{color:"var(--basecolor6)"}} className="mt-3">Beberapa Aplikasi RipLabs yang bisa kamu gunakan secara gratis.</p> */}
                <div className="row rowcard1 rowcardapps rowcardnoborder card-groupp justify-content-center">
                  {
                    Array.isArray(listaplikasiriplabs) ?
                    listaplikasiriplabs.map((data, i) => {
                      let listaplikasiriplabsimg = data.aplikasi_thumbnail ? baseurlassets+'images/aplikasi/thumbnail/' + data.aplikasi_thumbnail : '';
                      // if(ceksesilogin>0) {
                        var linkaplikasipreview = '/'+data.aplikasi_preview;
                      // }
                      // else {
                      //   var linkaplikasipreview = "/";
                      // }
                      return (
                        <div key={i} className="card col-lg-4 p-3 mb-3">
                          <NavLink to={linkaplikasipreview} className="textdecorationnone">
                          <div className='image'>
                            <img src={listaplikasiriplabsimg} className="card-img-top" alt={listaplikasiriplabsimg} />
                          </div>
                          <div className="card-body textdecorationnone">
                            <h5 className="card-title textdecorationnone mb-1">{data.aplikasi_judul}</h5>
                            <div className='color1 textdecorationnone font-medium mb-3'>{data.aplikasi_kategori}</div>
                            <p className="card-text textdecorationnone color2">{data.aplikasi_deskripsi}</p>
                          </div>
                          </NavLink>
                          <div className="card-footer">
                            <div className='row'>
                              <div className='col-6'>
                                <NavLink to={linkaplikasipreview} onClick={classnameonlick} className="color1 d-block mt-3 font-medium textdecorationnone">Buka Aplikasi</NavLink>
                              </div>
                              <div className='col-6'>
                                <a href={data.aplikasi_playstore} target="_blank" style={{textAlign:"right"}} className="textdecorationnone color1 font-medium d-block mt-3">PlayStore</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                    : ""
                  }
                </div>
              </div>
            </section>
            </>
          )
        }

      </div>
    </>
  )
}
export default Aplikasi;