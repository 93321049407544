import React from 'react';
import NavbarMobile from './components/NavbarMobile';
import Router from './router';
function WebRipLabs() {
  return (
    <div className="WebRipLabs">
      <Router/>
      <NavbarMobile/>
    </div>
  );
}
export default WebRipLabs;